/* @ngInject */
export function agencyMemberMentionsService ($rootScope, DatabaseApi, wildcard) { return {
  /**
   * Get agency member's mentions
   */
  getMentions(params) {
    const { agencyId, agencyMemberId } = $rootScope;
    const endpoint = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/mentions/inbox",
      agencyId,
      agencyMemberId
    );
    let urlWithParams = endpoint;

    Object.keys(params).forEach((key, index) => {
      let appendSymbol = "?";
      if (index !== 0) {
        appendSymbol = "&";
      }
      urlWithParams = `${urlWithParams}${appendSymbol}${key}=${params[key]}`;
    });

    return DatabaseApi.get(urlWithParams);
  },

  /**
   * Get agency member's unread mentions
   */
  getInboxUnreadCount() {
    const { agencyId, agencyMemberId } = $rootScope;
    const endpoint = wildcard(
      `agencies/:agencyId/agency_members/:agencyMemberId/mentions/unread_count`,
      agencyId,
      agencyMemberId
    );

    return DatabaseApi.get(endpoint);
  },

  /**
   * Mark a mentions as read
   */
  async markMentionsAsRead(mentionIds) {
    const { agencyId, agencyMemberId } = $rootScope;

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/mentions/seen",
      agencyId,
      agencyMemberId
    );

    let body = {
      seen: true,
      mentionIds,
    };

    return DatabaseApi.put(url, body);
  },

  /**
   * Mark a mentions as done
   */
  async markMentionsAsDone(mentionIds) {
    const { agencyId, agencyMemberId } = $rootScope;

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/mentions/done",
      agencyId,
      agencyMemberId
    );

    let body = {
      done: true,
      mentionIds,
    };

    return DatabaseApi.put(url, body);
  },

  /**
   * Mark a mentions as undone
   */
  async markMentionsAsUndone(mentionIds) {
    const { agencyId, agencyMemberId } = $rootScope;

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/mentions/done",
      agencyId,
      agencyMemberId
    );

    let body = {
      done: false,
      mentionIds,
    };

    return DatabaseApi.put(url, body);
  },

  /**
   * Mark a mentions as unread
   */
  async markMentionsAsUnread(mentionIds) {
    const { agencyId, agencyMemberId } = $rootScope;

    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/mentions/seen",
      agencyId,
      agencyMemberId
    );

    let body = {
      seen: false,
      mentionIds,
    };

    return DatabaseApi.put(url, body);
  },
}};
