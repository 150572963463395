import moment from "moment";

//! @ngInject
export function editCaregiverStatusModalCtrl(
      $rootScope,
      $scope,
      $uibModalInstance,
      DatabaseApi,
      CaregiverStatus,
      noteConsts,
      entityNoteService,
      dateUtils,
      apiErrors,
      toaster,
      caregiver,
      mfModal,
    ) {
      $scope.allowedStatuses = CaregiverStatus.dropdown;
      $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(
        noteConsts.NoteTypes.CAREGIVER_STATUS_CHANGE
      );

      $scope.state = {
        effectiveDate: null,
        status: {},
        note: {},
      };

      $scope.selectedStatusText = "";
      $scope.isSubmitting = false;
      $scope.isValid = false;

      $scope.$watchGroup(["state.status.id", "state.note", "state.effectiveDate"], () => {
        $scope.isValid = isFormValid();
      });

      $scope.newStatusSelectEvents = {
        onSelectionChanged() {
            $scope.evaluateEffectiveDateInfoMsg()
        }
      };

      $scope.evaluateEffectiveDateInfoMsg = function() {
        const state = $scope.state;

        if (state.status.id !== undefined && 
            state.effectiveDate !== null && 
            moment(state.effectiveDate).isSameOrBefore(moment())
          ) {
            const selectedStatus = $scope.allowedStatuses.find((status) => status.id === state.status.id);
            $scope.selectedStatusText = selectedStatus !== undefined ? selectedStatus.label : "";
        }
        else {
          $scope.selectedStatusText = "";
        }
      }

      $scope.handleClickSubmit = () => {
        if (!$scope.isValid) {
          return;
        }

        if ($scope.state.status.id === 'ACTIVE' && $scope.$resolve.caregiver.hireDate === null) {
          const message = `This caregiver doesn’t have a hire date. Should we take the date ${moment($scope.state.effectiveDate).format('MM/DD/YYYY')} and set it as the hire date?\n You cannot change the caregiver status to active without having a hire date.`
          const modal = mfModal.create({
            variant: "warning",
            subject: 'Status change',
            message: message,
            layoutOrder: ["message"],
            confirmLabel: "Set hire date and confirm",
            hideCancelButton: false,
            onConfirm: () => modal.close() && submitForm({ setEffectiveDateAsHireDate: true })
          });
        } else {
          submitForm({ setEffectiveDateAsHireDate: false });
        }
      }

      function submitForm({ setEffectiveDateAsHireDate }) {
        const url =
          "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/status"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":caregiverId", caregiver.id);

        const note = entityNoteService.buildEntityNoteRequest(
          $scope.state.note,
          noteConsts.NoteTypes.CAREGIVER_STATUS_CHANGE,
          { caregiverId: Number(caregiver.id) }
        );

        const body = {
          effectiveDate: dateUtils.dateToLocalDate($scope.state.effectiveDate),
          status: $scope.state.status.id,
          note: note ?? null,
          setEffectiveDateAsHireDate: setEffectiveDateAsHireDate
        };

        $scope.state.isSubmitting = true;

        return DatabaseApi.post(url, body, true)
          .then(() => handleSuccessSubmit(setEffectiveDateAsHireDate))
          .catch(handleErrorSubmit)
          .finally(() => ($scope.state.isSubmitting = false));
      };

      function handleSuccessSubmit(setEffectiveDateAsHireDate) {
        if ($scope.$resolve.onStatusChanged) {
          $scope.$resolve.onStatusChanged({
            newStatus: $scope.state.status.id,
            effectiveDate: $scope.state.effectiveDate,
            setEffectiveDateAsHireDate: setEffectiveDateAsHireDate
          });
          $uibModalInstance.close();
        }

        toaster.pop("success", "Status updated", "The caregiver status has been updated");
      }

      function handleErrorSubmit(error) {
        console.error(error);
        toaster.pop(
          "error",
          "Something went wrong",
          apiErrors.format(error, "Failed to update the caregiver status")
        );
      }

      function isFormValid() {
        const { isNoteValid, isPredefinedValid, isMessageValid } =
          entityNoteService.validateEntityNote(
            $scope.state.note,
            noteConsts.NoteTypes.CAREGIVER_STATUS_CHANGE
          );

        if (!isNoteValid) {
          $scope.noteValidations = { isPredefinedValid, isMessageValid };
          return false;
        }

        return $scope.state.effectiveDate && $scope.state.status.id;
      };

      $scope.handleNoteChanges = (updatedNote) => {
        $scope.state.note = updatedNote;
      };
    };
