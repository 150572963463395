import { LocalDate, nativeJs } from "js-joda";
import $ from "jquery";

//! @ngInject
export function TrainingCenterBundleSettingsCtrl(
  $stateParams,
  $state,
  $scope,
  $rootScope,
  $uibModal,
  DatabaseApi,
  officesService,
  $timeout,
  toaster,
  wildcard,
  $http,
  Consts,
  SweetAlert,
  onlyUnique
) {
  const DEFAULT_LANGUAGE = "English";

  $scope.bundleId = $stateParams.id;

  $scope.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;

  $scope.allLanguages = [
    "English",
    "Spanish",
    "Russian",
    "Chinese",
    "French",
    "Arabic",
    "Creole",
  ];

  $scope.offices = undefined;
  $scope.caregiverCertifications = [];
  $scope.caregiverOffices = [];
  $scope.statuses = [
    {
      id: "ACTIVE",
      label: "Active",
    },
    {
      id: "ON_HOLD",
      label: "On hold",
    },
    {
      id: "ON_LEAVE",
      label: "On leave",
    },
    {
      id: "PENDING",
      label: "Pending",
    },
    {
      id: "SUSPENDED",
      label: "Inactive",
    },
    {
      id: "TERMINATED",
      label: "Terminated",
    },
  ];
  $scope.caregiverStatuses = [];
  $scope.payrollTime = new Date('1970-01-01 00:00:00');
  $scope.payrollIsOvertime = false;


  $scope.activePaycodes = [];
  $scope.allPaycodes = [];
  $scope.certifications = [];

  $scope.certificationOptions = [];
  $scope.paycodeOptions = [];

  $scope.selectedCertification = {};
  $scope.selectedPaycode = {};

  $scope.certPaycodePairs = [];
  $scope.paycodePerCertificationMap = {};

  $scope.bundleItemsWatchTimeForCertificateTotalMins = undefined;
  $scope.bundleItemsLengthTotalSecs = undefined;

  $scope.isSaving = false;

  function init() {
    initAgencyMembers();

    $scope.uploadThumbnail = {
      uiActive: false,
      fileUpload: null,
    };
    initCertificationsData();
    setCertificationOptions();
    initPayrollCodesData();
    $scope.refreshBundle();
  }

  function initCertificationsData() {
    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
      .map(certificationItem => ({
        id: certificationItem.certification,
        label: certificationItem.certification
      }));
  }

  function initPayrollCodesData() {
    // We also need the list of all the paycodes because
    // sometimes a bundle is assigned to inactive paycode...
    const allPaycodes = DatabaseApi.payrollCodes();
    $scope.allPaycodes = allPaycodes.map(
      ({ id, certification, displayId }) => ({
        id,
        certification,
        label: displayId,
      })
    );

    const payrollCodes = DatabaseApi.activePayrollCodes();
    $scope.activePaycodes = payrollCodes.map(
      ({ id, certification, displayId }) => ({
        id,
        certification,
        label: displayId,
      })
    );
  }

  $rootScope.$on("got_agency_certifications", (_) => {
    initCertificationsData();
    setCertificationOptions();
  });
  
  $rootScope.$on("got_payroll_codes", (_) => {
    initPayrollCodesData();
  });

  $scope.scrollMaybe = function () {
    if ($scope.alreadyScrolled) return;

    const goToItemId = $stateParams.itemId;
    const element = document.getElementById(`itemid${goToItemId}`);
    if (!element) return;

    if (element.offsetTop < 200) return;

    document
      .getElementById("tc-page")
      .scrollTo({ top: element.offsetTop, behavior: "smooth" });
    $scope.alreadyScrolled = true;
  };

  $scope.refreshBundle = function () {
    DatabaseApi.get(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId
    ).then(
      function (res) {
        updateBundle(res.data);

        $scope.activeLanguage = { val: DEFAULT_LANGUAGE };

        $scope.translatedBundleTitleInput = {};
        $scope.bundle.bundleDisplayData.forEach(function (obj) {
          $scope.translatedBundleTitleInput[obj.language] = obj.data.title;
        });

        $scope.caregiverCertifications = $scope.bundle.bundleParams.caregiverCertifications
          .map(({ certifications }) => certifications)
          .flat()
          .filter(onlyUnique)
          .map((certification) => ({ id: certification }));

        $scope.caregiverOffices = $scope.bundle.bundleParams.caregiverCertifications.map(
          ({ officeId }) => ({ id: officeId })
        );

        $scope.caregiverStatuses = $scope.bundle.bundleParams.caregiverStatuses
          .map((status) => $scope.statuses.find(({ id }) => id === status))
          .filter((status) => status !== undefined);

        $scope.chatAgencyMemberIdInput =
          "" + $scope.bundle.bundleParams.chatAgencyMemberId;
        $scope.signingAgencyMemberIdInput =
          "" + $scope.bundle.bundleParams.signingAgencyMemberId;
        $scope.signingAgencyMemberTitleInput =
          $scope.bundle.bundleParams.signingAgencyMemberTitle;
        $scope.enforceWatchOrderInput =
          $scope.bundle.bundleParams.enforceWatchOrder;
        $scope.requiredForBillingComplianceInput =
          $scope.bundle.bundleParams.requiredForBillingCompliance;
        $scope.canWatchDuringVisitShiftInput =
          $scope.bundle.bundleParams.canWatchDuringVisitShift;
        $scope.showBundleAfterDueDateInput =
          $scope.bundle.bundleParams.showBundleAfterDueDate;
        $scope.isOrientationInput =
          $scope.bundle.bundleParams.isOrientation;

        const {
          payroll: {
            isOvertime,
            durationInSeconds,
            paycodePerCertificationMap,
          },
        } = res.data.bundleParams;

        $scope.payrollIsOvertime = isOvertime;

        const baseDate = new Date(durationInSeconds * 1000);
        const fixedTimezone = new Date(
          durationInSeconds * 1000 + baseDate.getTimezoneOffset() * 60000
        );
        $scope.payrollTime = fixedTimezone;
        $scope.paycodePerCertificationMap = paycodePerCertificationMap;

        setCertificationOptions();
        setPaycodeOptions();
        initPaycodeCertificationMap();

        setTimeout($scope.scrollMaybe, 500);
      },
      function (err) {
        toaster.pop("error", "something went wrong", "");
      }
    );

    officesService
      .getOffices($rootScope.agencyId)
      .then((offices) => {
        $scope.offices = offices.map(({ id, name }) => ({ id, label: name }));
      })
      .catch(() => toaster.pop("error", "Failed to fetch offices"));
  };

  function updateBundle(bundle) {
    $scope.bundle = bundle;

    $scope.bundleItemsWatchTimeForCertificateTotalMins = bundle.bundleItems.reduce(
      (acc, curr) => {
        const { timeForCertificateMinutes, media, active } = curr;
        if (active && timeForCertificateMinutes && media) {
          return acc + timeForCertificateMinutes;
        }
        return acc;
      },
      0
    );

    $scope.bundleItemsLengthTotalSecs = bundle.bundleItems.reduce(
      (acc, curr) => {
        const { media, active } = curr;
        if (active && media) {
          if (media.type === "Video" && media.videoInfo) {
            const videoInfoForLanguage = media.videoInfo.find(
              (video) => video.language === DEFAULT_LANGUAGE
            );
            if (
              videoInfoForLanguage &&
              videoInfoForLanguage.data &&
              typeof videoInfoForLanguage.data.videoLengthSeconds === "number"
            ) {
              const watchLengthSeconds = Math.ceil(
                (videoInfoForLanguage.data.videoLengthSeconds *
                  media.requiredWatchTimePercent) /
                  100
              );
              return acc + watchLengthSeconds;
            }
          }
          if (
            media.type === "PDF" &&
            typeof media.requiredViewTimeMinutes === "number"
          ) {
            return acc + media.requiredViewTimeMinutes * 60;
          }
        }
        return acc;
      },
      0
    );

    $scope.bundle.bundleDisplayData.forEach(function (obj) {
      if ($scope.allLanguages.indexOf(obj.language) < 0) {
        $scope.allLanguages.push(obj.language);
      }
    });
  }

  function initAgencyMembers() {
    var members = DatabaseApi.getAgencyMembersArr();
    if (!members || !members.length) {
      $timeout(initAgencyMembers, 500);
      return;
    }

    $scope.agencyMemberOptions = [];
    members.forEach(function (agencyMember) {
      $scope.agencyMemberOptions.push({
        id: agencyMember.id,
        displayName: agencyMember.displayName,
      });
    });
  }

  function sendDeleteBundleRequest() {
    const endpoint = wildcard(
      `agencies/:agencyId/agency_members/:agencyMemberId/training_center/bundles/:trainingCenterBundleId`,
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.bundle.bundleId
    );
    const data = undefined;
    const reactTo403 = true;

    const handleSuccessDeleteBundle = () => {
      toaster.pop("success", "Bundle has been deleted!");
      $state.go("app.trainingCenterSettings");
    };

    const handleFailDeleteBundle = (response) => {
      if (response?.data?.error) {
        toaster.pop("error", response.data.error);
      } else {
        toaster.pop("error", "Something went wrong");
      }
    };

    const handleFinallyDeleteBundle = () => {
      $scope.isDeleting = false;
    }

    $scope.isDeleting = true;

    DatabaseApi.delete(endpoint, data, reactTo403)
      .then(handleSuccessDeleteBundle)
      .catch(handleFailDeleteBundle)
      .finally(handleFinallyDeleteBundle);
  }

  $scope.bundleEnglishTitle = function () {
    if (!$scope.bundle) {
      return;
    }

    var displayData = $scope.bundle.bundleDisplayData.find(function (obj) {
      return obj.language === DEFAULT_LANGUAGE;
    }).data;

    return displayData.title;
  };

  $scope.bundleItemEnglishDisplayData = function (bundleItem) {
    var displayData = bundleItem.displayData.find(function (obj) {
      return obj.language === DEFAULT_LANGUAGE;
    }).data;

    return displayData;
  };

  $scope.bundleItemThumbnail = function (bundleItem) {
    var displayData = bundleItem.displayData.find(function (obj) {
      return obj.language === DEFAULT_LANGUAGE;
    }).data;

    if (displayData.thumbnailUrl) {
      return displayData.thumbnailUrl;
    }

    switch (bundleItem.media.type) {
      case "Video":
        var videoDisplayData = bundleItem.media.videoInfo.find(function (obj) {
          return obj.language === DEFAULT_LANGUAGE;
        }).data;
        if (videoDisplayData.thumbnailUrl) {
          return videoDisplayData.thumbnailUrl;
        }
        break;
      case "PDF":
        break;
      case "TestOnly":
        break;
    }
  };

  $scope.getBundleThumbnailUrl = function (lang) {
    if (!$scope.bundle) {
      return;
    }

    var thumbnailUrl = null;
    $scope.bundle.bundleDisplayData.forEach(function (obj) {
      if (obj.language === lang) {
        thumbnailUrl = obj.data.thumbnailUrl;
      }
    });
    return thumbnailUrl;
  };

  $scope.onClickPublish = function () {
    const endpoint = wildcard(
      `agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/publish`,
      $rootScope.agencyId,
      $scope.bundleId
    );

    DatabaseApi.post(endpoint)
      .then(({ data }) => updateBundle(data))
      .catch(() => toaster.pop("error", "Publish failed"));
  };

  $scope.onClickUnpublish = function () {
    const endpoint = wildcard(
      `agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/unpublish`,
      $rootScope.agencyId,
      $scope.bundleId
    );

    DatabaseApi.post(endpoint)
      .then(({ data }) => updateBundle(data))
      .catch(() => toaster.pop("error", "Unpublish failed"));
  };

  $scope.saveBundle = function () {
    const caregiverCertifications = $scope.caregiverOffices.map((office) => ({
      officeId: office.id,
      certifications: $scope.caregiverCertifications.map(({ id }) => id),
    }));
    const caregiverStatuses = $scope.caregiverStatuses.map(({ id }) => id);

    const timezoneOffset = $scope.payrollTime.getTimezoneOffset() * 60000;
    const timeWithTimezoneOffsetFixed = new Date(
      $scope.payrollTime.getTime() - timezoneOffset
    );
    const durationInSeconds = Math.trunc(
      timeWithTimezoneOffsetFixed.getTime() / 1000
    );

    const paycodePerCertificationMap = {};
    Object.keys($scope.paycodePerCertificationMap).map(
      (key) =>
        (paycodePerCertificationMap[key] =
          $scope.paycodePerCertificationMap[key].id ||
          $scope.paycodePerCertificationMap[key])
    );

    const payroll = {
      paycodePerCertificationMap,
      isOvertime: $scope.payrollIsOvertime,
      durationInSeconds,
    };

    const params = {
      chatAgencyMemberId: parseInt($scope.chatAgencyMemberIdInput, 10),
      signingAgencyMemberId: parseInt($scope.signingAgencyMemberIdInput, 10),
      signingAgencyMemberTitle: $scope.signingAgencyMemberTitleInput,
      enforceWatchOrder: $scope.enforceWatchOrderInput,
      requiredForBillingCompliance: $scope.requiredForBillingComplianceInput,
      canWatchDuringVisitShift: $scope.canWatchDuringVisitShiftInput,
      showBundleAfterDueDate: $scope.showBundleAfterDueDateInput,
      isOrientation: $scope.isOrientationInput,
      caregiverCertifications,
      caregiverStatuses,

      payroll,
    };

    $scope.isSaving = true;

    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/edit_params",
      params
    )
      .then(() => toaster.pop("success", "Succesfully updated!"))
      .catch(() => toaster.pop("error", "Something went wrong"))
      .finally(() => ($scope.isSaving = false));
  };

  $scope.deleteBundle = function () {
    SweetAlert.swal(
      {
        title: "Delete bundle",
        text:
          "Are you sure you want to delete this bundle? This action is irreversible!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete",
        closeOnConfirm: true,
      },
      (hasConfirmed) => hasConfirmed && sendDeleteBundleRequest()
    );
  };

  $scope.onBundleTitleChange = function (lang) {
    console.log("title", lang, $scope.translatedBundleTitleInput[lang]);

    if (lang === DEFAULT_LANGUAGE || $scope.translatedBundleTitleInput[lang]) {
      var params = {
        title: $scope.translatedBundleTitleInput[lang],
      };
      DatabaseApi.post(
        "agencies/" +
          $rootScope.agencyId +
          "/training_center/bundles/" +
          $scope.bundleId +
          "/languages/" +
          lang +
          "/display_data/edit",
        params
      ).then(
        function (res) {
          // Ok, updated
        },
        function err(err) {
          console.log();
        }
      );
    } else {
      DatabaseApi.delete(
        "agencies/" +
          $rootScope.agencyId +
          "/training_center/bundles/" +
          $scope.bundleId +
          "/languages/" +
          lang +
          "/display_data"
      ).then(
        function (res) {
          // Ok, updated
        },
        function err(err) {
          console.log();
        }
      );
    }
  };

  $scope.uploadBundleThumbnail = function () {
    console.log("Upload Bundle Thumbnail");

    var language = $scope.activeLanguage.val;

    var formData = new FormData();
    formData.append(
      "file",
      $scope.uploadThumbnail.fileUpload,
      $scope.uploadThumbnail.fileUpload.name
    );

    $http({
      url:
        Consts.api +
        "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_thumbnail/languages/" +
        language +
        "/upload_bundle_thumbnail",
      method: "POST",
      data: formData,
      headers: { "Content-Type": undefined },
    }).then(
      function (res) {
        toaster.pop(
          "success",
          "Success",
          "File Uploaded: " + $scope.uploadThumbnail.fileUpload.name
        );
        $scope.uploadThumbnail = {
          uiActive: false,
          fileUpload: null,
        };
        updateBundle(res.data);
      },
      function (response) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
        $scope.uploadThumbnail = {
          uiActive: false,
          fileUpload: null,
        };
      }
    );
  };

  $scope.onAddBundleItem = function (itemType) {
    var params = {
      itemType: itemType,
      displayData: [],
      testQuestions: [],
    };

    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/create_new_item",
      params
    ).then(
      function (res) {
        updateBundle(res.data);
      },
      function (err) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.onMoveBundleItem = function (bundleItemId, direction) {
    var params = {
      direction: direction, // "UP" or "DOWN"
    };

    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
      "/agency_members/" +
        $rootScope.agencyMemberId +
      "/training_center/bundles/" +
      $scope.bundleId +
      "/bundle_items/" +
      bundleItemId +
      "/move_up_down",
      params
    ).then(
      function (res) {
        updateBundle(res.data);
      },
      function (err) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.onDeleteBundleItem = function (bundleItemId) {
    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        bundleItemId +
        "/delete"
    ).then(
      function (res) {
        updateBundle(res.data);
      },
      function (err) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.onDeactivateBundleItem = function (bundleItemId) {
    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        bundleItemId +
        "/deactivate"
    ).then(
      function (res) {
        updateBundle(res.data);
      },
      function (err) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.onActivateBundleItem = function (bundleItemId) {
    DatabaseApi.post(
      "agencies/" +
        $rootScope.agencyId +
        "/training_center/bundles/" +
        $scope.bundleId +
        "/bundle_items/" +
        bundleItemId +
        "/activate"
    ).then(
      function (res) {
        updateBundle(res.data);
      },
      function (err) {
        toaster.pop("error", "Something Went Wrong", "Please try again");
      }
    );
  };

  $scope.openDueDateModal = function (bundleDueDate = undefined) {
    $uibModal.open({
      templateUrl: "admin/views/training-center-due-date-modal.html",
      size: "lg",
      controller: "trainingCenterDueDateModal",
      windowTopClass: "training-center-due-date-modal",
      resolve: {
        bundleDueDate: () => bundleDueDate,
        bundleId: () => $scope.bundleId,
        onBundleChange: () => (bundle) => ($scope.bundle = bundle),
      },
    });
  };

  $scope.requestToDeleteDueDate = function ({ bundleDueDateId }) {
    const url = wildcard(
      `agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/due_dates/:trainingCenterBundleDueDateId/delete`,
      $rootScope.agencyId,
      $scope.bundleId,
      bundleDueDateId
    );

    const deleteDueDate = () => {
      const handleSuccessDelete = () => {
        toaster.pop("success", "Due date deleted");

        // delete bundle due date from scope
        $scope.bundle.bundleDueDates = $scope.bundle.bundleDueDates.filter(
          (dueDate) => dueDate.bundleDueDateId !== bundleDueDateId
        );
      };

      const handleFailDelete = (response) => {
        if (response.data.error) {
          toaster.pop("error", response.data.error);
        } else {
          toaster.pop("error", "Something went wrong");
        }
      };

      DatabaseApi.post(url).then(handleSuccessDelete).catch(handleFailDelete);
    };

    SweetAlert.swal(
      {
        title: "Delete due date",
        text: "Are you sure you want to delete this due date?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete",
        closeOnConfirm: true,
      },
      (hasConfirmed) => hasConfirmed && deleteDueDate()
    );
  };

  $scope.removeCertification = function (removeCert) {
    delete $scope.paycodePerCertificationMap[removeCert];
    $scope.certPaycodePairs = $scope.certPaycodePairs.filter(
      ({ certification }) => certification !== removeCert
    );

    setCertificationOptions();
  };

  $scope.paycodeSelectionEvents = {
    onItemSelect: function () {
      const paycode = $scope.paycodeOptions.find(
        ({ id }) => $scope.selectedPaycode.id === id
      );
      setCertificationPaycodePairs(paycode);

      $scope.selectedCertification = {};
      $scope.selectedPaycode = {};

      setCertificationOptions();
      setPaycodeOptions();
    },
  };

  $scope.certificationSelectionEvents = {
    onItemSelect: function () {
      setPaycodeOptions();

      $timeout(() => {
        $("#paycodeSelect").find(".dropdown-toggle").trigger("click");
      }, 50);
    },
  };

  $scope.disableOrientationSwitch = function () {
    return $scope.bundle &&
      $scope.bundle.bundleDueDates &&
      $scope.bundle.bundleDueDates.length !== 1;
  }

  $scope.showOrientationWarning = function () {
    if (!$scope.isOrientationInput) return;
    SweetAlert.swal(
      {
        title: "Set bundle as orientation bundle?",
        text:
          "Changing this will remove bundle certifications and statuses. \n Existing due date will be modified and CHANGES WOULD BE SAVED.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Update and Save",
        closeOnConfirm: true,
      },
      (hasConfirmed) => {
        if (!hasConfirmed) {
          $scope.isOrientationInput = false;
          return;
        }

        $scope.caregiverCertifications = [];
        $scope.caregiverStatuses = [
          {
            id: "PENDING",
            label: "Pending",
          },
        ];

        const dueDate = $scope.bundle.bundleDueDates[0];
        if (dueDate === undefined) return;

        console.log(dueDate);
        // TODO: Use code from tcDueDateModal to update the duedate endpoint:

        const url = wildcard(
          `agencies/:agencyId/training_center/bundles/:trainingCenterBundleId/due_dates/:trainingCenterBundleDueDateId/edit`,
          $rootScope.agencyId,
          $scope.bundle.bundleId,
          dueDate.bundleDueDateId
        );

        const completionDate = LocalDate.from(nativeJs(new Date("01/01/2999")));
        const complianceDate = LocalDate.from(nativeJs(new Date("01/01/2999")));
        const visibleDate = LocalDate.from(nativeJs(new Date("01/01/2000")));

        const params = {
          year: dueDate.year.toString(),
          dueDates: {
            type: "Dates",
            completionDate,
            complianceDate,
            visibleDate,
          },
        };

        $scope.saveBundle();
        DatabaseApi.post(url, params)
          .then(() => {
            toaster.pop("success", "Due date has been succesfully updated");
            dueDate.dueDates.completionDate = completionDate;
            dueDate.dueDates.complianceDate = complianceDate;
            dueDate.dueDates.visibleDate = visibleDate;
          })
          .catch(() => toaster.pop("error", "Couldn't update due date", "Please manually set due dates to 01/01/2000-2999"));
      }
    );
  }

  function setCertificationPaycodePairs({ id, label, certification }) {
    $scope.paycodePerCertificationMap[certification] = {
      id,
      label,
    };
    $scope.certPaycodePairs.push({ id, label, certification });
  }

  function setCertificationOptions() {
    $scope.certificationOptions = $scope.certifications.filter(
      ({ id: certificationId }) =>
        !Object.keys($scope.paycodePerCertificationMap).includes(
          certificationId
        ) &&
        $scope.activePaycodes
          .filter(({ certification }) => certificationId === certification)
          .filter(
            ({ id }) =>
              $scope.certPaycodePairs.find(
                (selectedPaycode) => selectedPaycode.id === id
              ) === undefined
          ).length > 0
    );
  }

  function setPaycodeOptions() {
    if (!$scope.selectedCertification) return;

    $scope.paycodeOptions = $scope.activePaycodes.filter(
      ({ id, certification }) =>
        $scope.selectedCertification.id === certification &&
        !$scope.certPaycodePairs.find(
          (selectedPaycode) => selectedPaycode.id === id
        )
    );
  }

  function initPaycodeCertificationMap() {
    const rawMap = $scope.paycodePerCertificationMap;
    for (const certification in rawMap) {
      const paycodeId = rawMap[certification];
      const paycode = $scope.allPaycodes.find(({ id }) => paycodeId === id);
      const { id, label } = paycode;

      $scope.certPaycodePairs.push({ certification, id, label });
    }
  }

  init();
};
