import { LocalDate, nativeJs, LocalDateTime, DateTimeFormatter, convert } from "js-joda";

export const dateUtils = {
  dateToLocalDate: (date: Date) => LocalDate.from(nativeJs(date)),
  dateToLocalDateTime: (date: Date) => LocalDateTime.from(nativeJs(date)),
  ymdStringToLocalDate: (date: string) => LocalDate.parse(date),
  ymdStringToDate: (date: string) => convert(LocalDate.parse(date)).toDate(),
  localDateToDate: (localDate: LocalDate) => convert(localDate).toDate(),
  localDateToMDYString: (localDate: LocalDate) => localDate.format(DateTimeFormatter.ofPattern("M/d/yyyy")),
};

export type DateUtils = typeof dateUtils;

