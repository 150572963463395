import { LocalDate, nativeJs } from "js-joda";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from "moment";


export const patientMedicationProfile = {
    templateUrl: "admin/views/patient-medication-profile.html",
    bindings: {
        patient: "<",
        readonly: "<",
        showActions: '<'
    },
    //! @ngInject
    controller: function ($scope, $rootScope, $http, $uibModal, Consts, base64ImageConsts, NgTableParams, DatabaseApi, toaster, SweetAlert) {
        const vm = this;

        vm.$onInit = function () {
            $scope.initMedicationProfileTable();
        };

        $scope.initMedicationProfileTable = function () {
            const medicationsUrl = Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patient/' + vm.patient.id + '/medication_profile';
            $http.get(medicationsUrl).then(({data}) => {
                initMedicationProfileTable(data.medications);
            });
        }

        $scope.newMedicationProfileTable = function () {
            const newMedication = getEmptyMedicationObject();
            initMedicationProfileTable([newMedication]);
        }

        $scope.addNewMedication = function () {
            const newMedication = getEmptyMedicationObject();
            $scope.medicationProfileTable.data.unshift(newMedication);
        }

        function getEmptyMedicationObject() {
            return {
                id: null,
                name: null,
                dose: null,
                route: null,
                frequency: null,
                from: null,
                until: null,
                notes: null
            }
        }

        $scope.medicationsAutoCompleteOptions = {
            minimumChars: 1,
            containerCssClass: 'medicationProfileDropDown',
            data: function (searchText) {
                return DatabaseApi.get('agencies/' + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + '/medication_profile/search?value=' + searchText)
                  .then(function (response) {
                      return response.data.medications;
                  }, function (err) {
                      toaster.pop('error', "Something went wrong", "Could not get medications");
                  });
            },
            renderItem: function (item) {
                return {
                    value: item.display_name,
                    label: "<div class='medicationSearchResult'>"
                      + "<span class='medicineName'>" + item.displayName + "</span>"
                      + "<span class='medicineDoseRoute'>" + item.dose + ' • ' + item.route + "</span>"
                      + "</div>"
                };
            },
            itemSelected: function (e) {
                let item = $scope.medicationProfileTable.data[$scope.currentMedicationRowIndex];
                item.medicationId = e.item.id;
                item.medicationName = e.item.displayName;
                item.dose = e.item.dose;
                item.route = e.item.route;
                if (item.id) {
                    $scope.updateMedicationProfileRow(null, item, 'medicationId');
                } else {
                    insertMedicationProfileRow(e.item.id);
                }
            }
        }

        function insertMedicationProfileRow(medId) {
            const newMedicationRowBody = {
                medicationId: medId
            };

            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
              + '/patient/' + vm.patient.id + '/medication_profile'
              , newMedicationRowBody).then(function (res) {
                $scope.medicationProfileTable.data[$scope.currentMedicationRowIndex].id = res.data.id;
                reloadTable();
                toaster.pop('success', "A new medication created successfully");
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not create new medication");
            })
        }

        $scope.removeMedication = function (idx, item, field) {
            if (!item.id) {
                $scope.medicationProfileTable.data.splice(idx, 1);
                initMedicationProfileTable($scope.medicationProfileTable.data);
                return;
            }

            SweetAlert.swal({
                title: "Remove?",
                text: "Are you sure you want to remove this medication?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, remove",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $scope.updateMedicationProfileRow(idx, item, field);
                }
            });
        }

        $scope.updateMedicationProfileRow = function (idx, item, field) {

            let body = {
                id: item.id
            };
            switch (field) {
                case 'from':
                case 'until': {
                    if (item[field] !== null) {
                        body[field] = stringifyDate(item[field]);
                    } else {
                        body[field] = null;
                    }
                    break;
                }
                case 'status': {
                    body[field] = "DISCONTINUED";
                    break;
                }
                default: {
                    body[field] = item[field];
                }
            }

            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId
              + '/patient/' + vm.patient.id + '/medication_profile'
              , body).then(function (res) {
                if (field === 'status') {
                    $scope.medicationProfileTable.data.splice(idx, 1);
                    initMedicationProfileTable($scope.medicationProfileTable.data);
                }
                toaster.pop('success', "Medication updated successfully");
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not update medication");
            })
        }

        $scope.setRelevantMedicationRow = function (rowIndex) {
            $scope.currentMedicationRowIndex = rowIndex;
        }

        const parseDate = date => LocalDate.from(nativeJs(moment(date)));
        const stringifyDate = date => parseDate(date).toString();

        function initMedicationProfileTable(items) {
            items.forEach(function (item) {
                let d;
                if (item.from !== null) {
                    d = parseDate(item.from);
                    item.from = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
                }
                if (item.until !== null) {
                    d = parseDate(item.until);
                    item.until = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
                }
            });

            reloadTable(items);
        }

        function reloadTable(items) {
            $scope.medicationProfileTable = new NgTableParams({ count: 10 }, {
                counts: [],
                dataset: items ?? $scope.medicationProfileTable.data
            });
        }

        function getJSPDFCenterOffset(doc, text) {
            const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            return (doc.internal.pageSize.width - textWidth) / 2;
        }

        $scope.viewEncodeMedicationProfilePdf = (encodePDFOnly) => {
            let doc = new jsPDF();
            let patientName = vm.patient.firstName + " " + vm.patient.lastName;
            const medflytLogoBase64 = base64ImageConsts.medflytLogo;

            let cols = [{
                medicationName: 'Medication Name', dose: 'Dose', route: 'Route',
                frequency: 'Frequency', from: 'From', until: 'Until', notes: 'Notes'
            }];
            let rows = [];
            let frequency, from, until, notes, temp;

            $scope.medicationProfileTable.data.forEach(medication => {
                frequency = medication.frequency !== null ? medication.frequency : "";
                from = medication.from !== null ? stringifyDate(medication.from) : "";
                until = medication.until !== null ? stringifyDate(medication.until) : "";
                notes = medication.notes !== null ? medication.notes : "";

                temp = [medication.medicationName, medication.dose,
                    medication.route, frequency, from, until, notes];
                rows.push(temp);
            });

            doc.setFontSize(25);
            doc.text(patientName, getJSPDFCenterOffset(doc, patientName), 40);

            doc.setFontSize(15);
            doc.setTextColor(100)
            doc.text("Medication Profile", getJSPDFCenterOffset(doc, "Medication Profile"), 47);
            autoTable(doc, {
                head: cols,
                body: rows,
                startY: 55,
                didDrawPage: function (data) {
                    doc.setFontSize(20);
                    doc.setTextColor(40);
                    doc.setFontStyle('normal');
                    doc.addImage(medflytLogoBase64, "JPEG", (doc.internal.pageSize.width / 2) - 30, 15, 60, 15);
                },
                margin: { top: 30 }
            });
            if (encodePDFOnly) {
                return doc.output('datauri');
            }
            const fileDate = stringifyDate(new Date());
            const fileName = patientName + "-Medication Profile-" + fileDate;
            doc.save(fileName);
        }

        $scope.sendMedicationProfilePdfToEmailOrFax = (type) => {
            let pdfData = $scope.viewEncodeMedicationProfilePdf(true);

            const newScope = $scope.$new();
            newScope.modalTitle = "Send Medication Profile";

            newScope.attachmentName = "Medication Profile";
            newScope.type = type;
            newScope.encodedPdf = pdfData;

            $uibModal.open({
                templateUrl: 'admin/views/send-encoded-pdf-modal.html',
                size: 'md',
                scope: newScope,
                controller: 'sendEncodedPdfModalCtrl'
            });
        }

        $scope.openNewAgencyMedicineModal = (rowIndex) => {
            $scope.currentMedicationRowIndex = rowIndex;
            const modalInstance = $uibModal.open({
                templateUrl: 'admin/views/new-agency-medicine-modal.html',
                size: 'lg',
                controller: 'newAgencyMedicineModalCtrl',
                resolve: {
                    patientId: () => vm.patient.id,
                }
            });
            modalInstance.result.then((res) => {
                if (res.id) {
                    $scope.medicationProfileTable.data[$scope.currentMedicationRowIndex] = res;
                    reloadTable();
                }
            });
        };
    }
};
