import moment from "moment";

//! @ngInject
export function CaregiversTrainingCenterOverview(
    $scope,
    $rootScope,
    $state,
    $filter,
    offices,
    DatabaseApi,
    NgTableParams,
    BoostTrainingCenterModalService,
    toaster,
    wildcard,
    Storage,
    TrainingCenterOverviewService,
  ) {
    /**
     * Caregivers statistics
     * Being updated from the endpoint (see function `fetchStatistics` below)
     */
    $scope.bundleStatistics = undefined;

    /**
     * Indicates if we are currently fetching from the database
     */
    $scope.isFetching = {
      statistics: false,
      caregivers: false,
      caregiverDict: true,
      boostViews: false,
    };

    /**
     * Default values of the form
     */
    $scope.form = {
      filterDateType: { id: "dueDate" },
      dueDateRange: {
        startDate: moment().startOf("year"),
        endDate: moment().endOf("year"),
      },
      completionDateRange: {
        startDate: moment().startOf("year"),
        endDate: moment().endOf("year"),
      },
      installationStatus: [{ id: true }, { id: false }],
      complianceStatus: [],
      caregiverStatus: [
        { id: "ACTIVE", label: "Active", statusClass: "green" },
      ],
      caregiverOffices: [],
    };

    $scope.dueDateTypeOptions = [
      { id: "dueDate", label: "Due Date" },
      { id: "completionDate", label: "Completion Date" },
    ];

    $scope.dueDateTypeSelectionSettings = {
      closeOnSelect: true,
      selectionLimit: 1,
      showUncheckAll: false,
      showCheckAll: false,
      smartButtonMaxItems: 1,
      smartButtonTextConverter: function (itemText, originalItem) {
        return `${itemText}`;
      },
    };

    /**
     * Due date range (date-range-picker) options
     */
    $scope.dueDateRangeOptions = {
      ranges: {
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 14 Days": [moment().subtract(13, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "Last Year": [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ],
        "This Year": [moment().startOf("year"), moment().endOf("year")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      },
      alwaysShowCalendars: true,
      applyClass: "btn-primary",
      locale: {
        direction: "ltr date-range-picker-v2",
        format: "D MMM YY",
      },
      autoApply: true,
      minDate: new Date("2018-01-01"),
    };

    /**
     * Installation status select options
     */
    $scope.installationStatusOptions = [
      { id: true, label: "Installed" },
      { id: false, label: "Not Installed" },
    ];

    /**
     * Compliance select options
     */
    $scope.complianceStatusOptions = [
      { id: true, label: "Compliant" },
      { id: false, label: "Not Compliant" },
    ];

    $scope.caregiverStatusOptions = [
      { id: "ACTIVE", label: "Active" },
      { id: "ON_HOLD", label: "On Hold" },
      { id: "ON_LEAVE", label: "On Leave" },
      { id: "PENDING", label: "Pending Application" },
      { id: "TERMINATED", label: "Terminated" },
      { id: "SUSPENDED", label: "Inactive" },
      { id: "QUIT", label: "Quit" }
    ];

    /**
     * Init Caregiver offices (multi) select options
     */
    $scope.caregiverOfficesOptions = offices.map((office) => ({
      id: office.id,
      label: office.name,
    }));

    $scope.officesComponentOptions = {
      styleActive: true,
      scrollable: true,
      scrollableHeight: "250px",
      enableSearch: true,
    };

    /**
     * The selected bundle
     */
    $scope.selectedBundle = {
      bundle: undefined,
    };

    $scope.caregiversTable = undefined;
    $scope.caregiverStats = { rows: undefined };
    $scope.caregiverOverallStats = { rows: undefined };

    /**
     * Expose functions to the template
     */
    $scope.handleClickBundle = handleClickBundle;
    $scope.handleClickOverallStat = handleClickOverallStat;
    $scope.handleClickBoostViews = handleClickBoostViews;
    $scope.onClickApplyFilters = fetchStatistics;
    $scope.handleClickCaregiverName = navToCaregiver;
    $scope.handleClickCaregiverPhoto = navToCaregiver;
    $scope.handleClickViewCertificate = viewCertificate;
    $scope.handleClickDownloadCertificates = downloadCertificates;

    /**
     * Will fire when caregiver dictionary is loaded
     */
    $rootScope.$on("got_data", checkCaregiverDict);

    /**
     * Will parse table only when have the needed data
     */
    $scope.$watchGroup(
      ["tableTemp", "isFetching.caregiverDict"],
      ([tableTemp, fetchingDict]) => {
        if (tableTemp && !fetchingDict) {
          const rows = processCaregiverStatsResponse(tableTemp.rows);
          $scope.caregiverStats.rows = rows;
          $scope.isFetching.caregivers = false;
        }
      }
    );

    function checkCaregiverDict() {
      if (Object.keys(DatabaseApi.caregivers()).length > 0) {
        $scope.isFetching.caregiverDict = false;
      }
    }

    async function onInit() {
      checkCaregiverDict();
      fetchStatistics();
    }

    function fetchStatistics() {
      $scope.selectedBundle.bundle = undefined;
      $scope.bundleStatistics = undefined;
      $scope.isFetching.statistics = true;
      $scope.isFetching.caregivers = true;
      $scope.caregiverStats = { rows: undefined };
      $scope.caregiverOverallStats = { rows: undefined };

      TrainingCenterOverviewService.getGeneralStatistics($scope.form)
        .then(({ data }) => ($scope.bundleStatistics = data))
        .catch(() => toaster.pop("error", "Couldn't fetch statistics"))
        .finally(() => ($scope.isFetching.statistics = false));
      TrainingCenterOverviewService.getTableForAllBundles($scope.form)
        .then(({ data }) => {
          $scope.isFetching.caregivers = false;
          $scope.caregiverOverallStats.rows = processCaregiverStatsResponse(data.rows);
          return $scope.caregiverOverallStats.rows;
        })
        .catch(() => toaster.pop("error", "Failed to fetch caregivers"));
    }

    function handleClickBundle(bundle) {
      $scope.isFetching.caregivers = true;
      $scope.selectedBundle.bundle = bundle;
      $scope.caregiverStats = { rows: undefined };
      $scope.caregiverOverallStats = { rows: undefined };

      TrainingCenterOverviewService.getStatisticsTableByBundleId(
        bundle.bundleId,
        $scope.form
      )
        .then(({ data }) => {
          scrollToCaregiversTable();
          return $scope.tableTemp = data;
        })
        .catch(() => toaster.pop("error", "Failed to fetch caregivers"));
    }

    function handleClickOverallStat() {
      $scope.selectedBundle.bundle = undefined;
      $scope.caregiverStats = { rows: undefined };
      $scope.caregiverOverallStats = { rows: undefined };
      $scope.isFetching.caregivers = true;

      TrainingCenterOverviewService.getTableForAllBundles($scope.form)
        .then(({ data }) => {
          scrollToCaregiversTable();
          $scope.isFetching.caregivers = false;
          return $scope.caregiverOverallStats.rows = processCaregiverStatsResponse(data.rows);
        })
        .catch(() => toaster.pop("error", "Failed to fetch caregivers"));
    }

    function processCaregiverStatsResponse(rows) {
      return rows.map((row) => {
        const caregiver = DatabaseApi.getCaregiverById(row.caregiverId);
        row.caregiver = {
          ...caregiver,
        };
        return {
          ...row,
          __officeNames: getCaregiverOffices(row.caregiver.officeIds),
          __caregiverFullName: $filter("fullName")(row.caregiver),
          __compliance: row.isCompliant ? "Compliant" : "Incompliant",
        };
      });
    }

    function getCaregiverOffices(officeIds) {
      const offices = officeIds.map((officeId) => {
        return $scope.caregiverOfficesOptions.find(
          (office) => office.id === officeId
        ).label;
      });

      return offices.join(", ");
    }

    function scrollToCaregiversTable() {
      setTimeout(() => {
        const pageDOM = document.getElementById("tc-page");
        const headerDOM = document.querySelector(".tc-header");
        const tableDOM = document.getElementById("caregivers-table");

        pageDOM.scroll({
          top: tableDOM.offsetTop - headerDOM.offsetHeight - 16,
          behavior: "smooth",
        });
      }, 0);
    }

    function handleClickBoostViews(bundle, caregiverId) {
      $scope.isFetching.boostViews = true;

      if (!caregiverId) {
        TrainingCenterOverviewService.getStatisticsTableByBundleId(
          bundle.bundleId,
          $scope.form
          )
          .then(({ data }) => {
            BoostTrainingCenterModalService.open({
              caregiverIds: data.rows
              .filter(({ completedBundle }) => !completedBundle)
              .map(({ caregiverId }) => caregiverId),
            });
          })
          .catch(() => toaster.pop("error", "Boosting failed"))
          .finally(() => ($scope.isFetching.boostViews = false));
        } else {
          BoostTrainingCenterModalService.open({
            caregiverIds: [caregiverId]
            .filter(({ completedBundle }) => !completedBundle)
            .map(({ caregiverId }) => caregiverId),
          });
        }
    }

    function navToCaregiver({ caregiver }) {
      $rootScope.openCaregiverModal(caregiver.id);
    }

    function viewCertificate(row) {
      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/bundle_due_dates/:trainingCenterBundleDueDateId/certificate_pdf",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        row.caregiver.id,
        row.dueDateId
      );

      return DatabaseApi.get(endpoint)
        .then(({ data }) => window.open(data.url))
        .catch(() => toaster.pop("error", "Couldn't open certificated"));
    }

    function downloadCertificates(bundle, month) {
      const endpoint = wildcard(
        "agencies/:agencyId/training_center/bundles/:bundleId/zip_certificates/:month",
        $rootScope.agencyId,
        bundle.bundleId,
        month
      );

      $scope.isFetching.certificates = true;

      DatabaseApi.get(endpoint)
        .then(({ data }) => window.open(data.url))
        .catch(({ data }) => {
          if (data && data.error) {
            toaster.pop("error", data.error);
          } else {
            toaster.pop("error", "Something went wrong");
          }
        })
        .finally(() => ($scope.isFetching.certificates = false));
    }

    onInit();
  };
