//! @ngInject
export function billingExportDetailsCtrl(
  $scope,
  $rootScope,
  NgTableParams,
  $stateParams,
  DatabaseApi,
  generalUtils,
  toaster
) {
  $scope.exportBatch = null;
  const initializeMap = DatabaseApi.entitiesInitializeMap();
  $scope.assetsAreReady = false;

  function initialize() {
    $scope.isLoading = true;    
    $scope.caregiversMap = DatabaseApi.caregivers() || {};    
    $scope.contractTypes = DatabaseApi.contractTypes() || [];
    $scope.patientsMap = DatabaseApi.patients() || {};
    checkEssentialAssets();
    if (!$scope.assetsAreReady) return;        

    $scope.exportBatch = $stateParams.export;

    let exportBatchId =
      ($scope.exportBatch && $scope.exportBatch.id) ||
      generalUtils.getLastPartInUrlPath();
    if (exportBatchId) {
      let url =
        "agencies/" +
        $rootScope.agencyId +
        "/agency_members/" +
        $rootScope.agencyMemberId +
        "/export_batches/" +
        exportBatchId;

      DatabaseApi.get(url).then(
        function (res) {
          initTable(res.data.visitInstances);
          $scope.isLoading = false;
        },
        function (error) {
          toaster.pop("error", "Failed to load the export batch details");
          $scope.isLoading = false;
        }
      );
    }
  }

  let mapItems = function (items) {    
    items.forEach(function (item) {
      if (!item.contractTypeName && item.contractId) {
        let contractType = $scope.contractTypes.find(c => {
          return c.id == item.contractId
        });
        let patient = $scope.patientsMap[item.patientId];        
        item.contractTypeName = (contractType && contractType.name) || item.contractId;        
        item.patient = {displayId: patient?.displayId, displayName: patient?.displayName};
      }

      if (item.caregiverId) {
        item.caregiver = $scope.caregiversMap[item.caregiverId];
      }
    });
  };

  function checkEssentialAssets() {
    const patientsState = initializeMap['patients'];
    const contractTypesState = initializeMap['contractTypes'];
    const caregiversState = initializeMap['caregivers'];

    if (patientsState && caregiversState && contractTypesState) {
        $scope.assetsAreReady = true;
        return;
    }
    $scope.assetsAreReady = false;
    return;
  }

  let initTable = function (items) {
    let options = {
      count: 25
    };

    mapItems(items);

    $scope.tableParams = new NgTableParams(options, {
      dataset: items
    });
  };

  $scope.getSignedUrl = function (row) {
    DatabaseApi.get("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/export_batches/signed_url/" + row.id).then(res => {
      generalUtils.downloadURI(res.data.url, `medflyt-export-batch-${row.contractName}-${row.displayId}`)
    }, function (error) {
      toaster.pop("error", "Failed to download file from cloud");
    });
  }

  $rootScope.$on("got_patients_data", () => {
    $scope.patientsMap = DatabaseApi.patients();
    checkEssentialAssets();

    if ($scope.assetsAreReady) {
        initialize();
    }
  });

  $rootScope.$on("got_caregivers_data", () => {
      $scope.caregiversMap = DatabaseApi.caregivers();
      checkEssentialAssets();

      if ($scope.assetsAreReady) {
          initialize();
      }
  });

  $rootScope.$on("got_contract_types", () => {
    $scope.contractTypes = DatabaseApi.contractTypes();
    checkEssentialAssets();

    if ($scope.assetsAreReady) {
        initialize();
    }
  });

  $scope.clickTableRow = function (row) {
    if (!row) return;
    $rootScope.openVisitInstanceModal(row.id);
  };

  initialize();
  $scope.$on("refresh_visits", function () {
    initialize();
  })
};
