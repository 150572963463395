//! @ngInject
export function rejectComplianceItemPendingUploadModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster) {

    $scope.rejectReason = "";

    $scope.closeModal = (result = 'cancel') => {
        $uibModalInstance.close(result);
    };

    $scope.submitReject = () => {
        const reason =  $scope.rejectReason !== "" ? $scope.rejectReason : null;
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/caregivers/${$scope.pendingUpload.caregiverId}/uploaded_documents/${$scope.pendingUpload.caregiverDocumentUploadId}/reject`;
        const body = {
            reason: reason
        };
        DatabaseApi.post(url, body).then((res) => {
            toaster.pop('success', 'Successfully rejected upload');
            $scope.pendingUpload.complianceRejected = true;
            $scope.pendingUpload.complianceRejectReason = reason;
            $scope.closeModal('success');
        }, (err) => {
            toaster.pop('error', 'Oops! Something went wrong', 'could not reject upload');
        });
    }

};