//! @ngInject
export function contractSetupModalCtrl($scope, $rootScope, $uibModalInstance, fields, data, DatabaseApi, toaster, FormService, $uibModal, itemSearchPageManager, NgTableParams, rangesCalculateService, wildcard) {
    const DEFAULT_AUTO_APPROVE = false;

    $scope.isLoading = false;
    $scope.loadingIssuesTable = true;
    $scope.viewModeOnly = false;
    if (!$scope.viewModeOnly) {
      $scope.payrollCodes = DatabaseApi.activePayrollCodes();
    }
    $scope.fields = FormService.getFields(fields, data, $scope.viewModeOnly);

    if (data.id) {
      const officeField = $scope.fields.groups[0].fields.find(field => field.name === "offices");
      if (officeField) {
          officeField.disabled = true;
      }
        
      $scope.historyTables = [
          { tableName: "contract_type", recordId: data.id }
      ];
    }

    $scope.hideSaveFormButton = true;
    $scope.form = {};
    $scope.eBillingProvidersOptions = DatabaseApi.eBillingProviders();
    $scope.cmsTypeOptions = ['Medicare', 'Medicaid', 'Other'];
    $scope.ediExportOptions = [
      { name: "837I", label: "Institutional" },
      { name: "837P", label: "Professional" }];
    $scope.admissionDateTypeOptions = ['DT', 'D8'];
    $scope.eBilling = {
      eBillingProvider: data.eBillingProvider,
      exportType: data.exportType === undefined ? undefined : $scope.ediExportOptions.find(item => item.name === data.exportType).name,
      exportAdmissionDateType: data.exportAdmissionDateType,
      exportShowAuthorization: data.exportShowAuthorization,
      exportShowNMSegment: data.exportShowNMSegment,
      etin: data.etin,
      mmis: data.mmis,
      exportShowMedicalRecordNumber: data.exportShowMedicalRecordNumber,
      exportBillingUnitsDivision: data.exportBillingUnitsDivision,
      exportGSReceiverIdCode: data.exportGSReceiverIdCode,
      cms1500Type: data.cms1500Type,
      cms1500SignaturePerson: data.cms1500SignaturePerson,
      ediEmednyLocationCode: data.ediEmednyLocationCode,
      ediClaimFilingIndicatorCode: data.ediClaimFilingIndicatorCode,
      ediFaciltyTypeCode: data.ediFaciltyTypeCode, 
      ediShowCompositeMedicalProcedureIdentifier: data.ediShowCompositeMedicalProcedureIdentifier
    };
    $scope.calendarPopups = {};
    $scope.locationGroups = [];
    $scope.counties = [];

    $scope.issueGroups = [
      {
        prefix: "billing_issue_",
        label: "Billing",
        tooltip: "Prevent visits to be billed.",
      },
      {
        prefix: "payroll_issue_",
        label: "Payroll",
        tooltip: "Prevent visits from going into payroll"
      },
    ];

    $scope.contractTablesMap = {
        defaultDiagnosisCodes: null,
        billingRates: null
    }

    $scope.dateOptions = {
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
    };

    $scope.submitForm = () => {
        if ($scope.form.data.$invalid || $scope.formSending) {
            return;
        }

        $scope.formSending = true;

        var newData = FormService.prepareFormData($scope.fields);
        newData.offices = newData.offices.map(x => x.id);
        // Add agency info
        newData.agency = $rootScope.agencyId;
        newData = {...newData, ...$scope.eBilling};

        for (const approval of $scope.autoApprovalItems ) {
          newData[approval.item] = $scope.autoApprovals[approval.item];
        }

        if (newData.active === null) {
            newData.active = false;
        }

        if (data.id) {
            DatabaseApi.put('agencies/' + $rootScope.agencyId + '/contract_type/' + data.id, newData) //{[field.name]: field.value})
                .then(function (res) {
                    toaster.pop('success', "Contract type saved");
                    $scope.closeModal();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Could not change Contract settings")
                    $scope.formSending = false;
                });
        } else {
            DatabaseApi.post('agencies/' + $rootScope.agencyId + '/contract_type', newData).then(function (res) {
                toaster.pop('success', "Contract type saved");
                $scope.closeModal();
            }, function (err) {
                toaster.pop('error', "Something went wrong", "could not add contract");
                $scope.formSending = false;
            });
        }
    };

    $scope.closeModal = function () {
        $uibModalInstance.close('ok');
    };

    $scope.exit = function () {
        $uibModalInstance.dismiss();
    };

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    $scope.openRateModal = function (rate, viewMode) {
        $scope.getCountiesData();
        var newScope = $scope.$new();
        newScope.locationGroups = $scope.locationGroups;
        newScope.counties = $scope.counties;
        newScope.contractId = data.id;
        newScope.contractBillingType = $scope.eBilling.eBillingProvider;
        newScope.viewModeOnly = viewMode;
        newScope.getRates = $scope.getRates;
        const agencyMembers = DatabaseApi.getAgencyMembers();
        newScope.rateHistory = $scope.billingRates.map(billingRate => ({...billingRate, createdByName: agencyMembers[billingRate.createdBy]}));


        return $uibModal.open({
            templateUrl: 'admin/views/billing-rate-setup-modal.html',
            size: 'lg',
            scope: newScope,
            controller: 'billingRateSetupModalCtrl',
            resolve: {
                fields: function () { return DatabaseApi.formFields('Rate'); },
                data: rate ? rate : {}
            },
            windowTopClass: "boost-modal"
        });
    }

    $scope.openDiagnosisModal = function (diagnosis) {
        var newScope = $scope.$new();
        newScope.contractId = data.id;
        newScope.viewModeOnly = diagnosis ? true : false;
        newScope.getDefaultDiagnosisCodes = $scope.getDefaultDiagnosisCodes;

        return $uibModal.open({
            templateUrl: 'admin/views/diagnosis-code-setup-modal.html',
            size: 'lg',
            scope: newScope,
            controller: 'diagnosisCodeSetupModalCtrl',
            resolve: {
                data: diagnosis ? diagnosis : {}
            },
            windowTopClass: "boost-modal"
        });
    }

    $scope.getCountiesData = function () {
        var locationGroupsAndCounties = DatabaseApi.locationGroupsAndCounties();
        $scope.locationGroups = locationGroupsAndCounties.locationGroups;
        $scope.counties = locationGroupsAndCounties.counties;
    }

    $scope.getRates = function () {
        DatabaseApi.getBillingRates(data.id).then(function (res) {
            $scope.billingRates = res.data.billingRates;
            for(let bRateIdx in $scope.billingRates){
                $scope.billingRates[bRateIdx]
            }
            let serviceCodesArr  = DatabaseApi.serviceCodes();
            for(const i in $scope.billingRates){
                let serviceCodeName  = serviceCodesArr.filter((x) => x.id === $scope.billingRates[i].serviceCode)[0].code;
                $scope.billingRates[i].serviceCodeName = serviceCodeName;
                for(const rtIdx in $scope.billingRates[i].rates){
                    $scope.billingRates[i].rates[rtIdx].rateInCents = $scope.billingRates[i].rates[rtIdx].rateInCents / 100;
                    $scope.billingRates[i].rates[rtIdx].weekendRateInCents = ($scope.billingRates[i].rates[rtIdx].weekendRateInCents ?? $scope.billingRates[i].rates[rtIdx].rateInCents) / 100;
                }
            }

            const uniqueServiceCodes = [...new Set($scope.billingRates.map(billingRate => billingRate.serviceCode))].map(serviceCodeId => ({
              id: serviceCodeId,
              name: $scope.billingRates.find(billingRate => billingRate.serviceCode === serviceCodeId).serviceCodeName,
              isOpen: false
            }));
            
            $scope.billingRateRangesDisplay = [];
            uniqueServiceCodes.forEach(serviceCode => {
              $scope.billingRateRangesDisplay = 
                [...$scope.billingRateRangesDisplay, 
                 ...rangesCalculateService.calculateRanges($scope.billingRates.filter(billingRate => billingRate.serviceCode === serviceCode.id))]; 
            });

            setPayRatesTable(uniqueServiceCodes);
            setContractTablesMap('billingRates', $scope.billingRateRangesDisplay);
        }, function (err) {
            toaster.pop('error', "Failed to retrieve billing rates");
        });
    }

    $scope.getDefaultDiagnosisCodes = function () {
      const url = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/contracts/:contractId/default_diagnosis_codes",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        data.id
      );
      DatabaseApi.get(url).then((res) => {
        setContractTablesMap('defaultDiagnosisCodes', res.data.defaultDiagnosisCodes);
      }, (err) => {
        toaster.pop('error', "Something went wrong", "Failed to retrieve billing rates default diagnosis code");
      });
    }

    function setOfficesForIssues(contractTypeBillingIssues) {
      if ($scope.offices === undefined) {
        return;
      }

      $scope.officesForIssuesTable = $scope.offices.map(office => ({
        id: office.id,
        officeName: office.name,
        contractIssues: [],
        issuesToggleOn: true,
      }));

      if ($scope.officesForIssuesTable.length && $scope.officesForIssuesTable[0].id !== undefined) {
        $scope.selectedOfficeForIssuesTabId = $scope.officesForIssuesTable[0].id;
      }

      $scope.officesForIssuesTable.forEach((officeForIssues) => {
        const allValidators = angular.copy(
          $scope.validatorsDataManager.getAllValidators()
        );
        allValidators.forEach(function (validator) {
          if (validator.id === "same_day_visits_with_issues") {
            return;
          }
          const issues = contractTypeBillingIssues.get(officeForIssues.id);
          $scope.issueGroups.forEach((issueGroup) => {
            validator[issueGroup.prefix + validator.id] =
              issues && issues[issueGroup.prefix + validator.id] !== undefined
                ? issues[issueGroup.prefix + validator.id]
                : false;
          });
          officeForIssues.contractIssues.push(validator);
        });
      });
    }

    $scope.updateIssuesStatus = function (officeObj) {
      const { contractIssues, id } = officeObj;
      const body = {
        contractId: data.id,
        officeId: id,
      };

      contractIssues.forEach((issueType) => {
        $scope.issueGroups.forEach((issueGroup) => {
            body[issueGroup.prefix + issueType.id] =
            issueType[issueGroup.prefix + issueType.id] !== undefined
              ? issueType[issueGroup.prefix + issueType.id]
              : false;
        });
      });

      const url = wildcard(
        "agencies/:agencyId/contractType/:contractTypeId/contract_type_billing_issues",
        $rootScope.agencyId,
        data.id
      );
      DatabaseApi.post(url, body).then((res) => {
        toaster.pop("success", "Successfully updated billing issues",);
      }, (err) => {
        toaster.pop("error", "Something went wrong", "Failed to update billing issues");
      });
    };

    $scope.getIssues = function () {
      $scope.loadingIssuesTable = true;
      $scope.validatorsDataManager = $scope.pageManager.getValidatorsDataManager();

      const url = wildcard(
        "agencies/:agencyId/contractType/:contractTypeId/contract_type_billing_issues",
        $rootScope.agencyId,
        data.id
      );
      DatabaseApi.get(url).then((res) => {
        const issuesMap = new Map();
        res.data.contractTypeBillingIssues.forEach((issues) => {
          if (issuesMap.get(issues.officeId) === undefined) {
            issuesMap.set(issues.officeId, issues);
          }
        });
        setOfficesForIssues(issuesMap);
        setIssuesToggleState();
        $scope.loadingIssuesTable = false;
      }, (err) => {
        toaster.pop("error", "Something went wrong", "Failed to retrieve billing issues");
        $scope.loadingIssuesTable = false;
      });
    };

    $scope.toggleAllIssues = function (officeId) {
      const office = $scope.officesForIssuesTable.find(({id})=> officeId === id);
      if (office === undefined) return;

      const { issuesToggleOn, contractIssues } = office;
      const body = {
        contractId: data.id,
        officeId: officeId,
      };

      $scope.loadingIssuesTable = true;
      contractIssues.forEach((issueType) => {
        $scope.issueGroups.forEach((issueGroup) => {
          const value =
            issueType[issueGroup.prefix + issueType.id] !== undefined
              ? issuesToggleOn
              : false;
          body[issueGroup.prefix + issueType.id] = value;
          issueType[issueGroup.prefix + issueType.id] = value;
        });
      });

      const url = wildcard(
        "agencies/:agencyId/contractType/:contractTypeId/contract_type_billing_issues",
        $rootScope.agencyId,
        data.id
      );
      DatabaseApi.post(url, body).then((res) => {
        toaster.pop("success", "Successfully updated billing issues");
        office.issuesToggleOn = !issuesToggleOn;
        $scope.loadingIssuesTable = false;
      }, (err) => {
        toaster.pop("error", "Something went wrong", "Failed to update billing issues");
        $scope.loadingIssuesTable = false;
      });
    };

    function setContractTablesMap(tableKey, items) {
        var options = {
            count: Infinity,
            sorting: { id: "desc" }
        };

        $scope.contractTablesMap[tableKey] = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function setPayRatesTable(items) {
      const options = {
          sorting: { name: "asc" },
          count: 5
      };
    
      $scope.payRatesTable = new NgTableParams(options, {
          counts: [],
          dataset: items
      });
    }

    function setIssuesToggleState() {
      for (const office of $scope.officesForIssuesTable) {
        office.issuesToggleOn = office.contractIssues.some((issueType) =>
          $scope.issueGroups.some((issueGroup) => {
            const issue = issueType[issueGroup.prefix + issueType.id];
            return issue === false || issue === undefined;
          })
        );
      }
    }

    $scope.setCurrentOfficeIssues = (officeForIssuesId) => {
      $scope.selectedOfficeForIssuesTabId = officeForIssuesId;
    }

    $scope.initAutoApprovalSettings = () => {
      $scope.autoApprovalItems = [
        {
          item: "autoApproveAdmissionDate",
          title: "Admission Date",
          tooltip: ""
        },
        {
          item: "autoApproveAuthNumber",
          title: "Authorization Number",
          tooltip: ""
        },
        {
          item: "autoApproveBillingRate",
          title: "Billing Rate",
          tooltip: ""
        },
        {
          item: "autoApproveDiagnosisCodes",
          title: "Diagnosis Code",
          tooltip: ""
        },
        {
          item: "autoApproveIsHoliday",
          title: "Holiday",
          tooltip: ""
        },
        {
          item: "autoApproveLocation",
          title: "Location",
          tooltip: ""
        },
        {
          item: "autoApproveOffice",
          title: "Office",
          tooltip: ""
        },
        {
          item: "autoApprovePatientInfo",
          title: "Patient Info",
          tooltip: ""
        },
        {
          item: "autoApproveVisitRate",
          title: "Visit Rate",
          tooltip: ""
        }
      ];

      $scope.autoApprovals = {};

      for (const approval of $scope.autoApprovalItems ) {
        $scope.autoApprovals[approval.item] = data[approval.item] ?? DEFAULT_AUTO_APPROVE;
      }

      resetAutoApprovalToggle();
    }

    $scope.toggleAutoApprovalSettings = () => {      
      for (const approval of $scope.autoApprovalItems ) {
        $scope.autoApprovals[approval.item] = $scope.autoApprovalToggleOn;
      }
      $scope.updateAutoApprovalStatus();
    }

    function resetAutoApprovalToggle() {
      $scope.autoApprovalToggleOn = $scope.autoApprovalItems.some((autoApprovalItem) => $scope.autoApprovals[autoApprovalItem.item] === false || $scope.autoApprovals[autoApprovalItem.item] === undefined);
    }

    $scope.updateAutoApprovalStatus = function () {
      if (data.id) {
        $scope.formSending = true;
        DatabaseApi.put('agencies/' + $rootScope.agencyId + '/contract_type/' + data.id, $scope.autoApprovals) //{[field.name]: field.value})
            .then(function (res) {
                toaster.pop('success', "Contract type saved");
                $scope.formSending = false;
            }, function (err) {
                toaster.pop('error', "Something went wrong", "Could not change auto-approval settings")
                $scope.formSending = false;
            });
          }
      resetAutoApprovalToggle();
    }

    function init() {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/contract_settings");

        $scope.getCountiesData();
        // get rates only if contract exists
        // if ($scope.viewModeOnly) {
        //     $scope.getRates();
        //     $scope.getDefaultDiagnosisCodes();
        //     $scope.getIssues();
        // }
        $scope.getRates();
        $scope.getDefaultDiagnosisCodes();
        $scope.getIssues();
        $scope.initAutoApprovalSettings();
    }

    init();
};


export function diagnosisType() {
    return function(type) {
        switch (type) {
            case "PRINCIPAL":
                return "Principal diagnosis";
            case "SURGICAL":
                return "Surgical procedure";
            case null:
                return "Other pertinent diagnosis";
            default:
                null;
      }
    }
};
