import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function ExclusionListsCtrl(
    $scope,
    $rootScope,
    $uibModal,
    DatabaseApi,
    toaster,
    NgTableParams
  ) {
    $scope.isLoading = true;
    $scope.isLoadingRow = {
      flag: undefined,
      terminate: undefined,
    };

    $scope.dateRangeOptions = {
      ranges: {
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 14 Days": [moment().subtract(13, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "Last Year": [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ],
        "This Year": [moment().startOf("year"), moment().endOf("year")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      },
      alwaysShowCalendars: true,
      applyClass: "btn-primary",
      locale: {
        direction: "ltr date-range-picker-v2",
        format: "D MMM YY",
      },
      autoApply: true,
      minDate: new Date("2018-01-01"),
    };

    $scope.statuses = [
      { id: 0, label: "Flagged" },
      { id: 1, label: "Un-flagged" },
      { id: 2, label: "No match" },
    ];

    $scope.filters = {
      name: "",
      onChange: {
        dateRange: {
          startDate: moment().startOf("month"),
          endDate: moment().endOf("month"),
        },
        statuses: [],
        includeNonMatching: false,
      },
    };

    $scope.$watch(
      "filters.onChange",
      function (newValue, oldValue) {
        const startDateChanged = !newValue.dateRange.startDate.isSame(
          oldValue.dateRange.startDate
        );
        const endDateChanged = !newValue.dateRange.endDate.isSame(
          oldValue.dateRange.endDate
        );
        const includeNonMatchingChanged = newValue.includeNonMatching !== oldValue.includeNonMatching;
        const shouldLoadFromServer = startDateChanged || endDateChanged || includeNonMatchingChanged;

        handleData(shouldLoadFromServer);
      },
      true
    );

    $scope.caregivers = [];
    $scope.caregiversDisplay = [];

    (function init() {
      handleData(true);
    })();

    function handleData(shouldLoadFromServer = false) {
      if (shouldLoadFromServer) {
        $scope.isLoading = true;
        const from = LocalDate.from(
          nativeJs($scope.filters.onChange.dateRange.startDate)
        );
        const to = LocalDate.from(
          nativeJs($scope.filters.onChange.dateRange.endDate)
        );
        const includeNonMatching = $scope.filters.onChange.includeNonMatching;
        DatabaseApi.get(
          `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/exclusion_list?from=${from}&to=${to}&includeNonMatching=${includeNonMatching}`
        )
          .then(({ data: { caregivers } }) => {
            $scope.caregivers = enrichData(caregivers);
            $scope.caregiversDisplay = filter(angular.copy($scope.caregivers));
            initTable($scope.caregiversDisplay);
          })
          .catch((err) => {
            toaster.pop("error", "Couldn't load exclusion list");
            console.log(err);
          })
          .finally(() => ($scope.isLoading = false));

        return;
      }

      $scope.caregiversDisplay = filter(angular.copy($scope.caregivers));
      initTable($scope.caregiversDisplay);
    }

    function enrichData(caregivers) {
      for (const caregiver of caregivers) {
        caregiver.status = getStatus(caregiver);
        caregiver.color = getStatusColor(caregiver);
        caregiver.originalRowFormatted = getOriginalRowFormatted(
          caregiver.rowJson
        );
        caregiver.longEnoughToHide = caregiver.originalRowFormatted && caregiver.originalRowFormatted.length > 4;
      }
      return caregivers;
    }

    function filter(caregivers) {
      const selectedStatuses = $scope.filters.onChange.statuses.map(function (
        selectedStatus
      ) {
        return $scope.statuses.find(function (stat) {
          return stat.id === selectedStatus.id;
        }).label;
      });
      const showAllUnreviewed = selectedStatuses.indexOf("Un-flagged") !== -1;

      return caregivers.filter((caregiver) => {
        if (showAllUnreviewed && caregiver.flagged === null) {
          return true;
        }
        if (
          selectedStatuses.length > 0 &&
          selectedStatuses.indexOf(caregiver.status) === -1
        ) {
          return false;
        }

        return true;
      });
    }

    function initTable(data) {
      let page = false;
      let sorting = { createdAt: "desc" };

      if ($scope.table) {
        sorting = $scope.table.sorting();
        page = $scope.table.page();
      }

      const options = {
        count: 25,
        sorting: sorting,
      };

      $scope.table = new NgTableParams(options, {
        counts: [],
        dataset: data,
      });
    }

    $scope.searchByName = function (name) {
      $scope.caregiversDisplay = filter(
        $scope.caregivers
      ).filter(({ fullName }) =>
        fullName.toLowerCase().includes(name.toLowerCase())
      );
      initTable($scope.caregiversDisplay);
    };

    $scope.flagSwitch = function (caregiver) {
      if (!caregiver) return;

      $scope.isLoadingRow.flag = caregiver.exclusionId;

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/exclusionListActionsModal.html",
        size: "md",
        controller: "ExclusionListActionsModalCtrl",
        resolve: {
          caregiver: function () {
            return caregiver;
          },
          action: function () {
            return caregiver.flagged ? "Un-flag" : "Flag";
          },
          caregivers: function () {
            return $scope.caregivers;
          },
        },
      });

      modalInstance.result
        .then(function ({ action, result }) {
          if (action === "FLAGGED") {
            flaggedSideEffect(caregiver, result);
            const caregiverInCache = $scope.caregivers.find(
              ({ exclusionId }) => exclusionId === caregiver.exclusionId
            );
            if (!caregiverInCache) return;

            flaggedSideEffect(caregiverInCache, result);
          }
        })
        .finally(() => ($scope.isLoadingRow.flag = undefined));
    };

    function flaggedSideEffect(caregiver, { flag, note }) {
      caregiver.flagged = flag;
      caregiver.status = getStatus(caregiver.flagged);
      caregiver.color = getStatusColor(caregiver);
      caregiver.reviewedByName = `${$rootScope.user.agencyMember.firstName} ${$rootScope.user.agencyMember.lastName}`;
      caregiver.reviewedAt = new Date();
      caregiver.noteText = note.noteRichText;
      caregiver.notePredefinedAnswer = note.predefinedAnswer;
    }

    $scope.terminateCaregiver = function (caregiver) {
      if (!caregiver) return;

      $scope.isLoadingRow.terminate = caregiver.exclusionId;

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/exclusionListActionsModal.html",
        size: "md",
        controller: "ExclusionListActionsModalCtrl",
        resolve: {
          caregiver: function () {
            return caregiver;
          },
          action: function () {
            return "Terminate";
          },
          caregivers: function () {
            return $scope.caregivers;
          },
        },
      });

      modalInstance.result
        .then(function ({ action }) {
          if (action === "TERMINATED") {
            // If caregiver was termindated, he shouldn't appear in the table.
            $scope.caregiversDisplay = filter(
              angular.copy(
                $scope.caregivers.filter(({ id }) => id !== caregiver.id)
              )
            );
            initTable($scope.caregiversDisplay);
          }
        })
        .finally(() => ($scope.isLoadingRow.terminate = undefined));
    };

    $scope.shouldShowMore = function ({ longEnoughToHide, showMoreState }) {
      return longEnoughToHide && !showMoreState;
    };

    $scope.stripHtml = (html) => {
      const tempElement = document.createElement("DIV");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
   };

    function getStatus(caregiver) {
      if (caregiver.noMatch) return "No match";
      return caregiver.flagged ? "Flagged" : "Un-flagged";
    }

    function getStatusColor(caregiver) {
      if (caregiver.noMatch) return "azur";
      return caregiver.flagged ? "red" : "green";
    }

    function getOriginalRowFormatted(json) {
      if (!json) return;
      const originalRow = json.v;
      if (!originalRow) return;

      const keys = Object.keys(originalRow);
      const keyValuePairs = keys.map((key) => ({
        key,
        value: originalRow[key],
      }));

      return keyValuePairs;
    }
  }
