import { LocalDateTime } from "js-joda";
import moment from "moment";

/* @ngInject */
export function dashboardCtrl(DatabaseApi, $scope, $rootScope, $state, Storage, $timeout, $uibModal, Analytics, NgTableParams, $location) {

    window.scrollTo(0, 0);

    var agencyId = $rootScope.agencyId;
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();

    $scope.$on('got_data', function () {
        $scope.agencyMembers = DatabaseApi.getAgencyMembers();

        if(Object.keys($scope.agencyMembers).length !== 0) {
            if($scope.table && $scope.table.data && $scope.table.data[0] && $scope.table.data[0].agencyMemberName === undefined) {
                $scope.refreshTable($scope.table.data.map(function(agencyMember) {
                    agencyMember.agencyMemberName = $scope.agencyMembers[agencyMember.agencyMemberId];
                    return agencyMember;
                }));
            }

        }
    });

    $scope.datePicker = {
        date: {
            startDate: moment().subtract(6, 'days'),
                endDate: new Date()
        },
        options: {
            applyClass: 'btn-primary',
                locale: {
                applyLabel: "Apply",
                    fromLabel: "From",
                    //format: "YYYY-MM-DD", //will give you 2017-01-06
                    format: "D MMM YY", //will give you 6  Jan 17
                    //format: "D-MMMM-YY", //will give you 6-January-17
                    toLabel: "To",
                    cancelLabel: 'Cancel'
            }, ranges: {
                'Last Day': [moment().subtract(1, 'days'), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Last 6 Months': [moment().subtract(6, 'months'), moment()],
                'Last Year': [moment().subtract(1, 'years'), moment()]
            }
        }
    };

    var justStrated = true;
    $scope.dateChanged = function (newValue) {
        if(justStrated){
            justStrated = false;
        } else {
            if (newValue.startDate !== null && newValue.endDate !== null) {
                $scope.isLoading = true;
                $scope.showCoordinatorActivity = false;
                $scope.getCoordinatorActivity();
            }
        }
    };

    $scope.refreshTable = function (data)  {
        $scope.table = new NgTableParams({
            count: 200,
            sorting: {
                scheduled: 'desc'
            }
        }, {
            counts: [],
            dataset: data
        });
    };

    $scope.$watch('datePicker.date', $scope.dateChanged);

    $scope.redirectDownloadBoost = function () {
        Analytics.event("Redirect download boost", {from: "dashboard"});
        $state.go('app.communication', {view: 'sendDownload'})
    };

    $scope.goTo = function (page) {
        $state.go('app.' + page);
    };

    /*var tour = Storage.get('tour');
    if (tour !== 'true') {
        uiTourService.getTour('welcome').start();
        Storage.set('tour', 'true');
    }*/

    /*var showVideoModal = Storage.get('showVideoNewVisitForm');
    if (showVideoModal !== 'true') {

      var modalInstance = $uibModal.open({
          templateUrl: 'admin/views/video-modal.html',
          size: 'lg',
          controller: 'videoModalCtrl'
      });
      modalInstance.result.then(function (res) {

      }, function () {

      });

    }*/

    $scope.getCoordinatorActivity = function (click) {

        if($scope.showCoordinatorActivity && click) return;

        var newValue = angular.copy($scope.datePicker.date);

        DatabaseApi.get('agency/' + agencyId + '/coordinator_activity?startDate=' + LocalDateTime.parse(newValue.startDate.toISOString().replace('Z', '')).toString() + '&endDate=' + LocalDateTime.parse(newValue.endDate.toISOString().replace('Z', '')).toString()).then(function(result) {

            $scope.refreshTable(result.data.activities.map(function(agencyMember)  {
                agencyMember.agencyMemberName = $scope.agencyMembers[agencyMember.agencyMemberId];
                return agencyMember;
            }));

            $scope.isLoading = false;
            $scope.showCoordinatorActivity = true;

        });


    };

    $scope.getVisitStats = function () {

        if ($scope.showStats) {
            return;
        }

        DatabaseApi.get('admin/agencies/' + $rootScope.agencyId + '/visit_stats').then(function (json) {
            var records = [];

            for (var index = 0; index < json.data.visitStats.length; index++) {
                var visit = json.data.visitStats[index];
                var visitId = visit.visitId;
                var createdAt = new Date(visit.visitDetails.createdAt);
                var assignedToTimeDelta = null;
                if (visit.assignedToEvents.length > 0) {
                    var assignedToEvent = visit.assignedToEvents[0];
                    var assignedToAt = new Date(assignedToEvent["@timestamp"]);
                    assignedToTimeDelta = assignedToAt.getTime() - createdAt.getTime();
                }
                var caregiversRequested = new Set();
                var caregiversChatted = new Set();
                var caregiversPreViewed = new Set();
                var caregiversViewed = new Set();
                for (var i = 0; i < visit.caregiverEvents.length; i++) {
                    var ces = visit.caregiverEvents[i];
                    for (var j = 0; j < ces.events.length; j++) {
                        var ce = ces.events[j];
                        if (ce.type === "VisitViewed") {
                            caregiversViewed.add(ces.caregiverId);
                        } else if (ce.type === "VisitPreviewed ") {
                            caregiversPreViewed.add(ces.caregiverId);
                        } else if (ce.type === "ChatMessageSent") {
                            caregiversChatted.add(ces.caregiverId);
                        } else if (ce.type === "VisitRequested") {
                            caregiversRequested.add(ces.caregiverId);
                        }
                    }
                }

                var numPreViewed = caregiversPreViewed.size;
                var numViewed = caregiversViewed.size;
                var numRequested = caregiversRequested.size;
                var numChatted = caregiversChatted.size;

                records.push({
                    visitId: visitId,
                    visitCreatedAt: createdAt,
                    numMatchingCaregivers: visit.numMatchingCaregivers,
                    numViewed: numViewed,
                    numPreViewed: numPreViewed < numViewed ? numViewed : numPreViewed,
                    numRequested: numRequested,
                    numChatted: numChatted,
                    assignedToTimeDelta: (assignedToTimeDelta === null ? null : assignedToTimeDelta / 1000 / 60)
                });
            }

            $scope.totalVisits = records.length;
            var allMatchingCaregivers = 0;
            var allPreViewedCase = 0;
            var allViewedCase = 0;
            var allRequested = 0;
            var allTimeDeltas = 0;
            var allChated = 0;
            var assigned = 0;
            records.forEach(function (record) {
                allMatchingCaregivers += record.numMatchingCaregivers;
                allPreViewedCase += record.numPreViewed;
                allViewedCase += record.numViewed;
                allRequested += record.numRequested;
                allChated += record.numChatted;
                allTimeDeltas += record.assignedToTimeDelta;
                if (record.assignedToTimeDelta) assigned++;
            });

            $scope.allRequested = allRequested;
            $scope.assigned = assigned;
            $scope.assignedPercent = Math.round(assigned / records.length);
            $scope.visitCount = records.length;
            $scope.averageMatchingCaregivers = Math.round(allMatchingCaregivers / records.length);
            $scope.averagePreViewsPerCase = Math.round(allPreViewedCase / records.length);
            $scope.averageViewsPerCase = Math.round(allViewedCase / records.length);
            $scope.averageRequested = Math.round(allRequested / records.length);
            $scope.averageChated = Math.round(allChated / records.length);

            var deltaSum = records.filter(function (record) {
                if (record.assignedToTimeDelta !== null) {
                    return record;
                }
            });
            $scope.averageTimeDeltas = Math.round(allTimeDeltas / deltaSum.length);

            $scope.showStats = true;

        }, function (err) {
            $scope.showStats = false;
        });
    }

    DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/dashboard').then(function (res) {
        $scope.dashData = res.data;
    });

    var pathArr = $location.$$path.split('/');
    $scope.dashboardPage = 'dashboard';
    if (pathArr && pathArr.length) {
        $scope.dashboardPage = pathArr[pathArr.length - 1];

        switch ($scope.dashboardPage) {
            case 'visitStatistics': {
                $scope.getVisitStats();
                break;
            }
            case 'coordinatorActivity': {
                $scope.getCoordinatorActivity(true);
                break;
            }
        }
    }

    $scope.dashTabs = {
        activeTab: $scope.dashboardPage === 'dashboard' ? 'caregivers' : $scope.dashboardPage,
        caregivers: {
            activeTab: 'active'
        },
        installations: {
            activeTab: 'installed'
        },
        status: {
            activeTab: 'day',
            day: {count: 0, data: []},
            week: {count: 0, data: []},
            month: {count: 0, data: []}
        }
    };

};
