//! @ngInject
export function selectionLogic() {

    /*

    // example for 'onInitItem' method:
    onInitItem = function (item) {
        //DO SOMETHING
    };

    // the default value for 'itemKeyPropertyName' is "id", unless it is specified otherwise

    */
    var createNewLogic = function (onInitItem, itemKeyPropertyName) {

        var keyName = itemKeyPropertyName || "id";

        var selectionData = {
            items: [],
            selectedItems: {},
            countSelectedItems: 0,
            areAllItemsSelected: false
        };

        // atom action
        var resetSelectionData = function () {
            selectionData.selectedItems = {};
            selectionData.countSelectedItems = 0;
            selectionData.areAllItemsSelected = false;
            selectionData.items.forEach(item => item.isSelected = false);
        };

        // atom action
        var indicateItemIsSelected = function (item) {
            selectionData.selectedItems[item[keyName]] = item;
            selectionData.countSelectedItems++;
        };

        // atom action
        var indicateItemIsNotSelected = function (item) {
            delete selectionData.selectedItems[item[keyName]];
            selectionData.countSelectedItems--;
        };

        var initItemSelection = function (item, toSelect) {
            item.isSelected = toSelect || false;
            if (toSelect) {
                indicateItemIsSelected(item);
            }
        };

        var updateSelectionAfterItemSelectionChanged = function (item) {
            if (item.isSelected) {
                indicateItemIsSelected(item);
            }
            else {
                indicateItemIsNotSelected(item);
            }
        };

        var changeItemSelection = function (item, toSelect) {
            item.isSelected = toSelect;
            updateSelectionAfterItemSelectionChanged(item);
        };

        var updateIfAllItemsSelected = function () {
            var itemsAmount = selectionData.items.length;
            selectionData.areAllItemsSelected = !!itemsAmount && selectionData.countSelectedItems === itemsAmount;
        };

        var logic = {

            type: "standard",

            data: selectionData,

            initItemsCollection: function (items) {
                resetSelectionData();
                selectionData.items = items;
            },

            resetSelectionData: function () {
                selectionData.selectedItems = {};
                selectionData.countSelectedItems = 0;
                selectionData.areAllItemsSelected = false;
            },

            addItemToCollection: function (item) {
                selectionData.items.push(item);
            },

            initItem: onInitItem
                ? function (item, toSelect) {
                    onInitItem(item);
                    initItemSelection(item, toSelect);
                }
                : initItemSelection,

            countAll: function () {
                return selectionData.items.length;
            },

            hasSelectedItems: function () {
                return selectionData.countSelectedItems > 0;
            },

            countSelected: function () {
                return selectionData.countSelectedItems;
            },

            getSelectedItems: function () {
                return Object.values(selectionData.selectedItems);
            },

            onItemSelectionChanged: function (item) {
                // var isShiftKeyClicked = eventObject.shiftKey;
                updateSelectionAfterItemSelectionChanged(item);
                updateIfAllItemsSelected();
            },

            /**
             * Toggles the selection for all the items based on the 'areAllItemsSelected' value
             */
            toggleAllItems: function () {
                var areAllItemsSelected = selectionData.areAllItemsSelected;
                selectionData.items.forEach(function (item) {
                    if (item.isSelected !== areAllItemsSelected) {
                        changeItemSelection(item, areAllItemsSelected);
                    }
                });
            },

            refreshIfAllItemsSelected: function () {
                updateIfAllItemsSelected();
            },

        };

        return logic;

    };

    var createNewLogicById = function () {

        var selectionData = {
            ids: [],
            selectedIds: {},
            countSelected: 0,
            areAllSelected: false
        };

        var resetSelectionData = function () {
            selectionData.selectedIds = {};
            selectionData.countSelected = 0;
            selectionData.areAllSelected = false;
        };

        var indicateIdIsSelected = function (id) {
            selectionData.selectedIds[id] = id;
            selectionData.countSelected++;
        };

        var indicateIdIsNotSelected = function (id) {
            delete selectionData.selectedIds[id];
            selectionData.countSelected--;
        };

        var isIdSelected = function (id) {
            return Boolean(selectionData.selectedIds[id]);
        };

        var changeIdSelection = function (id, toSelect) {
            if (isIdSelected(id) !== toSelect) {
                if (toSelect) {
                    indicateIdIsSelected(id);
                }
                else {
                    indicateIdIsNotSelected(id);
                }
            }
        };

        var updateIfAllIdsSelected = function () {
            var idsAmount = selectionData.ids.length;
            selectionData.areAllSelected = !!idsAmount && selectionData.countSelected === idsAmount;
        };

        var logic = {

            type: "onlyId",

            data: selectionData,

            initIdsCollection: function (itemIds) {
                resetSelectionData();
                selectionData.ids = itemIds;
            },

            resetSelectionData: function() {
                resetSelectionData();
            },

            countAll: function () {
                return selectionData.ids.length;
            },

            hasSelectedIds: function () {
                return selectionData.countSelected > 0;
            },

            countSelected: function () {
                return selectionData.countSelected;
            },

            isIdSelected: isIdSelected,

            getSelectedIds: function () {
                return Object.values(selectionData.selectedIds);
            },

            updateIdSelection: function (id, toSelect) {
                changeIdSelection(id, toSelect);
                updateIfAllIdsSelected();
            },

            updateSelectionForIdsCollection: function (idsToUpdate, toSelect) {
                idsToUpdate.forEach(function (id) {
                    changeIdSelection(id, toSelect);
                });
                updateIfAllIdsSelected();
            },

            updateSelectionToAll: function (toSelect) {
                selectionData.ids.forEach(function (id) {
                    changeIdSelection(id, toSelect);
                });
                // updateIfAllIdsSelected();
                selectionData.areAllSelected = toSelect;
            },

        };

        return logic;

    };

    /*

    // example for 'onInitRenderedItem' method:
    onInitRenderedItem = function (item) {
        //DO SOMETHING
    };

    */
    var createNewVirtualizedLogic = function (onInitItem, itemKeyPropertyName = "id") {

        var totalLogic = createNewLogicById();
        var renderedLogic = createNewLogic(onInitItem, itemKeyPropertyName);

        var virtualLogic = {

            type: "virtualized",

            totalData: totalLogic.data,
            renderedData: renderedLogic.data,

            initItemIdsCollection: function (itemIds) {
                totalLogic.initIdsCollection(itemIds);
            },

            updateItemsToRender: function (itemsToRender) {
                renderedLogic.initItemsCollection(itemsToRender);
                renderedLogic.data.items.forEach(function (item) {
                    var isAlreadySelected = totalLogic.isIdSelected(item[itemKeyPropertyName]);
                    renderedLogic.initItem(item, isAlreadySelected);
                });
                renderedLogic.refreshIfAllItemsSelected();
            },

            countAll: function () {
                return totalLogic.countAll();
            },

            resetAllSelection: function () {
                return totalLogic.resetSelectionData();
            },

            hasSelectedItems: function () {
                return totalLogic.hasSelectedIds();
            },

            countSelected: function () {
                return totalLogic.countSelected();
            },

            getAllSelectedItemIds: function () {
                return totalLogic.getSelectedIds();
            },

            getRenderedSelectedItems: function () {
                return renderedLogic.getSelectedItems();
            },

            onRenderedItemSelectionChanged: function (item) {
                totalLogic.updateIdSelection(item[itemKeyPropertyName], item.isSelected);
                renderedLogic.onItemSelectionChanged(item);
            },

            /**
             * Toggles the selection for all the items based on the 'totalLogic.data.areAllSelected' value
             */
            toggleAllItems: function () {
                var areAllItemsSelected = totalLogic.data.areAllSelected;
                totalLogic.updateSelectionToAll(areAllItemsSelected);

                renderedLogic.data.areAllItemsSelected = areAllItemsSelected;
                renderedLogic.toggleAllItems();
            },

            /**
             * Toggles the selection for all the rendered items based by the 'renderedLogic.data.areAllItemsSelected' value
             */
            toggleAllRenderedItems: function () {
                var areAllRenderedItemsSelected = renderedLogic.data.areAllItemsSelected;
                renderedLogic.toggleAllItems();
                
                var renderedItemIds = [];
                renderedLogic.data.items.forEach(function (item) {
                    renderedItemIds.push(item[itemKeyPropertyName]);
                });
                totalLogic.updateSelectionForIdsCollection(renderedItemIds, areAllRenderedItemsSelected);
            },

        };

        return virtualLogic;

    };

    

    this.createNewLogic = createNewLogic;
    this.createNewVirtualizedLogic = createNewVirtualizedLogic;

};
