
export const caregiverStatus = {
    all: [
      "PENDING",
      "ACTIVE",
      "ON_HOLD",
      "SUSPENDED",
      "TERMINATED",
      "ON_LEAVE",
      "JOIN_REQUEST",
      "QUIT",
    ],
    dropdown: [
      { id: "ACTIVE", label: "Active" },
      { id: "ON_HOLD", label: "On Hold" },
      { id: "ON_LEAVE", label: "On Leave" },
      { id: "PENDING", label: "Pending Application" },
      { id: "TERMINATED", label: "Terminated" },
      { id: "SUSPENDED", label: "Inactive" },
      { id: "QUIT", label: "Quit" },
    ],
    mapToStatusColor: {
        ACTIVE: "green",
        ON_HOLD: "orange",
        ON_LEAVE: "orange",
        PENDING: "lightblue",
        TERMINATED: "red",
        SUSPENDED: "gray",
        QUIT: "gray"
    }
  };

export type CaregiverStatus = typeof caregiverStatus;

export const patientStatusUtils = {
    dropdown: [
      { id: "REFERRAL", label: "Refferal" },
      { id: "PENDING_FILES", label: "Pending Files" },
      { id: "ELIGIBLE", label: "Eligible" },
      { id: "ACCEPTED", label: "Accepted" },
      { id: "ACTIVE", label: "Active" },
      { id: "DISCHARGED", label: "Discharged" },
      { id: "ON_HOLD", label: "On Hold" },
      { id: "HOSPITALIZED", label: "Hospitalized" },
      { id: "VACATION", label: "Vacation" },
      { id: "DECEASED", label: "Deceased" },
    ],
    mapToStatusColor: {
        REFERRAL:'red',
        PENDING_FILES:'orange',
        ELIGIBLE:'green',
        ACCEPTED:'lightblue',
        ACTIVE: 'green',
        DISCHARGED:'red',
        ON_HOLD: 'orange',
        HOSPITALIZED:'orange',
        VACATION:'orange',
        DECEASED: 'red',
    }
  };

export type PatientStatusUtils = typeof patientStatusUtils;

interface errorResponse{
    data:{
        error:string,
        details:string[]
        invalidEntityType:string | undefined;
        invalidId:any
    }
}
export const apiErrorsConsts = {
    format: (response:errorResponse, fallbackMessage:string) => {
        if (response.data?.error === "Invalid Request") {
            return response.data.details[0];
        }

        return fallbackMessage ?? "Something went wrong";
    }
};

export type apiErrors = typeof apiErrorsConsts;



