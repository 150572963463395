//! @ngInject
export function patientMainDocumentsNewDocumentModalCtrl($rootScope, $scope, $uibModal, $uibModalInstance, $filter, DatabaseApi, toaster) {
      const documentTypes = $scope.$resolve.documentTypes.filter(
        (document) =>
          document.title !== "" &&
          document.removedAt === null &&
          document.versions.find((version) => version.isPublished) !== undefined
      );

      $scope.selectedDocumentType = {...documentTypes.find((x) => x.type === "CMS485") ?? {}};
      $scope.documentTypes = documentTypes.map(({ id, title }) => ({
        id,
        label: title,
      }));
      $scope.isLoading = false;

      $scope.dropdownSettings = {
        styleActive: true,
        scrollable: true,
        enableSearch: true,
        showCheckAll: false,
        showUncheckAll: false,
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1,
        smartButtonTextConverter: (item, original) => item,
      };

      $scope.onClickSaveAndFill = () => {
        $scope.isLoading = true;

        return createForm()
          .then(({ data: document }) => {
            $scope.$resolve.onDocumentCreated(document);
            $uibModal.open({
              templateUrl: "admin/views/edit-patient-doc-modal.html",
              size: "lg",
              controller: "editPatientDocModalCtrl",
              resolve: {
                patientId: $scope.$resolve.patientId,
                document: { ...document, title: $scope.selectedDocumentType.title },
                initialReadonly: false,
                documentReplaceConstants: () => prepareDocumentReplaceConstants,
                onSuccessSubmit: () => () => {
                  $uibModalInstance.close();
                  $scope.$resolve.onDocumentCreated(document);
                },
              },
            });
          })
          .catch(handleError)
          .finally(handleFinally);
      };

      $scope.onClickSaveAndClose = () => {
        $scope.isLoading = true;

        return createForm()
          .then(({ data }) => {
            toaster.pop("success", "Success", "The document has been created");
            $scope.$resolve.onDocumentCreated(data);
            $uibModalInstance.close();

            return data.document;
          })
          .catch(handleError)
          .finally(handleFinally);
      };

      function handleError(e) {
        console.error(e);
        toaster.pop("error", "Oops", "Could not create document");
      }

      function handleFinally() {
        $scope.isLoading = false;
      }

      function createForm() {
        const url =
          `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document_scheduled`
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.$resolve.patientId);

        const payload = {
          documentTypeId: $scope.selectedDocumentType.id,
        };

        return DatabaseApi.post(url, payload);
      }

      async function prepareDocumentReplaceConstants() {
        return {
          agencyName: $rootScope.user.agency.name,
          patientName: $scope.$resolve.patientName,
          agencyAddress: $rootScope.user.agency.address,
          agencyPhone: $filter("americanphone")($rootScope.user.agency.officePhone),
          caregiverName: $rootScope.user.displayName
        };
      }
    }
