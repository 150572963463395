//! @ngInject
export function billingVisitsToExportCtrl($scope, $rootScope, NgTableParams, DatabaseApi, itemSearchPageManager, selectionLogic, toaster, $filter, FilterUtils, billingInvoicesProcessor) {

    $scope.tableParams = null;
    $scope.isLoadingItems = false;
    $scope.globalFilter = { val: "" };

    function initialize() {
        initPageManager();
        initLogics();
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingVisitsToExportCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
        }
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingVisitsToExportCtrl = angular.copy(filters);
    });

    $scope.$on("refresh_visits", function () {
        $scope.loadItems();
    })

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/visits_to_export");

        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();

        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initContractTypesParam();

        $scope.pageManager.initSearchParam("status", "NEW");
        $scope.pageManager.updateSearchParamValue("status", "NEW");

        $scope.pageManager.initSearchParam("includeAwaitingResponse", false);
        $scope.pageManager.initSearchParam("includeReadyToExport", false);

        $scope.pageManager.initSearchParam("dateByInvoice", true, {
            placeholderValue: true,
            queryName: "date-by-invoice",
        });
    };

    $scope.isRowDisabled = (row) => row.isAdjustmentAwaitingResponse;

    $scope.changeSearchStatus = (val) => {
        if (val === "ADJUSTMENT" && $scope.pageManager.searchParams.status.value !== val) {
            $scope.pageManager.updateSearchParamValue("includeAwaitingResponse", false);
            $scope.pageManager.updateSearchParamValue("includeReadyToExport", false);
        }

        $scope.pageManager.searchParams.status.value = val;
    }

    function initLogics(itemIds) {
        initSelection(itemIds || []);
    };

    const initSelection = function (items) {
        $scope.selectionLogic = selectionLogic.createNewLogic((item) => {
            $scope.selectionLogic.addItemToCollection(item);
        });
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    };
    $scope.loadItems = function () {
        $scope.isLoadingItems = true;
        $scope.globalFilter.val = '';

        $scope.pageManager.executeSearch().then(function ({ data }) {
            $scope.visitInstances = data.visitInstances;
            initLogics($scope.visitInstances);
            renderPage($scope.visitInstances);
        }).catch(function (error) {
            toaster.pop("error", "Failed to load visit IDs");
        }).finally(function () {
            $scope.isLoadingItems = false;
        });
    };

    const mapPageItems = function (pageItems) {
        if (!$scope.caregiversMap) {
            $scope.caregiversMap = DatabaseApi.caregivers() || {};
        }

        if (!$scope.patientsMap) {
            $scope.patientsMap = DatabaseApi.patients() || {};
        }
        pageItems.forEach(function (item) {
            if (!item.contractTypeName && item.contractId) {
                const contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractTypeId);
                item.contractTypeName = (contractType && contractType.name) || item.contractId;
            }

            if (item.caregiverId) {
                item.caregiver = $scope.caregiversMap[item.caregiverId];
            }
            if (item.patientId) {
                item.patient = $scope.patientsMap[item.patientId];
            }
        });
    };

    const renderPage = function (pageItems) {
        const hasPageItems = pageItems && pageItems.length;
        if (hasPageItems) {
            mapPageItems(pageItems);
        }

        $scope.tableParams = new NgTableParams(
            { count: 25 },
            {
                counts: [],
                dataset: pageItems,
            }
        );
    };

    $scope.applyGlobalSearch = (term) => {
        let filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const visitInstances = $filter('filter')($scope.visitInstances, $scope.globalFilter.val);
            initSelection(visitInstances);
        }
    };

    $scope.clickTableRow = function (row) {
        if (!row) return;
        $rootScope.openVisitInstanceModal(row.id);
    };

    $scope.openModalToExportInvoices = function () {
        const selectedItems = $scope.selectionLogic.getSelectedItems();
        billingInvoicesProcessor.openInvoicesExporterModalByVisits(selectedItems.map(item => item.id), $scope.loadItems);
    };

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = ['Patient', 'Caregiver', 'Caregiver Code', 'Office', 'Contract', 'Visit Date', 'Schedule Time', 'Recorded Time', 'Current Total', 'Total Potential Amount',
            'Total Payment', 'Days For Timely Filing', 'Patient Medflyt ID', 'Patient HHA Exchange ID',
            'Patient Admission ID', 'Patient SSN', 'Patient Medicare Number', 'Patient Medicaid Number',
            'Patient Address', 'Patient Gender', 'Invoice ID', 'Invoice Date'];
        rows.push(titles);

        // Filter table data by global filter
        const visitsToExport = $filter('filter')($scope.visitInstances, $scope.globalFilter.val);
        visitsToExport.forEach(dataRow => {
            const csvRow = [];
            csvRow.push("\"" + (dataRow.patient.displayName ?? '') + "\"");
            csvRow.push("\"" + (dataRow.caregiver.displayName ?? '') + "\"");
            csvRow.push("\"" + (dataRow.caregiver.caregiverCode ?? '') + "\"");
            csvRow.push("\"" + (dataRow.officeName || '') + "\"");
            csvRow.push("\"" + (dataRow.contractName || '') + "\"");
            csvRow.push("\"" + ($filter("dateStringToLocaleDate")(dataRow.startTime) || '') + "\"");
            csvRow.push("\"" + $filter("hourMinutesRange")(dataRow, 'startTime', 'endTime') + "\"");
            csvRow.push("\"" + $filter("hourMinutesRange")(dataRow, 'clockinTime', 'clockoutTime') + "\"");
            csvRow.push("\"" + ($filter("centsToDollars")(dataRow.billingTotal) || 0) + "\"");
            csvRow.push("\"" + ($filter("centsToDollars")(dataRow.potentialBillingAmount) || 0) + "\"");
            csvRow.push("\"" + ($filter("centsToDollars")(dataRow.paymentTotal) || 0) + "\"");
            csvRow.push("\"" + (dataRow.timelyFilingLimitDaysLeft || '') + "\"");
            csvRow.push("\"" + (dataRow.patientId || '') + "\"");
            csvRow.push("\"" + (dataRow.patientExternalId || '') + "\"");
            csvRow.push("\"" + (dataRow.patientAdmissionId || '') + "\"");
            csvRow.push("\"" + (dataRow.patientSSN || '') + "\"");
            csvRow.push("\"" + (dataRow.patientMedicareNumber || '') + "\"");
            csvRow.push("\"" + (dataRow.patientMedicaidNumber || '') + "\"");
            csvRow.push(dataRow.patientAddress ? '"' + dataRow.patientAddress.replace('#', '') + '"' : '" "');
            csvRow.push("\"" + (dataRow.patientGender || '') + "\"");
            csvRow.push(dataRow.invoiceDisplayId ? '"' + dataRow.invoiceDisplayId.replace('#', '') + '"' : '');
            csvRow.push("\"" + ($filter("dateStringToLocaleDate")(dataRow.invoiceDate) || '') + "\"");
            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };


    const getExportedFileName = function () {
        const filename = "medflyt-visits-to-export"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }

    initialize();

};