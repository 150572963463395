import { getParameterByName } from "./getParameterByName";

function getApiOverride() {
  const overrideApiUrlParam = getParameterByName("overrideApiUrl");
  const overrideSocketUrlParam = getParameterByName("overrideSocketUrl");

  if (overrideApiUrlParam && overrideSocketUrlParam) {
    localStorage.setItem(
      "apiOverride",
      JSON.stringify({
        overrideApiUrl: overrideApiUrlParam,
        overrideSocketUrl: overrideSocketUrlParam,
      })
    );

    return {
      overrideApiUrl: overrideApiUrlParam,
      overrideSocketUrl: overrideSocketUrlParam,
    };
  }

  const stored = localStorage.getItem("apiOverride");

  if (stored === null) {
    return null;
  }

  try {
    const overrides = JSON.parse(stored);
    if (overrides.overrideApiUrl && overrides.overrideSocketUrl) {
      return {
        overrideApiUrl: overrides.overrideApiUrl,
        overrideSocketUrl: overrides.overrideSocketUrl,
      };
    }

    return null;
  } catch {
    localStorage.removeItem("apiOverride");
    return null;
  }
}

const buildConsts = () => {
  const overrides = getApiOverride();

  return {
    dev: false,
    api: overrides?.overrideApiUrl || import.meta.env.API_URL,
    socketApi: overrides?.overrideSocketUrl || import.meta.env.SOCKET_URL,
    PRICE_PER_SEGMENT: 0.825,
    SEGMENT_AMOUNT: 6,
  };
};

export const Consts = buildConsts();