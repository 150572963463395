import _ from "lodash";

//! @ngInject
export function historyTimeline(DatabaseApi, $rootScope) {
    return {
        restrict: 'E',
        templateUrl: 'admin/views/history-timeline.html',
        scope: {
            tables: '=',
        },
        link: function (scope) {
            const agencyMembers = _.keyBy(DatabaseApi.getAllAgencyMembersArr(), "id");

            scope.agencyMemberIds = [];
            DatabaseApi.get(`agencies/${$rootScope.agencyId}/agency_members_user_ids`).then(res => {
                scope.agencyMemberIds = res.data.list;
            }, () => {
                scope.agencyMemberIds = [];
            })

            scope.getHistoryOfTables = function () {
                scope.tablesHistory = [];
                let allCategoriesSet = new Set();
                for (const table of scope.tables) {
                    DatabaseApi.get(`agencies/${$rootScope.agencyId}/audit/table/${table.tableName}/id/${table.recordId}`).then(res => {
                        const tablesHistory = res.data.list.map(audit => {
                            audit.tableName = table.tableName;
                            const auditCategoriesSet = new Set();
                            audit.changes.forEach(change => {
                                if (!change.category) {
                                    auditCategoriesSet.add('General Details');
                                    change.category = 'General Details';
                                } else {
                                    auditCategoriesSet.add(change.category);
                                }
                            });

                            allCategoriesSet = new Set([...allCategoriesSet, ...auditCategoriesSet])
                            audit.categories = Array.from(auditCategoriesSet);
                            audit.changedColumnsTitles = "'" + audit.changes.map(change => change.title.charAt(0).toUpperCase() + change.title.slice(1)).join("', ' ") + "'";
                            if (audit.updatedByUserType === "AGENCY") {
                                const memberId = scope.agencyMemberIds.find(member => member.userId === audit.updatedBy);
                                if (memberId) {
                                    audit.photoUrl = agencyMembers[memberId.agencyMemberId].photoUrl;
                                }

                            }
                            return audit;
                        });

                        scope.allCategories = [];
                        scope.chosenCategories = [];
                        const allCategoriesLables = Array.from(allCategoriesSet);
                        for (let i = 0; i < allCategoriesLables.length; i++) {
                            scope.allCategories.push({ id: i, label: allCategoriesLables[i] });
                        }

                        scope.tablesHistory = scope.tablesHistory.concat(filteredOnlyDirtyChanges(tablesHistory));

                        // Set as loaded after one table
                        scope.doneLoading = true;
                    }, () => {
                        scope.errorMessage = "Error occurred when fetching history"
                    })
                }
            };

            const getEntityId = (tableName) => {
                return scope.tables.find(table => {
                    return tableName === table.tableName
                }).recordId
            };

            scope.getEntityDisplay = (event, isSubTableEvent, includeParenthesis = true) => {
                const wrapWithParenthesis = (text) => includeParenthesis ? `(${text})` : text;
                if (event.subTableDisplay != null) {
                    return wrapWithParenthesis(event.subTableDisplay);
                }
                if (isSubTableEvent === true) {
                    return event.subTableId ? wrapWithParenthesis(`#${event.subTableId}`) : '';
                }
                const entityId = getEntityId(event.tableName);
                return entityId ? wrapWithParenthesis(`#${entityId}`) : '';
            }

            scope.formatEventToOperation = (event) => {
                const operationName = (() => {
                    const hasUpdatedValue = event.changes[0]?.old ?? null !== null;

                    const aOrAn = ["a", "e", "i", "o"].includes(event.changes[0]?.title?.toLowerCase()[0]) ? "an" : "a";

                    switch (event.actionStr) {
                        case "INSERT":
                            return "has created the";
                        case "UPDATE":
                            return `has ${hasUpdatedValue ? "updated" : "set" } the`;
                        case "DELETE":
                            return "has deleted the";
                        case "SOFT_DELETE":
                            return "has deleted the";
                        case "SUB_INSERT":
                            return "has added a";
                        case "SUB_UPDATE":
                            return `has ${hasUpdatedValue ? "updated" : "set" } ${aOrAn}`;
                        case "SUB_DELETE":
                            return `has deleted a`;
                        case "SUB_SOFT_DELETE":
                            return `has deleted a`;
                        case "ASSOCIATE":
                            return `has associated this ${event.tableName.replace(/_/g, " ")} with ${aOrAn}`;
                        case "DISASSOCIATE":
                            return `has disassociated this ${event.tableName.replace(/_/g, " ")} with ${aOrAn}`;
                        default:
                            return event.actionStr;
                    }
                })();

                scope.formatTableName = ({ tableName, actionStr }) => {
                    if (tableName.match(/_assoc$/) && ["ASSOCIATE", "DISASSOCIATE"].includes(actionStr)) {
                        return "";
                    }
                    
                    return tableName.replace(/_/g, " ");
                }

                const operationEntity = (() => {
                    let tableName;
                    switch (event.actionStr) {
                        case "UPDATE":
                            return "";
                        case "INSERT":
                        case "DELETE":
                        case "SOFT_DELETE":
                            tableName = scope.formatTableName({ tableName: event.tableName, actionStr: event.actionStr });
                            return event.customName || tableName;
                        case "SUB_INSERT":
                        case "SUB_UPDATE":
                        case "ASSOCIATE":
                        case "DISASSOCIATE":
                        case "SUB_SOFT_DELETE":
                            tableName = scope.formatTableName({ tableName: event.subTable, actionStr: event.actionStr });
                            return `${tableName} ${event.subTableDisplay !== null ? `(${event.subTableDisplay})` : ""}`;
                        case "SUB_DELETE":  
                            return scope.formatTableName({ tableName: event.subTable, actionStr: event.actionStr });
                    }
                })();

                return { operationName, operationEntity }
            }

            const filteredOnlyDirtyChanges = (tablesHistory) => {
                return tablesHistory.filter(audit => {
                    let isChanged = false;
                    audit.changes.forEach(change => {
                        if (change.old !== change.new) {
                            isChanged = true;
                            return;
                        }
                    });

                    return isChanged;
                })
            }

            scope.eventFilteredCategories = (event) => {
                const filteredCategories = [];
                event.categories.forEach(category => {
                    if (scope.inCategoriesFilter(category)) {
                        filteredCategories.push(category);
                    }
                })

                return filteredCategories;
            }
            scope.inCategoriesFilter = (field) => {
                return (scope.chosenCategories.length === 0 || scope.allCategories.filter(category => scope.chosenCategories.find(chosen => category.id === chosen.id) !== undefined).find(category => category.label === field) !== undefined)
            }
            
            scope.getCategoryIdx = (categoryName) => {
                const category = scope.allCategories.find(x => x.label === categoryName);

                if (!category) {
                    return -1;
                }

                return category.id;
            }

            scope.hasFilteredChangesInEvent = ({ categories }) => {
                if (scope.chosenCategories.length === 0) {
                    return true;
                }

                return categories.some((category) => {
                  return scope.chosenCategories
                    .map((x) => x.id)
                    .includes(scope.getCategoryIdx(category));
                });
            }

            scope.getUserName = (event) => {
                switch (event.updatedByUserType) {
                    case "CAREGIVER": {
                        return "The caregiver";
                    }
                    case "AGENCY": {
                        return event.updatedByUser;
                    }
                    case "MEDFLYT": {
                        return "Medflyt team";
                    }
                    case "OTHER_AGENCY":
                    default:
                        return "The system";
                }
            }

            scope.toDate = (dateString) => {
                return new Date(dateString);
            }

            scope.categoriesDropdownSettings = {
                styleActive: true,
                displayProp: 'text'
            }
            scope.getHistoryOfTables();
        }
    };
};
