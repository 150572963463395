//! @ngInject
export function globalSearchCtrl($scope, $stateParams, DatabaseApi, $rootScope, $state, $uibModal) {

    $scope.searchText = $stateParams.searchText || '';
    $scope.resultsPreview = $stateParams.resultsPreview || [];
    $scope.displayResults = angular.copy($scope.resultsPreview);
    $scope.results = $stateParams.results || {
        patients: [],
        caregivers: [],
        visits: [],
        invoices: []
    };

    $scope.currentPage = 0;
    $scope.pageSize = 10;
    $scope.numberOfPages = function () {
        if ($scope.selectedCategory === $scope.categories[0]) {
            return Math.ceil($scope.displayResults.length / $scope.pageSize);
        } else {
            return Math.ceil($scope.displayResults.filter(function (r) {
                return r.category === $scope.selectedCategory;
            }).length / $scope.pageSize);
        }
    }

    var getResultFullName = function (result) {
        return result.middleName ?
            result.firstName + ' ' + result.middleName + ' ' + result.lastName :
            result.firstName + ' ' + result.lastName;
    }

    var getResultPhoneNumber = function (result) {
        if (result && result.phoneNumbers && result.phoneNumbers.length > 0) {
            if (result.phoneNumbers.length === 1) {
                return result.phoneNumbers[0];
            }
            return result.phoneNumbers.find(function (phone) {
                return phone.indexOf($scope.searchText) !== -1;
            });
        }
        return '';
    }

    var getResultEmail = function (result) {
        if (result && result.email) {
            return result.email;
        }
        return '';
    }

    var getResultAddress = function (result) {
        if (result && result.address) {
            return result.address;
        }
        return '';
    }

    var mapResultsToResultsPreview = function () {
        $scope.resultsPreview = $scope.results.patients.map(function (p) {
            p.category = 'Patients';
            p.displayValues = [
                getResultFullName(p),
                getResultPhoneNumber(p),
                '',
                getResultAddress(p)
            ];
            return p;
        }).concat($scope.results.caregivers.map(function (c) {
            c.category = 'Caregivers';
            c.displayValues = [
                getResultFullName(c),
                getResultPhoneNumber(c),
                getResultEmail(c),
                getResultAddress(c)
            ];

            return c;
        })).concat($scope.results.visits.map(function (v) {
            v.category = 'Visits';
            v.displayValues = [
                v.id,
                '',
                '',
                ''
            ];

            return v;
        })).concat($scope.results.invoices.map(function (i) {
            i.category = 'Payment claim';
            i.displayValues = [
                i.displayId,
                '',
                '',
                ''
            ];

            return i;
        }));

        $scope.displayResults = angular.copy($scope.resultsPreview);
    }

    if ($stateParams.results) {
        $scope.resutls = $stateParams.results;
        mapResultsToResultsPreview();
    } else {
        $scope.results = {
            patients: [],
            caregivers: [],
            visits: [],
            invoices: []
        };
    }

    if ($scope.searchText === '') {
        $state.go('app.dashboard');
    }

    $scope.categories = [
        'All categories',
        'Patients',
        'Caregivers',
        'Visits',
        'Payment claim'
    ];

    $scope.selectedCategory = 'All categories';

    $scope.selectCategory = function (categoryName) {
        $scope.selectedCategory = categoryName;
        $scope.currentPage = 0;
        $scope.displayResults = $scope.selectedCategory === $scope.categories[0] ?
            angular.copy($scope.resultsPreview) :
            $scope.resultsPreview.filter(function (r) {
                return r.category === $scope.selectedCategory;
            });
    }

    $scope.areThereNoResults = function () {
        ;
        return $scope.displayResults.length === 0;
    }

    var phoneSpacesIndexes = [2, 6, 10];
    var phoneSpacesAmountIndexes = [0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3];

    var pushValueToTextByIndex = function (text, index, value) {
        return text.substring(0, index) + value + text.substring(index, text.length)
    }

    var pushSpacesToPhoneNumber = function (originPhone) {
        if (originPhone.length !== 12) {
            return originPhone;
        }
        var phoneWithSpaces = angular.copy(originPhone);
        phoneSpacesIndexes.forEach(function (i) {
            phoneWithSpaces = pushValueToTextByIndex(phoneWithSpaces, i, '-');
        });
        return phoneWithSpaces;
    }

    $scope.getTextPreResultPart = function (text, isPhone) {
        if (text === undefined) {
            return '';
        }
        text = text.toString();
        if (text.toLowerCase().indexOf($scope.searchText.toLowerCase()) === -1 && !isPhone) {
            return '';
        }

        var resultIndex = text.toLowerCase().indexOf($scope.searchText.toLowerCase());
        if (isPhone) {
            var phoneWithSpaces = pushSpacesToPhoneNumber(text);
            if (resultIndex === -1) {
                return phoneWithSpaces;
            }
            var startIndex = 0;
            var endIndex = resultIndex + phoneSpacesAmountIndexes[resultIndex];
            return phoneWithSpaces.slice(startIndex, endIndex);
        }
        return text.slice(0, resultIndex);
    }

    $scope.getTextPostResultPart = function (text, isPhone) {
        if (text === undefined || text === '') {
            return '';
        }
        text = text.toString();
        if (text.toLowerCase().indexOf($scope.searchText.toLowerCase()) === -1 && (!isPhone)) {
            return '';
        }

        var resultIndex = text.toLowerCase().indexOf($scope.searchText.toLowerCase());
        if (isPhone) {
            if (resultIndex === -1) {
                return '';
            }
            var phoneWithSpaces = pushSpacesToPhoneNumber(text);
            var startIndex = resultIndex + $scope.searchText.length + phoneSpacesAmountIndexes[resultIndex + $scope.searchText.length];
            var endIndex = phoneWithSpaces.length;
            if (isNaN(startIndex)) {
                return '';
            }
            return phoneWithSpaces.slice(startIndex, endIndex);
        }
        return text.slice(resultIndex + $scope.searchText.length, text.length);
    }

    $scope.getTextResultPart = function (text, isPhone) {
        if (text === undefined || text === '') {
            return '';
        }
        text = text.toString();
        var resultIndex = text.toLowerCase().indexOf($scope.searchText.toLowerCase());
        if (resultIndex === -1 && !isPhone) {
            return text;
        }
        if (isPhone) {
            if (resultIndex === -1) {
                return '';
            }
            var phoneWithSpaces = pushSpacesToPhoneNumber(text);
            var startIndex = resultIndex + phoneSpacesAmountIndexes[resultIndex];
            var endIndex = resultIndex + $scope.searchText.length + phoneSpacesAmountIndexes[resultIndex + $scope.searchText.length];
            if (isNaN(endIndex)) {
                return $scope.searchText;
            }
            return phoneWithSpaces.slice(startIndex, endIndex);
        }
        return text.slice(resultIndex, resultIndex + $scope.searchText.length);
    }

    $scope.preResultSpace = function (text) {
        if (text === undefined) {
            return '';
        }
        text = text.toString();
        if (text.toLowerCase().indexOf($scope.searchText.toLowerCase()) === -1) {
            return '';
        }
        var resultIndex = text.toLowerCase().indexOf($scope.searchText.toLowerCase());
        return resultIndex > 0 && text[resultIndex - 1] === ' ';
    }

    $scope.postResultSpace = function (text) {
        if (text === undefined || text === '') {
            return '';
        }
        text = text.toString();
        if (text.toLowerCase().indexOf($scope.searchText.toLowerCase()) === -1) {
            return '';
        }
        var resultIndex = text.toLowerCase().indexOf($scope.searchText.toLowerCase());
        return resultIndex + $scope.searchText.length > 0 && text[resultIndex + $scope.searchText.length] === ' ';
    }

    $scope.doesTextMatchSearchText = function (text) {
        if (text === undefined || text === '') {
            return '';
        }
        text = text.toString();
        return text.toLowerCase() === $scope.searchText.toLowerCase();
    }

    $scope.getIconSrcByCategory = function (category) {
        switch (category) {
            case 'Patients': {
                return 'admin/images/icons/patient.svg';
            }
            case 'Caregivers': {
                return 'admin/images/icons/caregiver.svg';
            }
            case 'Visits': {
                return 'admin/images/icons/visit.svg';
            }
            case 'Payment claim': {
                return 'admin/images/icons/payment-claim.svg';
            }
            default: return '';
        }
    }

    $scope.getResultPerviewId = (resultPreview) => {
        switch (resultPreview.category) {
            case 'Patients':
                return resultPreview.displayId
            case 'Caregivers':
                return resultPreview.displayId
            case 'Visits':
                return resultPreview.id
            default:
                return '';
        }
    }

    $scope.getPermissionKeyByCategory = (category) => {
        switch (category) {
            case 'Patients':
                return 'view_patient_search_result';
            case 'Caregivers':
                return 'view_caregiver_search_result';
            case 'Visits':
            case 'Payment claim':
            default:
                return '';
        }
    }

    $scope.getIconSrcByCategoryWithSelected = function (category) {
        switch (category) {
            case 'Patients': {
                var selected = category === $scope.selectedCategory ? '-selected' : '';
                return 'admin/images/icons/patient' + selected + '.svg';
            }
            case 'Caregivers': {
                var selected = category === $scope.selectedCategory ? '-selected' : '';
                return 'admin/images/icons/caregiver' + selected + '.svg';
            }
            case 'Visits': {
                var selected = category === $scope.selectedCategory ? '-selected' : '';
                return 'admin/images/icons/visit' + selected + '.svg';
            }
            case 'Payment claim': {
                var selected = category === $scope.selectedCategory ? '-selected' : '';
                return 'admin/images/icons/payment-claim' + selected + '.svg';
            }
            default: return '';
        }
    }

    $scope.resultPreviewClick = function (resultPreview, externalLink) {
        switch (resultPreview.category) {
            case 'Patients': {
                $rootScope.openPatientModal(resultPreview.id);
                break;
            }
            case 'Caregivers': {
                $rootScope.openCaregiverModal(resultPreview.id);
                break;
            }
            case 'Visits': {
                $rootScope.openVisitBroadcastModalById(resultPreview.id);
                break;
            }
            case 'Payment claim': {
                var url = 'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/invoices/' + resultPreview.id;
                DatabaseApi.get(url).then(function (res) {
                    $state.go('app.billing.invoice', { id: resultPreview.id, invoice: res.data });
                }, function (err) {

                });
                break;
            }
            default: break;
        }
    }

    $scope.getTotalResutlsAmount = function () {
        return $rootScope.showBillingFeature ?
            $scope.resultsPreview.length :
            $scope.resultsPreview.length - $scope.results.invoices.length;
    }

    $scope.getCategoryResultsAmount = function (category) {
        switch (category) {
            case 'All categories':
                return $scope.getTotalResutlsAmount();
            case 'Patients':
                return $scope.results.patients.length;
            case 'Caregivers':
                return $scope.results.caregivers.length;
            case 'Visits':
                return $scope.results.visits.length;
            case 'Payment claim':
                return $scope.results.invoices.length;
            default: return 0;
        }
    }

    var search = function () {
        var url = 'agencies/' + $rootScope.agencyId + '/search?text=' + $scope.searchText;
        DatabaseApi.get(url).then(function (res) {
            $scope.results = res.data;
            mapResultsToResultsPreview();
        }, function (err) {

        });
    }

    $scope.$on('showBillingFeature_updated', function () {
        if ($rootScope.showBillingFeature) {
            search();
        }
    });

    $scope.previousPage = function () {
        $scope.currentPage--;
    }

    $scope.nextPage = function () {
        $scope.currentPage++;
    }
};

export function startFrom() {
    return function (input, start) {
        start = +start; //parse to int
        return input.slice(start);
    }
};