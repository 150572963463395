import moment from "moment";

//! @ngInject
export function caregiversComplianceOldCtrl(
    $scope,
    $rootScope,
    $filter,
    NgTableParams,
    Consts,
    $http,
    DatabaseApi,
    toaster,
    wildcard,
    $uibModal,
    entityNotesModalService,
    $timeout,
    $window,
    Storage
) {
    let caregiversMap = DatabaseApi.caregivers() || {};
    $scope.complianceGlobalFilter = { val: "" };
    $scope.filterComplianceBy = {
        certification: [],
        status: [{ id: 1 }],
        offices: [],
        expiryDate: { startDate: null, endDate: null },
        issue: [],
        showMissingItems: true,
        compliantStatus: []
    };
    $scope.complianceCache = null;
    $scope.issues = [];
    initTableColumns();
    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map((certificationItem, index) => ({
            id: index,
            label: certificationItem.certification
        }));

    $scope.statuses = [
        { id: 1, label: "Active", value: "ACTIVE", text: "Active", statusClass: "green" },
        { id: 2, label: "On Hold", value: "ON_HOLD", text: "On Hold", statusClass: "yellow" },
        { id: 3, label: "On Leave", value: "ON_LEAVE", text: "On Leave", statusClass: "orange" },
        { id: 4, label: "Pending Application", value: "PENDING", text: "Pending Application", statusClass: "lightblue" },
        { id: 5, label: "Inactive", value: "SUSPENDED", text: "Inactive", statusClass: "azur" },
        { id: 6, label: "Terminated", value: "TERMINATED", text: "Terminated", statusClass: "red" }
    ];

    $scope.compliantStatuses = [
        { id: 1, label: "Compliant" },
        { id: 2, label: "Missing" },
        { id: 3, label: "Not Compliant" }
    ];

    $scope.officesComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '250px',
        enableSearch: true
    };

    $scope.offices = $scope.$resolve.offices
        .filter(office => office.active)
        .map(office => ({ id: office.id, label: office.name }));

    function initTableColumns() {
        let columns = Storage.getObject("caregiversComplianceTableSettings");
        let isCaregiverCodeExists = false;

        if (columns && Object.keys(columns).length) {
            Object.keys(columns).forEach(function (t) {
                if (t === "Caregiver Code") isCaregiverCodeExists = true;
                if (columns[t]) $scope.tableColumnsLength++;
            });

            if (!isCaregiverCodeExists) {
                columns["Caregiver Code"] = true;
            }

            $scope.tableColumns = columns;
        } else {
            $scope.tableColumns = {
                "Caregiver ID": true,
                "Caregiver Code": true,
                "Caregiver Name": true,
                "Photo": true,
                "Languages": true,
                "Certifications": true,
                "Installation": true,
                "Last Seen": true,
                "Address": true,
                "Offices": true,
                "Phone": true,
                "Status": true,
                "Compliant Status": true,
                "Actions": true
            };
        }
        $scope.$watch(
            "tableColumns",
            function () {
                if ($scope.tableColumns) {
                    Storage.setObject("caregiversComplianceTableSettings", $scope.tableColumns);
                    $scope.tableColumnsLength = 0;
                    Object.keys($scope.tableColumns).forEach(function (t) {
                        if ($scope.tableColumns[t]) $scope.tableColumnsLength++;
                    });
                    complianceFilterTable();
                }
            },
            true
        );
    }

    $scope.clickOpendRow = (close) => {
        if (close) {
            angular.element(document.getElementById("active-row")).remove();
            $scope.activeRow = null;
        } else {
            $rootScope.openCaregiverModal($scope.activeRow.id);
        }
    };

    $scope.$watch('filterComplianceBy', function () {
        $scope.clickOpendRow(true);
        complianceFilterTable();
    }, true);

    $scope.applyComplianceGlobalSearch = function (term) {
        const filter = { $: term };
        if ($scope.caregiverIncomplianceTable) {
            angular.extend($scope.caregiverIncomplianceTable.filter(), filter);
        } else {
            console.log('no table');
        }
    };

    function initComplianceTable(data) {
        $scope.allComplianceData = data;
        const options = {
            count: 25,
            sorting: { timeStamp: "desc" }
        };

        $scope.caregiverIncomplianceTable = new NgTableParams(options, {
            counts: [],
            dataset: data
        });

        if ($scope.complianceGlobalFilter.val) {
            $scope.applyComplianceGlobalSearch($scope.complianceGlobalFilter.val);
        }
    }

    const complianceFilterByMethods = {
        hasCertification: function (compliance, selectedCertifications) {
            if (!compliance.certifications) return false;
            return compliance.certifications.some(c => selectedCertifications.includes(c));
        },
        hasStatus: function (compliance, selectedStatuses) { return selectedStatuses.indexOf(compliance.status) !== -1; },
        hasCompliantStatus: function (compliance, selectedCompliantStatuses) { return selectedCompliantStatuses.indexOf(compliance.compliantStatus) !== -1; },
        HasOffices: function (caregiver, offices) { return caregiver.officeIds.find(function (office) { return offices.indexOf(office) !== -1; }) !== undefined; },
        hasExpiryDate: function (compliance, startDate, endDate) {
            return compliance.Incompliances.find((incompliance) => {
                if (!incompliance.expiryDate) return false;
                const expiryDateMoment = moment(incompliance.expiryDate);
                return expiryDateMoment.isBetween(startDate, endDate);
            }) !== undefined;
        },
        hasIssue: function (compliance, selectedIssues) {
            return compliance.Incompliances.find((incompliance) => {
                return selectedIssues.indexOf(incompliance.name) !== -1
            }) !== undefined
        }
    };

    function complianceFilterTable() {
        if (!$scope.complianceCache) return;

        const filters = [];
        const selectedCertifications = $scope.filterComplianceBy.certification.map(function (obj) {
            return $scope.certifications.find(function (cert) { return cert.id === obj.id; }).label;
        });
        if (selectedCertifications.length > 0) {
            filters.push(function (compliance) { return complianceFilterByMethods.hasCertification(compliance, selectedCertifications); });
        }
        const selectedStatuses = $scope.filterComplianceBy.status.map(function (obj) {
            return $scope.statuses.find(function (stat) { return stat.id === obj.id; }).value;
        });
        if (selectedStatuses.length > 0) {
            filters.push(function (compliance) { return complianceFilterByMethods.hasStatus(compliance, selectedStatuses); });
        }
        const selectedCompliantStatuses = $scope.filterComplianceBy.compliantStatus.map(function (obj) {
            return $scope.compliantStatuses.find(function (stat) { return stat.id === obj.id; }).label;
        });
        if (selectedCompliantStatuses.length > 0) {
            filters.push(function (compliance) { return complianceFilterByMethods.hasCompliantStatus(compliance, selectedCompliantStatuses); });
        }
        const selectedOffices = $scope.filterComplianceBy.offices.map((office) => office.id);
        if (selectedOffices.length > 0) {
            filters.push(function (compliance) { return complianceFilterByMethods.HasOffices(compliance, selectedOffices); });
        }
        const selectedExpiryDate = $scope.filterComplianceBy.expiryDate;
        if (selectedExpiryDate.startDate && selectedExpiryDate.endDate) {
            filters.push(function (compliance) { return complianceFilterByMethods.hasExpiryDate(compliance, selectedExpiryDate.startDate, selectedExpiryDate.endDate); });
        }
        const selectedIssues = $scope.filterComplianceBy.issue.map(function (obj) {
            return $scope.issues.find(function (issue) { return issue.id === obj.id; }).label;
        });
        if (selectedIssues.length > 0) {
            filters.push(function (compliance) { return complianceFilterByMethods.hasIssue(compliance, selectedIssues); });
        }
        const selectedShowMissingItems = $scope.filterComplianceBy.showMissingItems;
        if (selectedShowMissingItems === false) {
            filters.push(function (compliance) { return compliance.hasNotMissing === true; });
        }

        let filteredCompliance = $scope.complianceCache;
        if (filters.length > 0) {
            filteredCompliance = filteredCompliance.filter((compliance) => {
                let isFiltered = true;
                for (let idx = 0; isFiltered && idx < filters.length; idx++) {
                    isFiltered = isFiltered && filters[idx](compliance);
                }
                return isFiltered;
            });
        }

        initComplianceTable(filteredCompliance);
    }

    $scope.caregiversIncompliancesGet = function () {
        const url = `agencies/${$rootScope.agencyId}/incompliances`
        DatabaseApi.get(url).then((response) => {
            if (!response.data.caregiversIncompliances || !response.data.caregiversIncompliances.length) {
                return;
            }

            $scope.issues = [];

            const options = {
                count: 25,
                sorting: { timeStamp: "desc" }
            };

            response.data.caregiversIncompliances.forEach((row, i) => {
                response.data.caregiversIncompliances[i] = angular.extend(
                    response.data.caregiversIncompliances[i],
                    caregiversMap[row.caregiver.toString()]
                );

                let compliantStatus = "Compliant";
                let hasNonCompliantItem = false;
                let issuesAmount = 0;
                row.Incompliances = row.Incompliances.map((incompliance) => {
                    if ($scope.issues.indexOf(incompliance.name) === -1) {
                        $scope.issues.push(incompliance.name);
                    }

                    if (incompliance.status !== "Compliant") {
                        issuesAmount++;

                        if (incompliance.status === "Missing") {
                            compliantStatus = "Missing";
                        } else {
                            hasNonCompliantItem = true;
                        }
                    }

                    incompliance.daysToExpire = calculateDayToExpireAsText(incompliance.expiryDate);
                    incompliance.isValidExpiry = incompliance.daysToExpire && incompliance.daysToExpire.indexOf("ago") === -1;

                    incompliance.uploadedBy = $rootScope.getCAgencyMemberName(incompliance.uploadedBy);

                    incompliance.notificationsTable = new NgTableParams(options, {
                        counts: [],
                        dataset: incompliance.notifications
                    });

                    return incompliance;
                });

                row.issuesAmount = issuesAmount;
                if (compliantStatus === "Missing") {
                    row.compliantStatus = 'Missing';
                } else if (hasNonCompliantItem === true) {
                    row.compliantStatus = 'Not Compliant';
                } else {
                    row.compliantStatus = 'Compliant';
                }
                row.strOffices = $scope.showCaregiverOffices(row.officeIds);
                // row.statusClass = $scope.getStatusByValue(caregiversMap[row.caregiver].status).statusClass;
            });

            $scope.issues = $scope.issues.map((issue, index) => {
                return { id: index + 1, label: issue };
            })

            // $scope.onComplianceDateRangeChanged = function (startDate, endDate) {
            //     // send request for incompliances
            //     $scope.caregiversIncompliancesGet(startDate, endDate);
            // }

            $scope.complianceCache = response.data.caregiversIncompliances;
            complianceFilterTable();
        },
            (error) => {
                toaster.pop("error", "Something went wrong", "could not load compliance data");
            }
        );
    };

    $scope.clickComplianceRow = function (row) {
        if ($scope.activeRow && $scope.activeRow.id === row.id) {
            $scope.activeRow = null;
            $scope.activeIssueRow = null;
            return;
        }
        if ($scope.activeRowIssue) {
            $scope.activeRowIssue = null;
        }

        $scope.activeRow = angular.copy(row);
        const options = {
            count: 25,
            sorting: { timeStamp: "desc" }
        };

        $scope.activeRowIssuesTable = new NgTableParams(options, {
            counts: [],
            dataset: row.Incompliances.filter(rowIssue => rowIssue.status !== "Compliant")
        });
    };

    $scope.clickComplianceItemRow = (row, rowIssue) => {
        if ($scope.activeRow && $scope.activeRow.id === row.id && $scope.activeRowIssue && $scope.activeRowIssue.id === rowIssue.id) {
            $scope.activeRowIssue = null;
            return;
        }

        $scope.activeRowIssue = angular.copy(rowIssue);
    }

    $scope.exportComplianceTable = () => {
        const exludedTitles = ["Photo", "Actions"];
        const extraIssueColumns = ["Expiry Date", "Issue", "Missing", "Notifications Sent"];

        const rows = [];
        const titles = [];
        for (const key in $scope.tableColumns) {
            if (exludedTitles.includes(key)) {
                continue;
            }

            if ($scope.tableColumns[key]) {
                titles.push(key);
            }
        }
        titles.push("Total Issues");

        for (const issueCol of extraIssueColumns) {
            titles.push(issueCol);
        }

        rows.push(titles);

        // Filter table data by global filter
        const tableData = $filter('filter')($scope.allComplianceData, $scope.complianceGlobalFilter.val);
        for (const caregiver of tableData) {
            const row = [];
            titles.forEach(function (title) {

                let toPush = "";
                if (title === "Caregiver Name") toPush = `"${caregiver.displayName}"`;
                else if (title === "Caregiver ID") toPush = `"${caregiver.caregiver}"`;
                else if (title === "Caregiver Code") toPush = `"${caregiver.caregiverCode || ""}"`;
                else if (title === "Languages") toPush = `"${caregiver.languages.join(', ')}"`;
                else if (title === "Certifications") toPush = `"${caregiver.certifications.join(', ')}"`;
                else if (title === "Installation") toPush = caregiver.appInstalled ? "Yes" : "No";
                else if (title === "Last Seen") toPush = `"${moment(caregiver.lastSeen)}"`;
                else if (title === "Address") toPush = `"${caregiver.address}"`;
                else if (title === "Offices") toPush = `"${caregiver.strOffices}"`;
                else if (title === "Phone") toPush = `"${caregiver.phoneNumber}"`;
                else if (title === "Status") toPush = `"${$scope.getStatusByValue(caregiver.status).text}"`;
                else if (title === "Compliant Status") toPush = `"${caregiver.compliantStatus}"`;
                else if (title === "Total Issues") toPush = caregiver.Incompliances.length;
                else toPush = undefined;

                if (toPush !== undefined) {
                    row.push(toPush.toString().replace('\n', ' '));
                }
            });

            for (const incompliance of caregiver.Incompliances) {
                rows.push([
                    ...row,
                    incompliance.expiryDate,
                    "\"" + incompliance.name + "\"",
                    incompliance.expiryDate ? "" : incompliance.isMissing ? "Yes" : "No",
                    incompliance.notifications.length
                ]);
            }
        }

        if (rows.length === 0) {
            return;
        }

        let csvContent = "";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const blob = new Blob([csvContent], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "medflyt-incompliant-caregivers-export.csv");

        link.click();
        window.URL.revokeObjectURL(url);
    };

    $scope.openImportComplianceModal = () => {
        const progress = { value: 0 };

        const modal = $uibModal.open({
            templateUrl: "admin/views/drag-file-modal.html",
            controller: "dragFileModal",
            resolve: {
                accept: () => ".xls,.csv,.xlsx",
                title: () => "Import Compliance items from Excel",
                onDragFile: () => file => uploadComplianceTable(file),
                progress: () => progress
            }
        });

        const onSuccessUpload = response => {
            const { totalImported, totalSkipped, tableType } = response.data.result;
            toaster.pop(
                "success",
                `${tableType} Imported`,
                `Imported ${totalImported} items, skipped ${totalSkipped} items.`,
                3000
            );
        };

        const onErrorUpload = response => {
            let message = "Something went wrong...";

            if (response.data.type !== undefined) {
                message = response.data.missing;
            }

            toaster.pop("error", `Import failed`, message, 3000);
        };

        const uploadComplianceTable = file => {
            const formData = new FormData();

            formData.append("file", file, file.name);

            $http({
                url: wildcard(
                    `${Consts.api}hr/agencies/:agencyId/compliance_items/import_table`,
                    $rootScope.agencyId
                ),
                method: "POST",
                data: formData,
                headers: { "Content-Type": undefined },
                uploadEventHandlers: {
                    progress: e => (progress.value = (e.loaded / e.total) * 100)
                }
            })
                .then(onSuccessUpload)
                .catch(onErrorUpload)
                .finally(() => modal.dismiss());
        };
    }

    function loadCaregiversData() {
        $scope.caregiversIncompliancesGet();
    }

    loadCaregiversData();
    $rootScope.$on("got_data", function (event) {
        if (Object.keys(caregiversMap).length > 0) return;
        $scope.gotData = true;
        caregiversMap = DatabaseApi.caregivers() || {};
        loadCaregiversData();
    });
    $rootScope.$on("caregiver_changed", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });
    $rootScope.$on("new_caregiver", function (event) {
        caregiversMap = DatabaseApi.caregivers() || {};
    });

    $scope.sendReminderDummy = () => {
        $timeout(function () {
            toaster.pop("success", "Success!", "Message Sent");
        }, 1500);
    };

    $scope.openDummyModal = (type) => {
        $uibModal.open({
            templateUrl: "admin/views/explain-modal.html",
            size: "md",
            controller: "boostModalCtrl",
            resolve: {
                textAmountToBroadcast: function () {
                    return 0;
                },
                priceOverallCents: function () {
                    return 0;
                },
                type: function () {
                    return type;
                }
            }
        });
    };

    $scope.handleNotesModalOpen = (profileId, profileName) => {
        entityNotesModalService.handleNotesModalOpen({ profileId, profileName });
    }

    $scope.getStatusByValue = (statusValue) => {
        return $scope.statuses.find((stat) => stat.value === statusValue);
    }

    $scope.showCaregiverOffices = (officeIds) => {
        if (!officeIds) return "";
        let presentedOffices;
        const includeInactiveOffices = $scope.filterComplianceBy.offices.find(office => office.id === -1) !== undefined;
        if (includeInactiveOffices) {
            presentedOffices = officeIds
                .map(officeId => offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.name)
        } else {
            presentedOffices = officeIds
                .map(officeId => $scope.offices.find(office => office.id === officeId))
                .filter(office => office !== undefined)
                .map(office => office.label)
        }

        return presentedOffices.join(", ");
    }

    $scope.handleViewIssueFileClick = (row, rowIssue) => {
        const url = `agencies/${$rootScope.agencyId}/caregivers/${row.caregiver}/compliance_instances/${rowIssue.id}/preview`;
        DatabaseApi.get(url).then((res) => {
            $window.open(res.data.fileUrl);
        }, (err) => {
            toaster.pop('error', 'Something went wrong', 'could not get file');
        });
    }

    const calculateDayToExpireAsText = (expiryDate) => {
        const now = moment();
        const expirationDate = moment(expiryDate);
        const diff = expirationDate.diff(now, 'days');
        if (isNaN(diff)) {
            return null;
        }
        if (diff === 0) {
            return `Expires today`;
        }
        if (diff >= 0) {
            return `Expires in ${diff} day${diff > 1 ? `s` : ``}`;
        }
        return `Expired ${diff * -1} day${diff < -1 ? `s ago` : ` ago`}`;
    }
};