import moment from "moment";

//! @ngInject
export function visitInstancePTOModalCtrl(
  $timeout,
  $rootScope,
  $scope,
  $uibModalInstance,
  ptoNotesModalService,
  toaster,
  wildcard,
  DatabaseApi,
  SweetAlert,
  $q,
  $state,
  entityNoteService,
  noteConsts
) {
  $scope.caregiverPtoLabels = DatabaseApi.caregiverPtoLabels();
  $scope.caregiverPTOStatuses = [];
  $scope.caregiverPTOStatusesOptions = [];
  $scope.isNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.CAREGIVER_PTO);

  /*** INITIALIZE ***/
  const initialize = () => {
    $scope.visitInstancesYears = [];
    $scope.visitInstances.forEach(visitInstance => {
      visitInstance.visitDurationMinutes = moment
        .duration(visitInstance.originalEnd.diff(visitInstance.originalStart))
        .asMinutes();
      visitInstance.durationInMinutes = visitInstance.visitDurationMinutes;

      const visitInstanceYear = new Date(visitInstance.startTime).getFullYear();
      if (!$scope.visitInstancesYears.includes(visitInstanceYear)) {
        $scope.visitInstancesYears.push(visitInstanceYear);
      }
    });

    fetchPTODetails();
    fetchPTOStatuses();
    if ($scope.viewModeOnly) {
      initNotesData();
    }
    $scope.totalDurationsInMinutes = 0;
    $scope.totalDurationsPerYearInMinutes = {};

    if (!$scope.viewModeOnly) {
      $scope.ptoEventForm = {
        labelId: undefined,
        note: "",
        durationMinutes: 0,
        durationHours: 0,
      };

      updateTotalDurations();
      $scope.visitInstances.forEach((visitInstance) => {
        visitInstance.durationHours = Math.trunc(visitInstance.visitDurationMinutes / 60);
        visitInstance.durationMinutes = visitInstance.visitDurationMinutes % 60;
      });
    } else {
      // Expecting only 1 visit instance in view mode
      let visitYear;
      $scope.visitInstances = $scope.visitInstances.map((visit) => {
        visitYear = new Date(visit.startTime).getFullYear();
        return {
          ...visit,
          durationHours: Math.trunc(visit.durationInMinutes / 60),
          durationMinutes: visit.durationInMinutes % 60,
        };
      });

      if ($scope.ptoEventForm.status !== "APPROVED") {
        $scope.totalDurationsPerYearInMinutes[visitYear] = $scope.ptoEventForm.duration;
      } else {
        $scope.totalDurationsPerYearInMinutes[visitYear] = 0;
      }
      $scope.ptoEventForm.labelId = $scope.ptoEventForm.label.id.toString();

      $timeout(() => {
        $scope.handleLabelSelected();
      });
    }
  };

  $scope.handleDurationChange = function (visitInstance) {
    visitInstance.durationLongerThanVisit = false;
    let maxDuration = visitInstance.visitDurationMinutes;
    if ($scope.viewModeOnly) {
      maxDuration = $scope.ptoEventForm.pto.originalVisitMinutes;
    }
    visitInstance.durationInMinutes =
      (visitInstance.durationHours ?? 0) * 60 +
      (visitInstance.durationMinutes ?? 0);

    if (visitInstance.durationInMinutes > maxDuration) {
      visitInstance.durationLongerThanVisit = true;
    }

    if (!$scope.editedOrigin && $scope.viewModeOnly) {
      $scope.editedOrigin = true;
      if ($scope.ptoEventForm.status === "APPROVED") {
        const visitInstanceYear = new Date(visitInstance.startTime).getFullYear();
        const selectedStatus = $scope.selectedStatuses.find(status => status.year === visitInstanceYear);
        selectedStatus.currentYearMinutesUsage -=
          $scope.ptoEventForm.duration;
        selectedStatus.totalMinutesUsage -= $scope.ptoEventForm.duration;
      }
    }

    updateTotalDurations();
  };

  const updateTotalDurations = () => {
    $scope.totalDurationsInMinutes = 0;
    $scope.totalDurationsPerYearInMinutes = {};
    $scope.visitInstances.forEach(visitInstance => {
      const year = new Date(visitInstance.startTime).getFullYear();
      if ($scope.totalDurationsPerYearInMinutes[year]) {
        $scope.totalDurationsPerYearInMinutes[year] += visitInstance.durationInMinutes;
      } else {
        $scope.totalDurationsPerYearInMinutes[year] = visitInstance.durationInMinutes;
      }
      $scope.totalDurationsInMinutes += visitInstance.durationInMinutes;
    });
  };

  function noticeAboutOvertimePTOS(years) {
    const deferred = $q.defer();

    SweetAlert.swal({
      title: 'PTO Overtime',
      text: `Are you sure you want to approve patient overtime pto usage for ${years.join(", ")}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3077EB',
      confirmButtonText: 'Approve',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        deferred.resolve(true);
      }
      deferred.resolve(false);
    });
    return deferred.promise;
  }

  $scope.submitPTO = async function () {
    if ($scope.visitPTOForm.$invalid) {
      return;
    }

    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService.validateEntityNote($scope.newPTONote, noteConsts.NoteTypes.CAREGIVER_PTO);
    if (!$scope.ptoEventForm.id && !isNoteValid) {
      $scope.noteValidations = { isPredefinedValid, isMessageValid };
      return;
    }

    const formData = angular.copy($scope.ptoEventForm);
    const visitsData = angular.copy($scope.visitInstances);
    if (visitsData.filter((v) => v.durationLongerThanVisit).length > 0) {
      return;
    }

    const overtimePtoSelectedStatuses = $scope.selectedStatuses.filter(status =>
      status.currentYearMinutesUsage + $scope.totalDurationsPerYearInMinutes[status.year] > status.currentYearMinutesPotential
    );
    if (overtimePtoSelectedStatuses.length > 0) {
      const overtimeYears = overtimePtoSelectedStatuses.map(status => status.year);
      const overTimePtoApproval = await noticeAboutOvertimePTOS(overtimeYears);
      if (!overTimePtoApproval) {
        return;
      }
    }

    if (formData.id) {
      const newData = {
        duration: visitsData[0].durationInMinutes,
        label: Number.parseInt($scope.ptoEventForm.labelId, 10),
      };
      const url = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiver_pto_instances/:caregiverPtoInstanceId",
        $rootScope.agencyId,
        $rootScope.agencyMemberId,
        $scope.caregiverId,
        formData.id
      );
      DatabaseApi.put(url, newData)
        .then(({ data }) => {
          toaster.pop("success", "Successfully edited PTO");
          $scope.closeModal("EDIT_SUCCESS");
        })
        .catch((error) => {
          toaster.pop("error", "Failed to submit PTO form");
        });
    } else {
      let newData = {
        label: Number.parseInt(formData.labelId, 10),
        ptos: visitsData.map((visitInstance) => ({
          duration: visitInstance.durationInMinutes,
          visitInstanceId: visitInstance.id,
        })),
        note: formData.note? formData.note : undefined,
      };

      const noteActors = {
        caregiverId: $scope.caregiverId
      };

      newData.note = entityNoteService.buildEntityNoteRequest(
        $scope.newPTONote,
        noteConsts.NoteTypes.CAREGIVER_PTO,
        noteActors
      );

      if ($scope.createAbsenceRequest) {
        const createAbsenceBody = {
          ...$scope.createAbsenceRequest.body,
          ptosCreateParams: newData
        };
        if ($scope.createAbsenceRequest.noteSetting.agencyId === null) {
          entityNoteService.createAgencyEntityNoteSetting($scope.createAbsenceRequest.noteSetting)
            .then((res) => {
              console.log(res);
              sendCreateAbsenceRequest({
                url: $scope.createAbsenceRequest.url,
                body: createAbsenceBody,
                withPto: true
              });
            }).catch(function (_) {
              toaster.pop("error", "Failed to create absence");
            });
        } else {
          sendCreateAbsenceRequest({
            url: $scope.createAbsenceRequest.url,
            body: createAbsenceBody,
            withPto: true
          });
        }
      } else {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiver_pto_instances"
          .replace(":agencyId", $rootScope.agencyId)
          .replace(":agencyMemberId", $rootScope.agencyMemberId)
          .replace(":caregiverId", $scope.caregiverId);
        DatabaseApi.post(url, newData)
          .then(({ data }) => {
            toaster.pop("success", "Successfully added PTO");
            $scope.closeModal("CREATE_SUCCESS");
          })
          .catch(({ status, data: { error } }) => {
            if (status === 409) {
              toaster.pop("error", "Conflict found", error);
            } else {
              toaster.pop("error", "Failed to submit PTO form");
            }
          });
      }
    }
  };

  $scope.saveAbsenceWithoutPTO = () => {
    sendCreateAbsenceRequest({
      url: $scope.createAbsenceRequest.url,
      body: $scope.createAbsenceRequest.body,
      withPto: false
    });
  };

  $scope.handleLabelSelected = () => {
    if ($scope.ptoEventForm.labelId && Array.isArray($scope.caregiverPTOStatuses) && $scope.caregiverPTOStatuses.length > 0) {
      $scope.totalMinutesUsagePerLabel = {};
      $scope.totalMinutesPotentialPerLabel = {};
      $scope.selectedStatuses = $scope.caregiverPTOStatuses.filter((status) => {
        $scope.totalMinutesUsagePerLabel[status.label] = status.totalMinutesUsage;
        $scope.totalMinutesPotentialPerLabel[status.label] = status.totalMinutesPotential;
        // == for string and number comparison
        return status.label == $scope.ptoEventForm.labelId;
      });
      $scope.selectedLabelText = $scope.selectedStatuses[0].labelText;
      $scope.selectedLabel = $scope.selectedStatuses[0].label;
    }
  };

  const getLabelText = (labelId) => {
    const labelFound = $scope.caregiverPtoLabels.find(
      (label) => label.id === labelId
    );

    if (labelFound === undefined) {
      return "";
    }

    return labelFound.text;
  };

  const fetchPTODetails = () => {
    if (!$scope.ptoEventForm || !$scope.ptoEventForm.id) {
      return;
    }
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregiverPtoInstances/:caregiverPtoInstanceId",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.ptoEventForm.id
    );
    DatabaseApi.get(url)
      .then(({ data }) => {
        $scope.payrollBatchDetails = data.payrollBatchDetails;
      })
      .catch((err) => {
        toaster.pop("error", "Failed to fetch PTO details");
      });
  };

  const fetchPTOStatuses = function () {
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiver_pto_statuses",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.caregiverId
    ) + `?${$scope.visitInstancesYears.map((year, idx) => `years[${idx}]=${year}`).join("&")}`;
    DatabaseApi.get(url)
      .then(({ data }) => {
        $scope.caregiverHireDate = new Date(data.caregiverHireDate);
        $scope.hireDateThisYear =
          new Date().getFullYear() === $scope.caregiverHireDate.getFullYear();

        $scope.caregiverPTOStatuses = [];
        $scope.caregiverPTOStatusesOptions = [];
        const existingLabelsMap = {};
        data.ptoStatuses.forEach(status => {
          const mappedStatus = {
            ...status,
            labelText: getLabelText(status.label),
          };
          if (!existingLabelsMap[status.label] && status.isLabelActive) {
            existingLabelsMap[status.label] = true;
            $scope.caregiverPTOStatusesOptions.push(mappedStatus);
          }
          $scope.caregiverPTOStatuses.push(mappedStatus);
        });
        $scope.handleLabelSelected();
      })
      .catch((error) => {
        toaster.pop("error", "Failed to fetch PTO Labels");
      });
  };

  $scope.removePTO = () => {
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/caregiver_pto_instances/:caregiverPtoInstanceId",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.caregiverId,
      $scope.ptoEventForm.id
    );
    DatabaseApi.delete(url)
      .then(function (res) {
        toaster.pop("success", "Caregiver's PTO Removed");
        $scope.closeModal("REMOVE_SUCCESS");
      })
      .catch(function (err) {
        toaster.pop(
          "error",
          "Something went wrong",
          "could not remove caregiver's PTO"
        );
      });
  };

  $scope.discard = function () {
    $uibModalInstance.close("discard");
  };

  $scope.closeModal = (data = "ok") => {
    $uibModalInstance.close(data);
  };

  function initNotesData() {
    $scope.notesData = {
      profileId: $scope.ptoEventForm.id,
      caregiverId: $scope.caregiverId
    };

    ptoNotesModalService.setNotesData($scope.notesData);
    ptoNotesModalService.setHandlePostNote(() => {});
    ptoNotesModalService.setHandleDeleteNote(() => {});
  }

  $scope.ptoNoteOnReady = function () {
    if (CKEDITOR.instances["note-editor"]) {
      CKEDITOR.instances["note-editor"].destroy();
    }

    const noteEditor = CKEDITOR.replace("note-editor", {
      extraPlugins: "emoji,basicstyles,undo,link,toolbar",
      removePlugins: "elementspath, showborders",
      resize_enabled: false,
      language: "en",
      contentsCss: [
        "/global-vendors/ckeditor4/ckeditor.css",
        "https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700",
      ],
      font_names: "Open Sans",
      allowedContent: true,
      toolbar: [
        {
          name: "document",
          items: ["Undo", "Redo"],
        },
        {
          name: "basicstyles",
          items: ["Bold", "Italic", "Strike"],
        },
        {
          name: "links",
          items: ["EmojiPanel", "Link", "Unlink"],
        },
      ],
      height: 150,
    });

    noteEditor.on("change", function () {
      $scope.ptoEventForm.note = this.getData();
    });

    // When finished - add custom class
    $timeout(() => {
      CKEDITOR.instances["note-editor"].element.$.classList.add(
        "active-note-editor"
      );
    });
  };

  $scope.removePtoInstance = (ptoInstanceId) => {
    $scope.visitInstances = $scope.visitInstances.filter(v => v.id !== ptoInstanceId);
    if ($scope.visitInstances.length === 0) {
      $scope.closeModal("CANCEL");
    }
    updateTotalDurations();
  };

  $scope.goToPayrollBatchPage = (payrollBatchId) => {
    $scope.closeModal("CANCEL");
    $rootScope.closeCaregiverModal();
    $timeout(() => $state.go('app.payroll.batch', { id: payrollBatchId }));
  };

  $scope.handleNoteChanges = (updatedNote) => {
    if (!$scope.newPTONote) {
      $scope.newPTONote = {};
    }

    $scope.newPTONote = updatedNote;
  };

  const sendCreateAbsenceRequest = ({ url, body, withPto }) => {
    DatabaseApi.post(url, body)
      .then(({ data }) => {
        $scope.closeModal(withPto ? "CREATE_WITH_ABSENCE_SUCCESS" : "ABSENCE_CREATED");
      })
      .catch(({ status, data: { error } }) => {
        if (status === 409) {
          toaster.pop("error", "Conflict found", error);
        } else {
          toaster.pop("error", `Failed to submit ${withPto ? "PTO with " : ""}Absence`);
        }
      });
  };

  initialize();
};
