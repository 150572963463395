import { Chart } from "chart.js";

//! @ngInject
export function chartGenerator() {

    var getDefaultPieChartConfig = function (chartTitle) {
        var chartConfig = {
            type: 'pie',
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: chartTitle
                    ? { display: true, text: chartTitle }
                    : { display: false },
                legend: {
                    position: 'bottom',
                    fullWidth: false,
                    labels: {
                        usePointStyle: true
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                animation: {
                    animateRotate: false,
                    animateScale: false,
                    duration: 0
                },
                plugins: {
                    labels: {
                        render: 'percentage',
                        position: 'outside',
                        showActualPercentages: true,
                        textMargin: 8
                    }
                }
            }
        };
        return chartConfig;
    };

    var getDefaultChartColors = function () {
        var chartColors = {
            red: 'rgb(237, 85, 101)',
            green: 'rgb(26, 179, 148)',
            blue: 'rgb(26, 179, 255)',
        };
        return [
            chartColors.green,
            chartColors.red,
            chartColors.blue,
            'rgb(20, 224, 15)',
            'rgb(15, 224, 192)',
            'rgb(222, 224, 15)',
            'rgb(224, 167, 15)',
            'rgb(224, 56, 15)'
        ];
    };

    var generatePieChartConfiguration = function (chartLabels, chartValues, chartColors, chartTitle) {
        var pieChartConfig = getDefaultPieChartConfig(chartTitle);
        var bgColors = (chartColors || []).concat(getDefaultChartColors());
        pieChartConfig.data = {
            labels: chartLabels,
            datasets: [{
                data: chartValues,
                backgroundColor: bgColors
            }]
        }
        return pieChartConfig;
    };

    var renderChartByIdAndConfiguration = function (chartElementId, chartConfig) {
        var chartItem = null;
        if (Chart) {
            var canvas = document.getElementById(chartElementId);
            if (canvas) {
                var ctx = canvas.getContext('2d');
                chartItem = new Chart(ctx, chartConfig);
            }
        }
        return chartItem;
    }



    this.configurePieChart = generatePieChartConfiguration;

    this.renderChart = renderChartByIdAndConfiguration;

};
