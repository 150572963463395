import { Chart } from "chart.js";
import { convert, DateTimeFormatter, LocalDate, LocalDateTime, nativeJs } from "js-joda";
import _ from "lodash";
import moment from "moment";
import phoneUtils from "../utils/phoneUtils";

//! @ngInject
export function patientCtrl(GoogleAddressService, $http, $scope, $rootScope, NgTableParams, ngTableEventsChannel, $state, DatabaseApi, toaster, $filter, Consts, $stateParams, $window, $uibModal, $timeout, $uibModalInstance, SweetAlert, uiCalendarConfig, entityNotesModalService, PatientModalService, officesService, allergyService, agencyBranchService, agencyCustomFieldsService, TimeZoneService, generalUtils, entityNewVisitModalService, wildcard, mfModal, entityNoteService, noteConsts, exportProfileService, $q, patientDocumentService, tasksService, eligibilityChecksService) {
    $scope.patientContractDiagnosisCodes = [];
    var patientId;
    $scope.radioOptions = [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
    ];

    $scope.getEligibilityStatusText = (isEligible) => {
        return eligibilityChecksService.getEligibilityStatusText(isEligible)
    };
    $scope.getEligibilityStatusColor = (isEligible) => {
       return eligibilityChecksService.getEligibilityStatusColor(isEligible)
    };

    $scope.runEligibilityCheck = () => {
        $scope.isLoadingEligibility = true;
        eligibilityChecksService.runEligibilityCheck($scope.patientId).then((eligbilityStatus) => {
            $scope.patient.eligibiltyStatus = eligbilityStatus;
        }).catch((err) =>{
            toaster.pop('error', 'Something went wrong', 'Could run eligibility check')
        }).finally(() => {
            $timeout(() => {
                $scope.isLoadingEligibility = false;
            }, 0);
        });
    }

    $scope.statusChangeReasons = DatabaseApi.statusChangeReasons();
    $scope.statusChangeTos = DatabaseApi.statusChangeTos();
    $scope.planOfCareTypes = DatabaseApi.plansOfCare();
    $scope.electric = { selected: [] };
    $scope.medicalNotes = '';
    $scope.nursingVitalsQuestions = [];
    $scope.sideBarState = undefined;
    $scope.shouldOpenCertificationPeriodModal = true;

    $scope.periodTypesOptions = {
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        ENTIRE_PERIOD: 'Entire period'
    };
    $scope.firstDayOfWeekOptions = {
        SUNDAY: "Sunday",
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
    };

    const documentsMultiSelectFilterEntities = [
        "title",
        "scheduledDate",
        "submittedByName",
        "status",
    ];

    $scope.documentsFilters = Object.fromEntries(
        documentsMultiSelectFilterEntities.map((entity) => [entity, []])
    );

    $scope.documentFiltersOptions = Object.fromEntries(
        documentsMultiSelectFilterEntities.map((entity) => [entity, []])
    );

    $scope.documentDropdownFilterEvents = {
        onSelectionChanged: () => filterDocumentsTable(),
    };

    $scope.evvLocationOptions = [
        { id: 'HOME', label: 'Home' },
        { id: 'COMMUNITY', label: 'Community' }
    ];

    $scope.documentsDateRangesFilter = {
        startDate: null,
        endDate: null
    };

    $scope.vitalUnitNames = {
        /**
         * @deprecated
         */
        KG: "KG",
        /**
         * @deprecated
         */
        CM: "cm",
        /**
         * @deprecated
         */
        CELSIUS: "Celsius",
        FAHRENHEIT: "Fahrenheit",
        mmHg: "mmHg",
        "Breaths Per Minute": "Breaths Per Minute",
        "Beats Per Minute": "Beats Per Minute"
    }

    $scope.patientTaskTemplates = DatabaseApi.patientTaskTemplates();

    $scope.vitalMethodNames = {
        /**
         * @deprecated
         */
        EAR: "In the EAR",
        /**
         * @deprecated
         */
        MOUTH: "Through The Mouth",
        /**
         * @deprecated
         */
        ARM: "Under The Arm",
        "Right Arm": "Right Arm",
        "Left Arm": "Left Arm",
        "Sitting": "Sitting",
        "Laying": "Laying",
        "Standing": "Standing",
        "Regular": "Regular",
        "Irregular": "Irregular",
        "Oral": "Oral",
        "Axillary": "Axillary",
        "Rectal": "Rectal",
        "Remote/Forehead": "Remote/Forehead"
    }

    $scope.editShiftsParams = { type: 'CURRENT_SHIFTS', untilDate: undefined };

    const patientCalendarShowDeletedVisits = localStorage.getItem("patientCalendarShowDeletedVisits");
    if (patientCalendarShowDeletedVisits === null) {
        $scope.showDeletedVisitsOnCalendar = true;
        localStorage.setItem("patientCalendarShowDeletedVisits", true);
    } else {
        $scope.showDeletedVisitsOnCalendar = JSON.parse(patientCalendarShowDeletedVisits);
    }

    const patientCalendarShowAuthorizationDetails = localStorage.getItem("patientCalendarShowAuthorizationDetails");
    if (patientCalendarShowAuthorizationDetails === null) {
        $scope.showAuthOnCalendar = false;
    } else {
        $scope.showAuthOnCalendar = JSON.parse(patientCalendarShowAuthorizationDetails);
    }

    const patientCalendarShowSummaryColumn = localStorage.getItem("patientCalendarShowSummaryColumn");
    if (patientCalendarShowSummaryColumn === null) {
        $scope.showSummaryColumn = true;
    } else {
        $scope.showSummaryColumn = JSON.parse(patientCalendarShowSummaryColumn);
    }

    $scope.patientCurrentOffice = {};

    const mapOffices = (offices) =>
        offices
            .map((office) => ({
                id: office.id,
                label: office.name,
            }));

    if (!officesService.offices) {
        officesService.getOffices().then((offices) => {
            $scope.allOffices = mapOffices(offices);
            $scope.offices = mapOffices(offices.filter((office) => office.active));
        });
    } else {
        $scope.allOffices = mapOffices(officesService.offices);
        $scope.offices = mapOffices(officesService.offices.filter((office) => office.active));
    }

    $scope.patientOfficeEvents = {
        onItemSelect: function ({ id }) {
            $scope.patient.currentOfficeId = id;
            $scope.patchPatient('currentOfficeId');
        },

        onItemDeselect({ id }) {
            // Disallow removing all offices from a caregiver
            if (!$scope.patient.currentOfficeId) {
                SweetAlert.swal({
                    title: "Update offices",
                    text: "Patient must have at least 1 office associated",
                    type: "warning",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK",
                    closeOnConfirm: true,
                });
                $scope.patient.currentOfficeId = id;
                return;
            }
            $scope.patient.currentOfficeId = null;
            $scope.patchPatient('currentOfficeId');
        },
    };

    $scope.getOfficeName = function ({ currentOfficeId }) {
        if (!$scope.allOffices) return;

        const office = $scope.allOffices.find(({ id }) => currentOfficeId === id);
        return office ? office.label : "No office";
    }

    if ($state.current.name === 'app.patient' && (!$scope.$resolve || !$scope.$resolve.forceModal)) {
        $scope.patientId = patientId = $stateParams.id;
        $scope.isModal = false;
        $window.scrollTo(0, 0);
    } else {
        $scope.patientId = patientId = $scope.$resolve.id;
        $scope.isModal = true;
    }

    $scope.patientAgencyTeamEvents = {
        onItemSelect: function ({ id }) {
            if ($scope.patient.agencyTeamId === id) return;

            $scope.patient.agencyTeamId = id;
            $scope.patchPatient('agencyTeamId');
        },
        onDeselectAll: () => {
            $scope.patient.agencyTeamId = null;
            $scope.patchPatient('agencyTeamId');
        }
    };

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };

    $scope.canViewPdf = function (row) {
        return (row.pdfUrl !== null && row.pdfUrl !== "MANUAL_SUBMISSION_STRING");
    }

    $scope.anyDocumentsSelected = function () {
        if ($scope.documentsTable && $scope.documentsTable.data) {
            return $scope.documentsTable.data.some(function (doc) {
                return doc.selected;
            });
        }
        return false;
    }

    $scope.printSelectedDocuments = function () {
        var selectedDocumentIds = $scope.documentsTable.data
            .filter(function (doc) { return doc.selected; })
            .map(function (doc) { return doc.id; });

        var url = Consts.api + 'agencies/' + $rootScope.agencyId + '/' + $rootScope.agencyMemberId + '/patient_document_type/combine_documents';

        var data = {
            documentIds: selectedDocumentIds
        };

        $scope.isLoadingSelectedDocuments = true;

        $http.post(url, data).then(function (response) {
            var pdfUrl = response.data.url;

            if (pdfUrl) {
                window.open(pdfUrl);
            }
        })
            .catch(function (err) { toaster.pop('error', 'Something went wrong', 'Could not fetch PDF URL') })
            .then(function () { $scope.isLoadingSelectedDocuments = false });
    }

    $scope.sendDocumentPDFEmail = (document, type) => {
        $uibModal.open({
            templateUrl: 'admin/views/email-modal.html',
            size: 'lg',
            resolve: {
                documents: () => buildDocuments(document),
                patientId: () => patientId,
                patientFullName: () => `${$scope.patient.firstName} ${$scope.patient.lastName}`,
                docsType: () => type === 'planOfCare' ? 'planOfCare' : 'patient',
                onSuccess: () => () => {
                    document.wasSentViaEmail = true;
                    document.status = getPatientDocumentStatus(document);
                }
            },
            controller: 'emailModalCtrl'
        });
    }

    $scope.sendDocumentPDFEfax = function (document) {
        $uibModal.open({
            templateUrl: 'admin/views/fax-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return buildDocuments(document); },
                patientId: function () { return (patientId); }
            },
            controller: 'faxModalCtrl'
        });
    };

    function buildDocuments(document) {
        var documents;
        if (document !== undefined) {
            documents = [document];
        } else {
            documents = $scope.documentsTable.data.reduce(function (a, c) {
                if (c.selected) {
                    a.push({ id: c.id, title: c.title });
                }
                return a;
            }, []);
        }
        return documents;
    }

    $scope.reopenSubmission = function (document) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patient_documents/:scheduledDocId/openSubmission"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":scheduledDocId", document.id);
        $http.post(Consts.api + url).then(function (res) {
            document.submittedAt = null;
            document.pdfUrl = null;
            document.isOpenForResubmission = true;
            document.status = getPatientDocumentStatus(document);

            // Find how to properly resort.
            //$scope.documentsTable.reload();
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not reopen for submission");
        });
    }

    $scope.submitManually = function (document) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", document.scheduledVisitId);

        $rootScope.openUploadDocumentPdfModal({
            url: url,
            document: document, successCallback: (result) => {
                if (result !== null) {
                    document.submittedAt = LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"));
                    document.pdfUrl = result.data.url;
                    document.status = getPatientDocumentStatus(document);
                }
            }
        });
    }

    $scope.markAsSubmitted = function (document) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_document"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", document.scheduledVisitId);
        DatabaseApi.post(url,
            {
                base64: null,
                documentId: document.documentType,
                documentVersion: document.versionId,
                documentTitle: null
            }
        ).then(function (res) {
            toaster.pop('success', "Manual submission successful", '', ' ');
            document.submittedAt = LocalDate.now().format(DateTimeFormatter.ofPattern("dd/MM/yy"));
            document.pdfUrl = null;
            document.id = res.data.id;

        }, function (err) {
            toaster.pop('error', 'Error', 'Manual submission failed');
        });
    }

    $scope.markAsSignedByPhysician = function (document) {
        $uibModal.open({
            templateUrl: 'admin/views/sign-physician-document-modal.html',
            controller: 'signPhysicianDocumentModalCtrl',
            size: 'sm',
            windowClass: 'center-center',
            resolve: {
                document: () => document,
                onSuccess: () => ({ physicianSignDate }) => {
                    document.physicianSignDate = physicianSignDate;
                    document.status = getPatientDocumentStatus(document);
                }
            },
        });
    }

    const handleConfirmRemoveVisitScheduledDocument = (patientDocumentScheduleId) => {
        patientDocumentService
            .deletePatientVisitDocument($scope.patientId, patientDocumentScheduleId)
            .then(() => {
                const visitIndex = $scope.allPatientDocuments.documents.findIndex(v => v.id === patientDocumentScheduleId);
                $scope.allPatientDocuments.documents.splice(visitIndex, 1);
                filterDocumentsByDateRange();
                toaster.pop("success", "Document successfully removed");
            })
            .catch(() => {
                this.toaster.pop("error", "Something went wrong", "Failed to delete document");
            })
    };

    const handleConfirmRemoveTaskScheduledDocument = (document) => {
        tasksService
                .deletePatientTaskDocument($scope.patientId, document.taskInstanceId, document.documentType)
                .then(() => {
                    const taskIndex = $scope.allPatientDocuments.documents
                        .findIndex(v => v.taskInstanceId === document.taskInstanceId && v.documentType === document.documentType);
                    $scope.allPatientDocuments.documents.splice(taskIndex, 1);
                    filterDocumentsByDateRange();
                    toaster.pop("success", "Document successfully deleted");
                })
                .catch(() => {
                    this.toaster.pop("error", "Something went wrong", "Failed to delete document");
                })
    };

    $scope.onClickRemovePatientScheduledDocument = function (document) {
        if (document.taskInstanceId) {
            const modal = mfModal.create({
                subject: `Delete ${document.title} Document`,
                message: "Are you sure you want to delete this document from the task?",
                variant: "danger",
                confirmLabel: "Confirm",
                cancelLabel: "Cancel",
                onConfirm: () => modal.close() && handleConfirmRemoveTaskScheduledDocument(document)
            });
            return;
        }

        if (document.id === null) {
            const modal = mfModal.create({
                subject: `Delete ${document.title} Document`,
                message: "This visit document hasn't been filled yet, in order to delete pre-filled documents, delete it by editing the visit via the patient calendar.",
                variant: "info",
                confirmLabel: "OK",
                hideCancelButton: true,
                onConfirm: () => modal.close()
            });
            return;
        }

        const modal = mfModal.create({
            subject: `Delete ${document.title} Document`,
            message: "Are you sure you want to remove this document from the visit?",
            variant: "danger",
            confirmLabel: "Confirm",
            cancelLabel: "Cancel",
            onConfirm: () => modal.close() && handleConfirmRemoveVisitScheduledDocument(document.id)
        });
    };

    async function prepareDocumentReplaceConstants(visitInstanceId, taskInstanceId) {
        var data = {};
        data.agencyName = $rootScope.user.agency.name;
        data.caregiverName = "";
        data.patientName = $scope.patient.firstName + " " + $scope.patient.lastName;
        if (visitInstanceId !== undefined) {
            const res = await $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/visit_instances/${visitInstanceId}/caregiver_name`)
            data.caregiverName = res.data;
        }
        if (taskInstanceId !== undefined) {
            const res = await $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_task_instances/${taskInstanceId}/caregiver_name`)
            data.caregiverName = res.data;
        }
        if ((visitInstanceId !== undefined || taskInstanceId !== undefined) && !data.caregiverName) {
            toaster.pop('error', "Didn't find caregiver name in the visits list");
        }
        data.agencyAddress = $rootScope.user.agency.address;
        data.agencyPhone = $filter("americanphone")($rootScope.user.agency.officePhone);

        return data;
    }

    $scope.reviewForm = function (document) {
        let url = "";
        if (document.scheduledVisitId !== undefined) {
            url = Consts.api + "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/patient_documents/:documentTypeId"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":visitInstanceId", document.scheduledVisitId)
                .replace(":documentTypeId", document.documentType);
        }

        if (document.taskInstanceId !== undefined) {
            url = Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patient_task_instances/' + document.taskInstanceId + '/patient_documents/' + document.documentType;
        }

        if (document.scheduledVisitId === undefined && document.taskInstanceId === undefined) {
            url = Consts.api + `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_document_scheduled/:patientDocumentScheduleId`
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":patientId", $scope.patient.id)
                .replace(":patientDocumentScheduleId", document.id)
        }

        $http.get(url).then(function (res) {
            const taskInstanceId = res.data.document !== undefined ? res.data.document.patientTaskInstanceId : undefined;
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/views/edit-patient-doc-modal.html',
                size: 'lg',
                controller: 'editPatientDocModalCtrl',
                resolve: {
                    patientId: $scope.patient.id,
                    document: res.data.document || res.data,
                    documentReplaceConstants: () => prepareDocumentReplaceConstants.bind({}, res.data.scheduledVisitId, taskInstanceId),
                    onSuccessSubmit: () => () => refreshPatientDocuments(),
                }
            });

            modalInstance.result.then(function (data) {
                if (data && data.fileUrl && data.date) {
                    document.submittedAt = data.date;
                    document.pdfUrl = data.fileUrl;
                }
            }, function (e) {
                refreshPatientDocuments();
            });
        }, function (err) {
            toaster.pop('error', "Something went wrong");
        });
    }

    $scope.viewPDF = function (pdfUrl) {
        if (pdfUrl !== null) {
            $window.open(pdfUrl);
        }
    }

    var prosMap = $scope.prosMap = DatabaseApi.caregivers() || {};
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();

    $scope.patient = {};

    $scope.gradients = DatabaseApi.getCssGradient;

    var scrollToOptions = {
        duration: 500,
        easing: 'easeOutQuint',
        offset: 138
    };


    $rootScope.$on('got_data', function (event) {
        if ($scope.availableForms.length === 0) {
            $scope.availableForms = DatabaseApi.patientDocumentTypes();
        }

        const nursingQuestions = DatabaseApi.patientQuestionTypes();
        if (nursingQuestions) {
            $scope.nursingQuestions = DatabaseApi.patientQuestionTypes();
            setVitalNursingQuestions(nursingQuestions);
        }
        initCoordinatorsData();
        initStatusesOptions();
        initBranchesOptions();
        initCustomFields();
    });

    const initBranchesOptions = () => {
        const agencyBranches = DatabaseApi.agencyBranches() || [];
        $scope.agencyBranches = agencyBranches.map(branch => ({
            id: branch.id,
            label: branch.name
        }))
    }

    const initStatusesOptions = () => {
        $scope.agencyPatientStatuses = DatabaseApi.agencyPatientStatuses();
        if ($scope.agencyPatientStatuses.length === 0) {
            return;
        }

        $scope.statuses = $scope.statuses.filter((status) => {
            const foundStatus = $scope.agencyPatientStatuses.find(
                (agencyStatus) => agencyStatus.text === status.value
            );
            return foundStatus !== undefined && foundStatus.active;
        });
    };

    const initCustomFields = () => {
        const agencyCustomFields = DatabaseApi.agencyCustomFields();
        $scope.customFields = angular.copy(agencyCustomFields.filter(customField => customField.entityName === 'PATIENT'));
    }

    $scope.handleCustomFieldRemoved = (customField) => {
        agencyCustomFieldsService
            .removePatientCustomField(
                $rootScope.agencyId,
                $scope.patient.id,
                customField
            )
            .then(() => {
                toaster.pop(
                    "success",
                    "Success",
                    `${customField.fieldName} changed successfully`
                );
            })
            .catch(() => {
                toaster.pop(
                    "error",
                    "Something went wrong",
                    `could not edit ${customField.fieldName}`
                );
            });
    };

    $scope.handleCustomFieldChange = (customField) => {
        agencyCustomFieldsService
            .upsertPatientCustomField(
                $rootScope.agencyId,
                $scope.patient.id,
                customField
            )
            .then(() => {
                toaster.pop(
                    "success",
                    "Success",
                    `${customField.fieldName} changed successfully`
                );
            })
            .catch(() => {
                toaster.pop(
                    "error",
                    "Something went wrong",
                    `could not edit ${customField.fieldName}`
                );
            });
    };

    const initCoordinatorsData = () => {

        $scope.agencyMembersByRoles =
            DatabaseApi.getAgencyMembersByRole() || {};

        const allCoordinatorsOptions = (DatabaseApi.getAgencyMembersArr() || []);
        $scope.coordinatorsOptions = allCoordinatorsOptions.filter(agencyMember => {
            return agencyMember.officeIds.includes($scope.patient.currentOfficeId);
        }).sort(generalUtils.sortByDisplayName);

        $scope.agencyMembersMap = DatabaseApi.getAgencyMembers() || {};


    }

    const initPatientAgencyTeam = (agencyTeamId) => {
        if (agencyTeamId) {
            $scope.patientAgencyTeam = {
                id: agencyTeamId,
                label: $scope.showSelectedId('agencyTeamId', 'agencyTeams', 'name'),
            };
        } else {
            $scope.patientAgencyTeam = {};
        }
    }

    $scope.availableForms = DatabaseApi.patientDocumentTypes();
    $scope.nursingQuestions = DatabaseApi.patientQuestionTypes();
    $scope.nursingVitalsQuestions = angular.copy($scope.nursingQuestions.filter(q => q.isVital === true));

    $scope.handleClickSubsectionMenu = (subsectionIdx) => {
        $scope.isManuallyScrolling = true;

        $timeout(() => $scope.isManuallyScrolling = false, 1000);

        const activeSection = $scope.sections.list[$scope.sections.activeIdx];
        activeSection.activeIdx = subsectionIdx;

        const activeSubsection = activeSection.list[activeSection.activeIdx];

        const id = `patient-main-section-${activeSubsection.id}`;
        generalUtils.scrollToElement(id);
    }

    $scope.sections = {
        activeIdx: 0,
        list: [
            {
                label: "Calendar",
                activeIdx: 0,
                list: [
                    {
                        id: "patient-main-calendar",
                        view: "patient-main-calendar.html",
                        label: "Calendar",
                        permissionKey: "view_patient_page_calendar"
                    },
                    {
                        id: "patient-main-list-view",
                        view: "patient-main-list-view.html",
                        label: "List view",
                        permissionKey: "view_patient_page_calendar"
                    },
                    {
                        id: "patient-main-weekly-template",
                        view: "patient-main-weekly-template.html",
                        label: "Weekly template",
                        permissionKey: "view_patient_page_weekly_template"
                    },
                    {
                        id: "patient-main-caregivers",
                        view: "patient-main-caregivers.html",
                        label: "Caregivers",
                        permissionKey: "view_patient_page_caregivers"
                    }
                ]
            },
            {
                label: "Profile",
                activeIdx: 0,
                list: [
                    {
                        id: "patient-main-summary",
                        view: "patient-main-summary.html",
                        label: "Summary",
                        permissionKey: "view_patient_page_summary"
                    },
                    {
                        id: "patient-main-status-review",
                        view: "patient-main-status-review.html",
                        label: "Status review",
                        permissionKey: "view_patient_page_summary" // TODO view_patient_page_status_review
                    },
                    {
                        id: "patient-main-addresses",
                        view: "patient-main-addresses.html",
                        label: "Addresses",
                        permissionKey: "view_patient_page_summary" // TODO view_patient_page_addresses
                    },
                    {
                        id: "patient-main-emergency-preparedness",
                        view: "patient-main-emergency-preparedness.html",
                        label: "Emergency Contact & Preparedness",
                        permissionKey: "view_patient_page_emergency"
                    },
                    {
                        id: "patient-main-information",
                        view: "patient-main-information.html",
                        label: "Information",
                        permissionKey: "view_patient_page_information"
                    }
                ]
            },
            {
                label: "Medical",
                activeIdx: 0,
                list: [
                    {
                        id: "patient-main-medical-summary",
                        view: "patient-main-medical-summary.html",
                        label: "Summary",
                        permissionKey: "view_patient_page_care_level"
                        // TODO replace when permission created
                        // permissionKey: "view_patient_page_medical_summary"
                    },
                    {
                        id: "patient-main-plan-of-care",
                        view: "patient-main-plan-of-care.html",
                        label: "Plan of Care",
                        permissionKey: "view_patient_page_plan_of_care"
                    },
                    {
                        id: "patient-main-duty-sheet",
                        view: "patient-main-duty-sheet.html",
                        label: "Duty sheet",
                        permissionKey: "view_patient_page_duty_sheet"
                    },
                    {
                        id: "patient-main-medical-information",
                        view: "patient-main-medical-information.html",
                        label: "Medical information",
                        permissionKey: "view_patient_page_medical_info"
                    },
                    {
                        id: "patient-physicians",
                        view: "patient-main-physicians.html",
                        label: "Physicians",
                        permissionKey: "view_patient_physicians"
                    },
                    {
                        id: "patient-main-medication-profile",
                        view: "patient-main-medication-profile.html",
                        label: "Medication profile",
                        permissionKey: "view_patient_page_medication_profile"
                    },
                    {
                        id: "patient-main-tasks",
                        view: "patient-main-tasks.html",
                        label: "Tasks",
                        permissionKey: "view_patient_page_tasks"
                    },
                    {
                        id: "patient-main-documents",
                        view: "patient-main-documents.html",
                        label: "Documents",
                        permissionKey: "view_patient_page_documents"
                    },
                    {
                        id: "patient-main-uploaded-documents",
                        view: "patient-main-uploaded-documents.html",
                        label: "Uploaded documents",
                        permissionKey: "view_patient_page_uploaded_documents"
                    },
                    {
                        id: "patient-main-vbp",
                        view: "patient-main-vbp.html",
                        label: "Value-Based payments",
                        permissionKey: "view_patient_page_vbp"
                    }
                ]
            },
            {
                label: "Administrative",
                activeIdx: 0,
                list: [
                    {
                        id: "patient-main-contracts",
                        view: "patient-main-contracts.html",
                        label: "Contracts",
                        permissionKey: "view_patient_page_contracts"
                    },
                    {
                        id: "patient-main-authorizations",
                        view: "patient-main-authorizations.html",
                        label: "Authorizations",
                        permissionKey: "view_patient_page_authorizations"
                    },
                    {
                        id: "patient-main-diagnosis-codes",
                        view: "patient-main-diagnosis-codes.html",
                        label: "Diagnosis codes",
                        permissionKey: "view_patient_page_diagnosis_codes"
                    },
                    {
                        id: "patient-main-pay-rates",
                        view: "patient-main-pay-rates.html",
                        label: "Pay rates",
                        permissionKey: "view_patient_page_pay_rates"
                    }
                ]
            },
            {
                label: "Recent activity",
                activeIdx: 0,
                list: [
                    {
                        id: "patient-recent-activity",
                        view: "patient-main-recent-activity.html",
                        label: "Recent activity",
                        permissionKey: "view_patient_page_summary"
                    }
                ]
            }
        ]
    }

    $scope.handleClickSectionMenu = (section) => {
        $scope.sections.activeIdx = $scope.sections.list.indexOf(section);
        $scope.sections.list[$scope.sections.activeIdx].activeIdx = 0;
        document.querySelector(".patient-page").scrollTop = 0;
        $timeout(autoTrackSubsection, 3000);
    }

    function autoTrackSubsection() {
        const parent = document.querySelector(".patient-page");

        if (parent === null) {
            return;
        }

        const sections = document.querySelectorAll(".patient-page > *");
        const sectionNames = [...sections].map(x => x.dataset.subsection);
        const initialOffset = parent.offsetTop;

        const sectionFromTo = {};

        sections.forEach(child => {
            sectionFromTo[child.dataset.subsection] = {
                from: child.offsetTop - initialOffset,
                to: child.offsetTop - initialOffset + child.scrollHeight
            }
        })

        parent.onscroll = () => {
            if ($scope.isManuallyScrolling) {
                return;
            }

            for (const [subsection, { from, to }] of Object.entries(sectionFromTo)) {
                if (parent.scrollTop >= from && parent.scrollTop < to) {
                    const currentSection = $scope.sections.list[$scope.sections.activeIdx];
                    const activeIdx = sectionNames.indexOf(subsection);

                    if (currentSection.activeIdx !== activeIdx) {
                        currentSection.activeIdx = activeIdx;
                        $scope.$digest();
                    }
                }
            }
        }
    }

    // I'm not proud of it, but that's the only way I could make it work in angularjs 🤦‍♂️
    [1, 2, 3, 4].forEach((x) => $timeout(autoTrackSubsection, x * 1000));

    $scope.navigationItems = {
        active: 'scroll-summary',
        list: [
            {
                id: 'scroll-summary',
                permissionKey: 'view_patient_page_summary',
                title: 'Summary'
            },
            {
                id: 'scroll-care-level',
                permissionKey: 'view_patient_page_care_level',
                title: 'Care Level'
            },
            {
                id: 'scroll-documents',
                permissionKey: 'view_patient_page_documents',
                title: 'Documents'
            },
            {
                id: 'scroll-uploaded-documents',
                permissionKey: 'view_patient_page_uploaded_documents',
                title: 'Uploaded Documents'
            },
            {
                id: 'scroll-master-week',
                permissionKey: 'view_patient_page_master_week',
                title: 'Master Weeks',
                hidePending: true,
                billingFeature: true
            },
            {
                id: 'scroll-visits',
                permissionKey: 'view_patient_page_visits',
                title: 'Visits'
            },
            {
                id: 'scroll-calendar',
                permissionKey: 'view_patient_page_calendar',
                title: 'Calendar',
                hidePending: true
            },
            {
                id: 'scroll-visits-list-view',
                permissionKey: 'view_patient_page_calendar',
                title: 'Visits List',
                hidePending: true
            },
            {
                id: 'scroll-caregivers',
                permissionKey: 'view_patient_page_caregivers',
                title: 'Caregivers'
            },
            {
                id: 'scroll-plan-of-care',
                permissionKey: 'view_patient_page_plan_of_care',
                title: 'Plan Of Care'
            },
            {
                id: 'scroll-duty-sheet',
                permissionKey: 'view_patient_page_duty_sheet',
                title: 'Duty Sheet'
            },
            {
                id: 'scroll-medical-info',
                permissionKey: 'view_patient_page_medical_info',
                title: 'Medical Information'
            },
            {
                id: 'scroll-medication-profile',
                permissionKey: 'view_patient_page_medication_profile',
                title: 'Medication Profile'
            },
            {
                id: 'scroll-patient-tasks',
                permissionKey: 'view_patient_page_patient_tasks',
                title: 'Tasks'
            },
            {
                id: 'scroll-contracts',
                permissionKey: 'view_patient_page_contracts',
                title: 'Contracts'
            },
            {
                id: 'scroll-authorizations',
                permissionKey: 'view_patient_page_authorizations',
                title: 'Authorizations',
                billingFeature: true
            },
            {
                id: 'scroll-pay-rates',
                permissionKey: 'view_patient_page_pay_rates',
                title: 'Pay Rates',
                hidePending: false,
                billingFeature: true
            },
            {
                id: 'scroll-vbp',
                permissionKey: 'view_patient_page_vbp',
                title: 'Value Based Payment'
            },
            {
                id: 'scroll-information',
                permissionKey: 'view_patient_page_information',
                title: 'Information'
            },
            {
                id: 'scroll-emergency',
                permissionKey: 'view_patient_page_emergency',
                title: 'Emergency Preparedness'
            },
            {
                id: 'recent-activity',
                title: 'Recent activity'
            }
        ]
    };

    $scope.goToItem = function (item) {
        if (!item) {
            return;
        }

        $scope.navigationItems.active = item.id;
        generalUtils.scrollToElement(item.id)
    };

    // get source types
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/referral_source/types').then(function (res) {
        $scope.sourceTypes = res.data.types;
    }, function (err) {
        toaster.pop('error', "Something went wrong", "could not get source types");
        $scope.sourceTypes = [];
    });


    // get contract types
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/contract_types').then(function (res) {
        $scope.contracts = res.data.contractTypes;
    }, function (err) {
        toaster.pop('error', "Something went wrong", "could not get contracts");
    });

    // get serveices codes
    $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/service_codes').then(function (res) {
        $scope.serviceCodes = res.data.serviceCodes;
    }, function (err) {
        toaster.pop('error', "Something went wrong", "could not get service codes");
    });


    // get sources
    function getReferralSources(cb) {
        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/referral_sources').then(function (res) {
            $scope.referralSources = res.data.sources;
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get referral sources");
        });
    }
    getReferralSources();


    // get social workers
    function getSocialWorkers() {
        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/coordinator/' + $rootScope.agencyMemberId + '/social_workers').then(function (res) {
            res.data.workers.forEach(function (w) {
                w.displayName = w.firstName + ' ' + w.lastName;
            })
            $scope.socialWorkers = res.data.workers;

        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get social workers");
        });
    }
    getSocialWorkers();

    var phoneFields = ['homePhoneNumber', 'mobilePhoneNumber', 'phone', 'phoneNumber', 'phone3', 'phone4'];

    $scope.gotToPatients = function () {
        $state.go('app.patients.dashboard');
    };
    function startChart() {
        /*var options = {
            bindto: '#chart',
            data: {
                columns: [
                    ['Plan of Care', 7, 50, 25, 100, 40, 60],
                    ['Time Attendance', 30, 25, 35, 50, 35, 80]
                ],
                type: 'spline'
            }
        };
        c3.generate(options);*/

        //var ctx = angular.element( document.querySelector( '#chart-s' ) ).getContext('2d');
        $timeout(function () {

            var chartColors = {
                red: 'rgb(255, 99, 132)',
                orange: 'rgb(255, 159, 64)',
                yellow: 'rgb(255, 205, 86)',
                green: 'rgb(75, 192, 192)',
                blue: 'rgb(54, 162, 235)',
                purple: 'rgb(153, 102, 255)',
                grey: 'rgb(201, 203, 207)'
            };

            var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            var config = {
                type: 'line',
                data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [{
                        label: 'Plan of Care',
                        backgroundColor: chartColors.green,
                        borderColor: chartColors.green,
                        data: [
                            80, 83, 75, 90
                        ],
                        fill: false,
                    }, {
                        label: 'Time Attendance',
                        fill: false,
                        backgroundColor: chartColors.blue,
                        borderColor: chartColors.blue,
                        data: [
                            75, 85, 90, 85
                        ]
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false
                    },
                    legend: {
                        position: 'bottom'
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Month'
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Value'
                            },
                            ticks: {
                                min: 0,
                                max: 100
                            }
                        }]
                    }
                }
            };

            if (Chart) {
                if (document.getElementById('chart-s')) {
                    var ctx = document.getElementById('chart-s').getContext('2d');
                    var chart = new Chart(ctx, config);
                }
            }
        }, 500);

    }

    $scope.caregiverOnCase = false;
    $scope.nurseOnCase = false;

    $scope.contracts = [];

    $scope.serviceCodes = [

    ];

    $scope.genders = [
        { id: 'M', text: 'Male' },
        { id: 'F', text: 'Female' }
    ];

    initCoordinatorsData();
    $scope.agencyTeams = DatabaseApi.agencyTeams().filter(team => team.active);

    $scope.agencyTeamsExtraSettings = {
        displayProp: 'name',
        showCheckAll: false,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        styleActive: true,
        scrollable: true,
        scrollableHeight: '360px',
        enableSearch: true
    };

    $scope.statuses = [
        { id: 1, value: 'REFERRAL', text: 'REFERRAL', statusClass: "red" },
        { id: 2, value: 'ACCEPTED', text: 'ACCEPTED', statusClass: "green" },
        { id: 3, value: 'ELIGIBLE', text: 'ELIGIBLE', statusClass: "lightblue" },
        { id: 4, value: 'PENDING_FILES', text: 'PENDING FILES', statusClass: "lightblue" },
        { id: 5, value: 'ACTIVE', text: 'ACTIVE', statusClass: "green" },
        { id: 6, value: 'DISCHARGED', text: 'DISCHARGED', statusClass: "red" },
        { id: 7, value: 'ON_HOLD', text: 'ON HOLD', statusClass: "orange" },
        { id: 8, value: "HOSPITALIZED", text: "HOSPITALIZED", statusClass: "red" },
        { id: 9, value: "VACATION", text: "VACATION", statusClass: "pink" },
        { id: 10, value: "DECEASED", text: "DECEASED", statusClass: "red" },
    ];

    const activeAgencyCertifications = DatabaseApi.activeAgencyCertifications() || [];
    $scope.certifications = activeAgencyCertifications
        .map(certificationItem => ({
            id: certificationItem.certification
        }));

    initStatusesOptions();
    initBranchesOptions();
    initCustomFields();

    $scope.priorityCodes = [1, 2, 3, 4, 5];
    $scope.evacuationZones = ['1', '2', '3', '4', '5', 'Not availalable', 'Other'];
    $scope.mobilityStatuses = [
        { key: 'TAL 1: Bedbound', title: 'TAL1 - Bedbound' },
        { key: 'TAL 2: Chair Bound', title: 'TAL2- Chairbound' },
        { key: 'TAL 2: Ambulatory with assist', title: 'TAL2 - Ambulatory with assist' },
        { key: 'TAL 3: Ambulatory Independent', title: 'TAL3 - Ambulatory Independent' },
    ];
    $scope.evacuationLocations = ['Church', 'Family', 'Friend', 'Hospital', 'Nursing Home', 'Shelter', 'Other'];
    $scope.electricEquipmentDependencys = ['IV Pump', 'Bi - PAP', 'TPN', 'Feeding Pump', 'Oxygen', 'Ventilator', 'Wheelchair', 'Cane', 'Walker', 'None'];

    function getHoursAndMinutes(num) {
        var time = {};
        time.hours = num ? Math.floor(num / 60) : 0;
        time.minutes = num ? num - (time.hours * 60) : 0;
        return time;
    }

    var daysToString = $scope.daysToString = {
        mon: 'eligibility_monday',
        tue: 'eligibility_tuesday',
        wed: 'eligibility_wednesday',
        thu: 'eligibility_thursday',
        fri: 'eligibility_friday',
        sat: 'eligibility_saturday',
        sun: 'eligibility_sunday'
    };
    $scope.changeEligible = function (contract, treatment, day) {

        // console.log(day);
        $scope.patient.contracts.forEach(function (cont) {
            if (cont.id !== contract.id) return;
            // console.log(cont);
            cont.treatments.forEach(function (treat) {
                if (treat.id !== treatment.id) return;
                // console.log(treat);
                if (treatment.days[day].hours < 0) treatment.days[day].hours = 0;
                if (treatment.days[day].hours > 24) treatment.days[day].hours = 24;
                if (treatment.days[day].minutes < 0) treatment.days[day].minutes = 0;
                if (treatment.days[day].minutes > 59) treatment.days[day].minutes = 59;
                if (treatment.days[day].hours === 24) treatment.days[day].minutes = 0;

                treat[daysToString[day]] = parseInt(treatment.days[day].hours * 60) + parseInt(treatment.days[day].minutes);
                $scope.patchTreatment(contract, treatment, daysToString[day]);
            })
        });
    }


    $scope.getAgencyMemberName = function (id, jobTitle) {
        var members = $scope.agencyMembersByRoles[jobTitle];
        var name = '';
        if (members) {
            members.forEach(function (member) {
                if (member.id === id) name = member.displayName;
            });
        }
        //// console.log('sdcd');
        // // console.log(jobTitle);
        // // console.log(members);
        // // console.log(name);
        return name;
    };

    $scope.showSelected = function (field, options) {
        var selected = $filter('filter')($scope[options], { value: $scope.patient[field] });
        return ($scope.patient[field] && selected.length) ? selected[0].text : 'empty';
    };

    $scope.showSelectedId = function (field, options, display, id) {
        if (id && $scope.patient[field]) {
            var selected = $filter('filter')($scope[options], { id: $scope.patient[field].id });
        }
        else var selected = $filter('filter')($scope[options], { id: $scope.patient[field] });
        if (!selected) return 'empty';
        return ($scope.patient[field] && selected.length) ? selected[0][display] : 'empty';
    };

    $scope.showIsOnHold = function (contract, fallBackText) {
        var selected = $filter('filter')($scope.radioOptions, { value: contract.isOnHold });
        return (selected.length) ? selected[0].text : fallBackText;
    };

    $scope.showIsPrimary = function (contract, fallBackText) {
        var selected = $filter('filter')($scope.radioOptions, { value: contract.isPrimary });
        return (selected.length) ? selected[0].text : fallBackText;
    };

    $scope.showSelectedWithParent = function (field, parent, options, res) {
        //// console.log('dfvfd');
        // // console.log(field);
        // // console.log(parent);
        // // console.log(options);
        // // console.log(res);
        if (!$scope[parent]) return 'empty';
        var selected = $filter('filter')($scope[parent][options], { id: $scope.patient[field] });
        return ($scope.patient[field] && selected && selected.length) ? selected[0][res] : 'empty';
    };

    $scope.updateAddress = function (address) {
        //updating the address only if it's truthy
        if (address) {
            $scope.setAddress(address);
        }
    };

    $scope.setAddress = async function (address) {
        if (address) {
            try {
                const components = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(address);
                $scope.patient.address = {
                    text: components.formatedAddressDetails.fullAddress,
                    components: components
                };

                const timezoneResponse = await TimeZoneService.getTimzone(components.location);
                if (timezoneResponse) {
                    const timezone = timezoneResponse.timeZoneId;
                    $scope.patient.address.timezone = timezone;
                    $scope.patchPatient('address');
                } else {
                    toaster.pop("cant get timezone from location");
                }
            }
            catch (e) {
                console.log(e)
                toaster.pop('error', 'Invalid address: ' + e.message);
            }


        } else {
            $scope.patient.address = null;
        }
    };

    $scope.setAddressEmergency = function (address, i) {
        if (address && $scope.patient.emergencyContact && $scope.patient.emergencyContact[i]) {
            $scope.patient.emergencyContact[i].address = address.formatted_address;
        } else if ($scope.patient.emergencyContact && $scope.patient.emergencyContact[i]) {
            $scope.patient.emergencyContact[i].address = null;
        }
    };

    $scope.toggleEditEmergencyContacts = () => {
        $scope.showEditEmergencyContacts = !$scope.showEditEmergencyContacts;
        if (!$scope.showEditEmergencyContacts) {
            const errorMessage = validateEmergencyContacts();
            if (errorMessage) {
                $scope.showEditEmergencyContacts = true;
                toaster.pop("error", errorMessage);
            } else {
                $scope.patchPatient("emergencyContact");
            }
        }
    };

    function validateEmergencyContacts() {
        let errorMessage;
        $scope.patient.emergencyContact.forEach((contact, index) => {
            if (!contact.firstName || contact.firstName.trim() === "") {
                errorMessage = errorMessage || `Missing or invalid emergency contact #${index + 1}: first name`;
            }
            if (!contact.lastName || contact.lastName.trim() === "") {
                errorMessage = errorMessage || `Missing or invalid emergency contact #${index + 1}: last name`;
            }
            if (contact.livesWithPatient === undefined) {
                errorMessage = errorMessage || `Missing emergency contact #${index + 1}: lives with patient`;
            }
            const phone1 = contact.emergencyPhoneNumber1;
            const phone2 = contact.emergencyPhoneNumber2;
            if (!phone1 || phone1.trim() === "" || !phoneUtils.isValidNumber(phone1)) {
                errorMessage = errorMessage || `Missing or invalid emergency contact #${index + 1}: phone #1`;
            }
            if (phone2 === undefined || (phone2 !== "" && phone2.trim() !== "" && !phoneUtils.isValidNumber(phone2))) {
                errorMessage = errorMessage || `Invalid emergency contact #${index + 1}: phone #2`
            }
        });
        return errorMessage;
    }

    var saleRepJobTitles = ['SalesRep', 'SeniorSalesRep', 'HeadOfSales'];
    var agencyMembers = DatabaseApi.getAgencyMembersArr();
    var saleReps = [];
    agencyMembers.forEach(function (member) {
        if (saleRepJobTitles.indexOf(member.jobTitle) > -1) saleReps.push({
            name: member.firstName + ' ' + member.lastName,
            id: member.id
        });
    })
    $scope.saleReps = saleReps;

    $scope.patientSelectedAllergies = [];

    if (!allergyService.allergies) {
        allergyService.getAllergies($rootScope.agencyId).then((allergies) => {
            $scope.allergyTypes = allergies.map(item => ({
                id: item.id,
                label: item.name
            }));
        });
    } else {
        $scope.allergyTypes = allergyService.allergies.map(item => ({
            id: item.id,
            label: item.name
        }));
    }

    $scope.selectAllergiesExtraSettings = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '360px',
        enableSearch: true
    };

    function updateAllergies(allergyIds, actionType) {
        const allergyIdsObject = {
            allergyIds: allergyIds,
            type: actionType
        };

        allergyService.updatePatientAllergies($scope.agencyId, $rootScope.agencyMemberId, $scope.patientId, allergyIdsObject).then(function (res) {
            allergyService.getPatientAllergies($scope.agencyId, $rootScope.agencyMemberId, $scope.patientId).then(data => {
                $scope.patient.allergies = data;
                $scope.patientAllergiesLabel = $scope.patient.allergies.map(item => item.name).join(', ');
            })
            toaster.pop('success', "Allergies updated successfully");
        }, function (err) {
            toaster.pop('error', "Could not update allergies");
        });
    }

    $scope.getCountyName = function () {
        if ($scope.patient.address && $scope.patient.address.components) {
            if ($scope.patient.address.components.formatedAddressDetails && $scope.patient.address.components.formatedAddressDetails.county) {
                return $scope.patient.address.components.formatedAddressDetails.county
            } else {
                return $scope.patient.address.components.administrativeAreaLevel2;
            }
        }
    }
    $scope.allergyEvents = {
        onItemSelect: function (item) {
            updateAllergies([item.id], "ASSOCIATE");
        },

        onItemDeselect(item) {
            updateAllergies([item.id], "DISSOCIATE");
        },

        onSelectAll() {
            const allergyIds = $scope.patientSelectedAllergies.map(allergy => allergy.id)
            updateAllergies(allergyIds, "ASSOCIATE");
        },
        onDeselectAll() {
            const allergyIds = $scope.patientSelectedAllergies.map(allergy => allergy.id)
            updateAllergies(allergyIds, "DISSOCIATE");
        }
    }

    function mapPatientPhonenumbers() {
        $scope.patient.phoneNumbers = $scope.patient.phoneNumbers.map(x => {
            if (x.phonenumber) {
                x.phonenumber = x.phonenumber.replace(/\+1/g, "");
            }
            return x;
        });
        $scope.patient.emergencyContact = ($scope.patient.emergencyContact || []).map(contact => {
            if (contact.emergencyPhoneNumber1) {
                contact.emergencyPhoneNumber1 = contact.emergencyPhoneNumber1.replace(/\+1/g, "");
            }
            if (contact.emergencyPhoneNumber2) {
                contact.emergencyPhoneNumber2 = contact.emergencyPhoneNumber2.replace(/\+1/g, "");
            }
            return contact;
        });
    }

    //////////////////////

    function refreshPatientDocuments() {
        const url = "agencies/:agencyId/:agencyMemberId/patient_documents_history/:patientId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", patientId)

        $scope.isLoadingPatientDocuments = true;
        DatabaseApi.get(url)
            .then(({ data }) => {
                $scope.allPatientDocuments = data;
                initDocumentsTable(data, true);
                setDocumentsFilterOptions(data.documents, data.scannedDocuments);
            })
            .finally(() => $scope.isLoadingPatientDocuments = false)
    }

    function setDocumentsFilterOptions(documents, scannedDocuments) {
        const optionsEntries = documentsMultiSelectFilterEntities.map((propertyName) => [
            propertyName,
            new Set([
                ...documents.map(document => document[propertyName]),
                ...scannedDocuments.map(document => document[propertyName])
            ])
        ]);

        $scope.documentFiltersOptions = Object.fromEntries(
            optionsEntries.map(([key, set]) => [key, [...set].map((value) => ({ id: value, label: value }))])
        );
    }

    // get patient
    function getPatient(data) {

        refreshPatientDocuments();

        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_member/' + $rootScope.agencyMemberId + '/patient/' + patientId + '/uploaded_documents').then(function (res) {
            initUploadedDocumentsTable(res.data.documents);
        });

        getPatientVitals();

        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + patientId + '/nursing_questions').then(function (res) {
            initPatientGeneralMedicalInfoTable(res.data.questions);
        });

        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + patientId).then(function (res) {
            done(res);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not get patient");
        });

        function done(res) {
            if (!res.data.contracts.length) {
                $scope.contracts.forEach(function (cont) {
                    if (cont.name === 'General') {
                        var contract = {
                            contractType: cont.id,
                            serviceCodeId: null,
                            isPrimary: null,
                            altPatientId: null,
                            startDate: null,
                            startOfCareDate: null,
                            notes: null,
                            placementId: null,
                            medicaidNumber: null,
                            medicareNumber: null
                        };

                        $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + patientId + '/contracts', contract).then(function (res) {
                            getPatient();
                        }, function (err) {
                            toaster.pop('error', "Something went wrong", "could not add contract");
                        });

                    }
                });
            } else if (!res.data.contracts[0].treatments.length) {

                var treatment = {
                    contract: res.data.contracts[0].id,
                    startOfPeriod: null,
                    endOfPeriod: null,
                    accepted_services: [],
                    experience_required_by_the_caregiver: [],
                    auth_number: null,
                    eligibility_sunday: 0,
                    eligibility_monday: 0,
                    eligibility_tuesday: 0,
                    eligibility_wednesday: 0,
                    eligibility_thursday: 0,
                    eligibility_friday: 0,
                    eligibility_saturday: 0,
                    rn_assessment_done_by_admission_source: null,
                    rn_assessment_required: null,
                    rn_assessment_done: null,
                    next_rn_assessment_required: null
                };

                $http.post(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + patientId + '/contracts/' + res.data.contracts[0].id + '/treatment', treatment).then(function (res) {
                    getPatient();
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "could not add treatment");
                });

            }

            PatientModalService.getPatientVisits($scope.patientId).then(
                visits => {
                    $scope.visitsArray = visits;
                    $scope.visits = visits;
                }
            );

            $scope.patient = res.data;

            // configure caregivers blacklist component
            $scope.blacklistedExtraSettings = {
                displayProp: 'name',
                styleActive: true,
                scrollable: true,
                scrollableHeight: '360px',
                enableSearch: true,
                showCheckAll: false,
                closeOnSelect: true,
                closeOnDeselect: true,
            };
            $scope.blacklistedOptions = Object.values(DatabaseApi.caregivers())
                .map(caregiver => ({ id: caregiver.id, name: caregiver.displayName }));
            $scope.blacklistedEvents = {
                onSelectionChanged: function () {
                    $scope.blacklisted = $scope.blacklisted
                        .filter(el => el.id)
                        .map(el => $scope.blacklistedOptions.find(el2 => el2.id === el.id));
                    $scope.updateCaregiversBlacklist();
                },
            };
            $scope.blacklisted = $scope.patient.blacklisted
                ? $scope.patient.blacklisted.map(id => $scope.blacklistedOptions.find(el2 => el2.id === id))
                : [];

            $scope.removeCaregiverFromBlacklist = (item) => {
                $scope.blacklisted = $scope.blacklisted.filter(el => el.id !== item.id);
                $scope.updateCaregiversBlacklist();
            };

            $scope.updateCaregiversBlacklist = function () {
                const body = {
                    ids: $scope.blacklisted.map(el => el.id),
                };

                DatabaseApi.checkCaregiversBlacklist(
                    $scope.patient.id, body
                ).then(function (res) {
                    Object.assign($scope.patient, {
                        blacklisted: body.ids,
                    });

                    if (res.data.hasFutureVisits === true) {
                        const modal = mfModal.create({
                            subject: "Update caregivers blacklist",
                            message: `
                                The patient has future visits with these caregivers, do you wish to keep / unassign caregivers from these visits?
                            `,
                            cancelLabel: "Keep",
                            confirmLabel: "UnAssign",
                            variant: "danger",
                            onConfirm: () => {
                                $scope.saveCaregiversBlacklist(res.data.toDelete, res.data.toInsert, true);
                                modal.close();
                            },
                            onCancel: () => {
                                $scope.saveCaregiversBlacklist(res.data.toDelete, res.data.toInsert, false);
                            }
                        });
                    } else {
                        $scope.saveCaregiversBlacklist(res.data.toDelete, res.data.toInsert, false);
                    }
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "could not edit field");
                });
            };

            $scope.saveCaregiversBlacklist = function (toDelete, toInsert, unassignVisits) {
                DatabaseApi.saveCaregiversBlacklist(
                    $scope.patient.id, toDelete, toInsert, unassignVisits
                ).then(function (res) {
                    DatabaseApi.connect('onlyVisits');
                    DatabaseApi.connect('onlyCaregivers');
                    $rootScope.$broadcast('patient_profile_updated');
                    $rootScope.$broadcast('visit_changed');
                    toaster.pop('success', "Success", "Patient details updated successfully");
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "could not edit field");
                });
            };

            initCoordinatorsData();

            mapPatientPhonenumbers();

            $scope.patientCurrentOffice = {
                id: res.data.currentOfficeId,
                label: $scope.getOfficeName(res.data),
            };

            initPatientAgencyTeam(res.data.agencyTeamId);

            $scope.patient.branches = $scope.patient.agencyBranchIds.map((branchId) => {
                return $scope.agencyBranches.find(function (branch) {
                    return branch.id === branchId;
                });
            }).filter(branch => branch !== undefined);

            $scope.caregiverOnCase = $scope.patient.caregiverOnCase;
            $scope.nurseOnCase = $scope.patient.nurseOnCase;

            $scope.patient = res.data;

            const status = $scope.statuses.find(s => s.value === $scope.patient.status);
            if (status) {
                $scope.patient.selectedStatus = { id: status.id };
                $scope.patient.statusClass = status.statusClass;
            } else {
                $scope.patient.selectedStatus = {};
            }

            $scope.electric.selected = $scope.patient.electricEquipmentDependency ? $scope.patient.electricEquipmentDependency.split(',').filter(Boolean) : [];
            $scope.medicalNotes = $scope.patient.medicalNotes;
            $scope.patientFullName = `${$scope.patient.firstName} ${$scope.patient.middleName !== null ? $scope.patient.middleName : ''} ${$scope.patient.lastName}`

            getVBPInstances();
            if ($rootScope.showBillingFeature) {
                showBillingFeature();
            }
            getPatientStatusChanges();
            mapPatientVacations();

            // Create key/value object for notes amounts
            // $scope.sectionNotesAmount = {};
            // for (const sectionObj of $scope.patient.generalNotesAmount) {
            //     $scope.sectionNotesAmount[sectionObj.section] = sectionObj.amount
            // }

            startChart();
            if (!$scope.notesData) {
                setNotesData('Patient');
            }
            if (!$scope.patientChats) {
                getPatientChats($scope.patient.id);
            }
            getCaregiverAssociated();
            $scope.patientAllergiesLabel = $scope.patient.allergies.map(item => item.name).join(', ');
            $scope.patientSelectedAllergies = $scope.patient.allergies;

            $scope.patient.photoUrl = $scope.patient.gender === "M" ?
                '../admin/images/patient-men.png' :
                '../admin/images/patient-women.png';
        }
    }

    $scope.getStatusById = function (id) {
        const status = $scope.statuses.find((status) => id === status.id);
        return status && status.text ? status.text : "None";
    }

    $rootScope.$on('PatientUpdated', function (event, data) {
        if (data && data.PatientId == patientId) {
            getPatient();
        }
    });


    /////////////////////////
    //// contract
    /////////////////////////

    $scope.addContract = function () {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-contract-modal.html',
            size: 'md',
            controller: 'patientContractModalCtrl',
            resolve: {
                patient: function () { return $scope.patient; },
                codes: function () { return $scope.serviceCodes; },
                contracts: function () { return $scope.contracts; }
            }

        });
        modalInstance.result.then(function (res) {
            // console.log( 'good' , res);
            getPatient();
        }, function () {
            // console.log('modal close');
        });

    };

    // edit contract
    $scope.patchContract = function (contract, field) {
        if (field === 'startDate' || field === 'endDate' && contract.startDate && contract.endDate) {
            const startDateMoment = moment(contract.startDate);
            const endDateMoment = moment(contract.endDate);
            if (endDateMoment.isBefore(startDateMoment)) {
                toaster.pop("error", "End date can't be before start date");
                return;
            }
        }
        var body = { id: contract.id };
        body[field] = contract[field];
        $http.patch(Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patients/' + $scope.patient.id + '/contracts/' + contract.id, body).then(function (res) {
            toaster.pop('success', "Success", "Contract changed successfully");
        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not edit contract");
        });
    };

    $scope.validateMedicaidNumber = async (data) => {
        let patientState = '';

        if ($scope.patient.address.components.formatedAddressDetails) {
            patientState = $scope.patient.address.components.formatedAddressDetails.state;
        } else {
            $scope.patient.address.components = await GoogleAddressService.getAddressComponentsFromText($scope.patient.address.text);

            if ($scope.patient.address.components) {
                patientState = $scope.patient.address.components.formatedAddressDetails.state;
            } else {
                toaster.pop('error', "Something went wrong", "Address is not valid");
            }
        }

        if (data !== "" && !generalUtils.isMedicaidNumberValid(data, patientState)) {
            const modal = mfModal.create({
                subject: "Oops!",
                message: generalUtils.constructInvalidMedicaidMessage(patientState),
                hideCancelButton: true,
                confirmLabel: "OK",
                onConfirm: () => modal.close()
            });
            return false;
        }
        return true;
    }

    $scope.showSelectedContract = function (contract, field, options) {
        var selected = $filter('filter')($scope[options], { value: contract[field] });
        return (contract[field] && selected.length) ? selected[0].text : 'empty';
    };

    $scope.showSelectedText = function (contract, field, options, fallBackText) {
        var selected = $filter('filter')($scope[options], { id: contract[field] });
        return (contract[field] && selected.length) ? selected[0].text : fallBackText;
    };

    $scope.getServiceCodeName = function (id) {
        var res = '';
        $scope.serviceCodes.forEach(function (t) {
            if (t.id === id) res = t.code;
        });
        return res;
    };

    $scope.getPeriodTypeTableSubLabel = function (row) {
        switch (row.periodType) {
            case "DAILY": return '';
            case "WEEKLY":
                switch (row.firstDayOfWeek) {
                    case "SUNDAY": return '(Sun-Sat)';
                    case "MONDAY": return '(Mon-Sun)';
                    case "TUESDAY": return '(Tue-Mon)';
                    case "WEDNESDAY": return '(Wed-Tue)';
                    case "THURSDAY": return '(Thu-Wed)';
                    case "FRIDAY": return '(Fri-Thu)';
                    case "SATURDAY": return '(Sat-Fri)';
                    default: return '';
                }
            case "MONTHLY": return '';
            case "ENTIRE_PERIOD": '';
            default: return '';
        }
    };

    /////////////////////////
    //// treatment
    /////////////////////////

    $scope.addTreatment = function (contract) {

        var modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-treatment-modal.html',
            size: 'sm',
            controller: 'patientTreatmentModalCtrl',
            resolve: {
                patient: function () { return $scope.patient; },
                contract: function () { return contract; }
            }

        });
        modalInstance.result.then(function (res) {
            // console.log( 'good' , res);
            getPatient();
        }, function () {
            // console.log('modal close');
        });

    };

    // edit treatment
    $scope.patchTreatment = function (contract, treatment, field) {
        var body = { id: treatment.id };
        body[field] = treatment[field];
        $http.patch(Consts.api + 'agencies/' + $rootScope.agencyId + '/patients/' + $scope.patient.id + '/treatments/' + treatment.id, body).then(function (res) {

        }, function (err) {
            toaster.pop('error', "Something went wrong", "could not edit treatment");
        });
    };

    $scope.patchTreatmentExp = function (contract, treatment, item) {
        if (!treatment.experience_required_by_the_caregiver) treatment.experience_required_by_the_caregiver = [];
        if (treatment.experience_required_by_the_caregiver.indexOf(item) === -1) treatment.experience_required_by_the_caregiver.push(item);
        else treatment.experience_required_by_the_caregiver.splice(treatment.experience_required_by_the_caregiver.indexOf(item), 1);
        // console.log(treatment);
        // console.log($scope.patient);
        $scope.patchTreatment(contract, treatment, 'experience_required_by_the_caregiver')
    };

    $scope.showSelectedTreatment = function (treatment, field, options) {
        var selected = $filter('filter')($scope[options], { value: treatment[field] });
        return (treatment[field] && selected.length) ? selected[0].text : 'empty';
    };

    $scope.toChat = function (caregiver) {
        $rootScope.openAgencyChat(caregiver.id);
    };


    /*$scope.newVisit = function(contract, treatment, type) {
        if(!$scope.patient.address) return toaster.pop('warning','Missing address','Add patient\'s address');
        $state.go('app.new-visit',{patient: $scope.patient, contract: contract, treatment: treatment, type: type});
    };
    */

    $scope.newVisit = function () {
        if (!$scope.patient.address) return toaster.pop('warning', 'Missing address', 'Add patient\'s address');
        var contract = false, treatment = false, type = 1;

        if (!$scope.patient.contracts.length) {
            toaster.pop('error', 'Please add contract to the patient.');
            return;
        }

        $scope.patient.contracts.forEach(function (cont, i) {
            if (!contract) {
                contract = cont;
                contract.idx = i;
            } else if (cont.id > contract.id) {
                contract = cont;
                contract.idx = i;
            }
        });

        $scope.patient.contracts[contract.idx].treatments.forEach(function (treat, i) {

            if (!treatment) {
                treatment = treat;
            } else if (treat.id > treatment.id) {
                treatment = treat;
            }

        });

        if (!treatment) return toaster.pop('warning', 'No Treatment', 'Please Add Treatment');

        console.log($scope.patient);
        console.log(contract);
        console.log(treatment);
        $state.go('app.new-visit', { patient: $scope.patient, contract: contract, treatment: treatment, type: type });
    };

    /*

        $scope.initVisitTable = function(treatment) {
            initVisitTable(treatment.visits);
        };
    */

    $scope.checkIfCustomDocumentScanned = function (row) {
        return (row.title && !row.documentType && row.documentScanned);
    }

    $scope.toggleSelectAllDocuments = () => {
        const someSelected = $scope.documentsTable.data.some(x => x.selected === true);
        if (someSelected) {
            $scope.documentsTable.data.forEach(doc => doc.selected = false);
        } else {
            $scope.documentsTable.data.forEach(doc => (doc.id === null || doc.submittedAt === null) ? null : doc.selected = true);
        }
    }

    function getFullName({ firstName, middleName, lastName }) {
        return [firstName, middleName, lastName].filter((namePart) => namePart !== null).join(" ");
    }


    $scope.onClickAddDocument = () => {
        $uibModal.open({
            templateUrl: 'admin/views/patient-main-documents-new-document-modal.html',
            size: 'md',
            windowClass: 'center-center',
            resolve: {
                documentTypes: () => DatabaseApi.patientDocumentTypes(),
                patientId: () => $scope.patient.id,
                patientName: () => getFullName($scope.patient),
                onDocumentCreated: () => () => refreshPatientDocuments()
            },
            controller: 'patientMainDocumentsNewDocumentModalCtrl'
        });
    }

    function filterDocument(document) {
        for (const [entity, values] of Object.entries($scope.documentsFilters)) {
            if (values.length > 0 && !values.map((value) => value.id).includes(document[entity])) {
                return false;
            }
        }

        return true;
    }

    function filterDocumentsTable() {
        const documents = $scope.allPatientDocuments.documents.filter(filterDocument);
        const scannedDocuments = $scope.allPatientDocuments.scannedDocuments.filter(filterDocument);

        initDocumentsTable({
            documents,
            scannedDocuments
        });
    };

    $scope.onDateRangeChanged = (startDate, endDate) => {
        $scope.documentsDateRangesFilter.startDate = startDate;
        $scope.documentsDateRangesFilter.endDate = endDate;
        filterDocumentsByDateRange();
    }

    function filterDocumentsByDateRange() {
        const { startDate, endDate } = $scope.documentsDateRangesFilter;
        if (
            !$scope.allPatientDocuments ||
            $scope.allPatientDocuments.documents === undefined ||
            !Array.isArray($scope.allPatientDocuments.documents) ||
            $scope.allPatientDocuments.scannedDocuments === undefined ||
            !Array.isArray($scope.allPatientDocuments.scannedDocuments)
        ) {
            return;
        }
        const documents = $scope.allPatientDocuments.documents.filter(doc => {
            if (doc.scheduledDate === null) {
                return true;
            }

            const scheduledDate = moment(doc.scheduledDate);
            return scheduledDate.isBetween(startDate, endDate) || doc.scheduledDate === null;
        });
        const scannedDocuments = $scope.allPatientDocuments.scannedDocuments.filter(doc => {
            if (doc.scheduledDate === null) {
                return true;
            }

            const scheduledDate = moment(doc.scheduledDate);
            return scheduledDate.isBetween(startDate, endDate) || doc.scheduledDate === null;
        });
        return initDocumentsTable({
            documents,
            scannedDocuments
        })
    }

    function getPatientDocumentStatus(document) {
        if (document.isOpenForResubmission) {
            return "OPEN_FOR_RESUBMISSION";
        }

        if (document.physicianSignDate) {
            return "SIGNED";
        }

        if (document.wasSentViaEmail || document.wasSentViaFax) {
            return "SENT";
        }

        if (document.submittedAt !== null) {
            return "COMPLETED"
        }

        if (document.answers.length > 0) {
            return "IN_PROGRESS";
        }

        return "MISSING";
    }

    function initDocumentsTable(data, isInitial = false) {
        if (!data || (!data.documents && !data.scannedDocuments)) return;
        data = data.documents.concat(data.scannedDocuments);
        if (isInitial) {
            const sorted = [...data].sort((a, b) => {
                if ((a.scheduledDate ?? null) === null) {
                    return 1;
                }

                if ((b.scheduledDate ?? null) === null) {
                    return -1;
                }

                LocalDate.parse(a.scheduledDate).isBefore(LocalDate.parse(b.scheduledDate)) ? -1 : 1
            });

            // Sets the date range from the first document scheduled date to the last document scheduled date
            $scope.initialDatesDatePicker = {
                from: sorted[0] ? moment(sorted[0].scheduledDate).subtract(1, "days") : null,
                to: _.last(sorted) ? moment(_.last(sorted).scheduledDate).add(1, "days") : null
            }
        }
        data = data.map(function (row, index) {
            if (row.submittedAt !== null) {
                row.submittedAt = moment.utc(row.submittedAt).format('MM/DD/YYYY');
            }
            row.selected = false;
            row.orderId = index;
            row.status = getPatientDocumentStatus(row)
            return row;
        });

        var options = {
            count: 10
        };

        $scope.documentsTable = new NgTableParams(options, {
            getData: function (params) {
                if (!data || data.length === 0) {
                    return [];
                }

                let datesOnly = data.filter(a => a.submittedAt !== null);
                let nullDates = data.filter(a => a.submittedAt === null);
                if (params.orderBy().includes('-submittedAt')) {
                    datesOnly.sort(function (a, b) {
                        return new Date(a.submittedAt) - new Date(b.submittedAt)
                    });
                } else {
                    datesOnly.sort(function (a, b) {
                        return new Date(b.submittedAt) - new Date(a.submittedAt)
                    });
                }
                data = datesOnly.concat(nullDates);

                params.total(data.length);

                let orderedData = data
                    .slice((params.page() - 1) * params.count(), params.page() * params.count())
                    .map(row => ({ ...row, status: getPatientDocumentStatus(row) }));

                orderedData = params.sorting() ?
                    $filter('orderBy')(orderedData, params.orderBy()) : orderedData;
                return orderedData;
            }
        });

        var x = ngTableEventsChannel;
        ngTableEventsChannel.onPagesChanged($scope.scrollToTopOfTable, $scope, $scope.documentsTable);
    }

    function initUploadedDocumentsTable(data) {
        if (!data || !data.length) return;

        data = data.map(function (row, index) {
            row.caregiver = DatabaseApi.getCaregiverById(row.caregiver);
            return row;
        });

        var options = {
            count: 10,
        };

        $scope.uploadedDocumentsTable = new NgTableParams(options, {
            getData: function (params) {
                if (!data || data.length === 0) {
                    return [];
                }

                return data;
            }
        });
    }

    $scope.scrollToTopOfTable = function (list, name) {
        $scope.goToItem($scope.navigationItems.list.find(function (item) { item.id === "scroll-documents" }));
    }

    /////////////////////////
    //// patient
    /////////////////////////

    $scope.newPatientShow = false;
    $scope.showNewPatientForm = function () {
        $scope.newPatientShow = !$scope.newPatientShow;
    }

    $scope.sendMedicationProfilePdfToFax = (document) => {
        if (!document || !document.patientId) {
            return;
        }

        $uibModal.open({
            templateUrl: 'admin/views/fax-modal.html',
            size: 'lg',
            resolve: {
                documents: function () { return buildDocuments(document); },
                patientId: function () { return document.patientId; },
                onSuccess: () => () => {
                    document.wasSentViaFax = true;
                    document.status = getPatientDocumentStatus(document);
                }
            },
            controller: 'faxModalCtrl'
        });
    }

    $scope.formUpdated = function () {
        $scope.errorSocialWorker = '';
        $scope.errorSource = '';
        $scope.errors = '';
        // console.log($scope.addNewPatientForm);
        // console.log($scope.newPatient);

    };

    // edit patient info
    $scope.print = function () {
        // console.log($scope.patient);
    };


    // add family member
    $scope.addMember = function (type) {
        if (!$scope.patient[type]) {
            $scope.patient[type] = [];
        }
        $scope.patient[type].push({
            firstName: null,
            lastName: null,
            relationship: null,
            mobilePhoneNumber: null,
            homePhoneNumber: null
        });
    };

    $scope.addEmergencyContact = () => {
        if ($scope.patient.emergencyContact === undefined || $scope.patient.emergencyContact === null) {
            $scope.patient.emergencyContact = [];
        }
        $scope.patient.emergencyContact.push({
            firstName: "",
            lastName: "",
            relationship: "",
            livesWithPatient: undefined,
            emergencyPhoneNumber1: "",
            emergencyPhoneNumber2: "",
            address: ""
        });
    };

    $scope.removeEmergencyContact = (idx) => {
        $scope.patient.emergencyContact.splice(idx, 1);
    }

    $scope.beforeUpdatePatient = (oldVal) => {
        $scope.udpatePatientOldVal = oldVal;
    }

    $scope.shouldDisableSSNEdit = () => {
        return !$rootScope.user.permissions.includes('show_patient_ssn');
    }

    // edit patient info
    $scope.patchPatient = async function (field, parseTo = undefined) {
        if (!$scope.patient || !$scope.patient.id) {
            $scope.patient = { id: parseInt(patientId) };
        }

        var body = { id: $scope.patient.id };

        var fieldData = $scope.patient[field];

        if (field === 'electricEquipmentDependency') {
            fieldData = $scope.electric.selected;
        }

        if (field === 'ssn' && !generalUtils.isSsnValid(fieldData)) {
            $scope.patient[field] = $scope.udpatePatientOldVal;
            toaster.pop('error', 'invalid SSN');
            return;
        }

        if (field === 'medicaidNumber') {
            if (fieldData === "") {
                fieldData = null;
            }
            else {
                const isUnique = await PatientModalService.checkForDuplicateMedicaidNumber($scope.patient.id, fieldData);
                if (!isUnique) {
                    return;
                };
            }
        }

        switch (parseTo) {
            case "number":
                fieldData = parseFloat(fieldData);
                break;
            case "string":
                fieldData = fieldData.toString();
                break;
        }
        if (field === 'status') {
            body[field] = $scope.statuses.find((status) => $scope.patient.selectedStatus.id === status.id).value;
        } else if (field === "emergencyContact") {
            let invalidNumbers = false;
            fieldData.forEach(x => {
                if (
                    [null, undefined, ""].indexOf(x.emergencyPhoneNumber1) === -1 &&
                    phoneUtils.isValidNumber(x.emergencyPhoneNumber1)
                ) {
                    x.emergencyPhoneNumber1 = phoneUtils.formatE164(x.emergencyPhoneNumber1);
                } else {
                    x.emergencyPhoneNumber1 = "";
                    invalidNumbers = true;
                }
                if ([null, undefined, ""].indexOf(x.emergencyPhoneNumber2) === -1) {
                    if (phoneUtils.isValidNumber(x.emergencyPhoneNumber2)) {
                        x.emergencyPhoneNumber2 = phoneUtils.formatE164(x.emergencyPhoneNumber2);
                    } else {
                        x.emergencyPhoneNumber2 = "";
                        invalidNumbers = true;
                    }
                } else {
                    x.emergencyPhoneNumber2 = "";
                }
            });
            body[field] = fieldData;
            if (invalidNumbers) {
                mapPatientPhonenumbers();
                $scope.showEditEmergencyContacts = true;
                toaster.pop('error', "Invalid phone numbers", "Emergency contacts not updated");
                return;
            }
        } else {
            body[field] = fieldData;
        }

        try {
            if (phoneFields.indexOf(field) > -1 && phoneUtils.isValidNumber(body[field])) {
                body[field] = phoneUtils.formatE164(body[field]);
            }
        }
        catch (err) {
            mapPatientPhonenumbers();
            return;
        }

        DatabaseApi.updatePatient($scope.patient.id, body).then(function (res) {
            if (field === 'address') {
                $rootScope.$broadcast('refresh_visits');
            }
            if (field === 'emergencyContact') {
                mapPatientPhonenumbers();
            }

            $rootScope.$broadcast('patient_profile_updated');

            toaster.pop('success', "Success", "Patient details updated successfully");
        }, function (err) {
            if (field === 'emergencyContact') {
                mapPatientPhonenumbers();
                $scope.showEditEmergencyContacts = true;
                toaster.pop('error', "Something went wrong", "Emergency contacts failed to update");
            } else {
                toaster.pop('error', "Something went wrong", "could not edit field");
            }
        });
    };


    $scope.languages = [
        { "text": "Albanian", "value": "Albanian" },
        { "text": "Arabic", "value": "Arabic" },
        { "text": "Armenian", "value": "Armenian" },
        { "text": "Bengali", "value": "Bengali" },
        { "text": "Bulgarian", "value": "Bulgarian" },
        { "text": "Cantonese", "value": "Cantonese" },
        { "text": "Chinese", "value": "Chinese" },
        { "text": "Creole", "value": "Creole" },
        { "text": "Danish", "value": "Danish" },
        { "text": "Dutch", "value": "Dutch" },
        { "text": "English", "value": "English" },
        { "text": "Estonian", "value": "Estonian" },
        { "text": "Farsi", "value": "Farsi" },
        { "text": "Filipino", "value": "Filipino" },
        { "text": "French", "value": "French" },
        { "text": "Fukkianese", "value": "Fukkianese" },
        { "text": "Fula-Fulani", "value": "Fula-Fulani" },
        { "text": "Fuzhounese", "value": "Fuzhounese" },
        { "text": "Georgian", "value": "Georgian" },
        { "text": "German", "value": "German" },
        { "text": "Ghana", "value": "Ghana" },
        { "text": "Greek", "value": "Greek" },
        { "text": "Hakka", "value": "Hakka" },
        { "text": "Hebrew", "value": "Hebrew" },
        { "text": "Hindi", "value": "Hindi" },
        { "text": "Hungarian", "value": "Hungarian" },
        { "text": "Italian", "value": "Italian" },
        { "text": "Japanese", "value": "Japanese" },
        { "text": "Korean", "value": "Korean" },
        { "text": "Krio", "value": "Krio" },
        { "text": "Kyrgyz", "value": "Kyrgyz" },
        { "text": "Mandarin", "value": "Mandarin" },
        { "text": "Pashto", "value": "Pashto" },
        { "text": "Persian", "value": "Persian" },
        { "text": "Polish", "value": "Polish" },
        { "text": "Portuguese", "value": "Portuguese" },
        { "text": "Punjabi", "value": "Punjabi" },
        { "text": "Romanian", "value": "Romanian" },
        { "text": "Russian", "value": "Russian" },
        { "text": "Shanghaines", "value": "Shanghainese" },
        { "text": "Sign", "value": "Sign" },
        { "text": "Soninke", "value": "Soninke" },
        { "text": "Spanish", "value": "Spanish" },
        { "text": "Swahili", "value": "Swahili" },
        { "text": "Tagalog", "value": "Tagalog" },
        { "text": "Taishanese", "value": "Taishanese" },
        { "text": "Taiwanese", "value": "Taiwanese" },
        { "text": "Turkish", "value": "Turkish" },
        { "text": "Twi", "value": "Twi" },
        { "text": "Ukrainian", "value": "Ukrainian" },
        { "text": "Urdu", "value": "Urdu" },
        { "text": "Uzbe", "value": "Uzbe" },
        { "text": "Vietnmese", "value": "Vietnmese" },
        { "text": "Yiddish", "value": "Yiddish" }
    ];

    $scope.treatmentExp = [
        'ALS',
        'Dementia',
        'Alzheimer',
        'Ambulation',
        'Bathing',
        'Diabetes',
        'Hygiene',
        'Incontinence',
        'Gait',
        'Belt/Mechanical lift',
        'Multiple sclerosis',
        'Palliative',
        'Parkinson',
        'Physical Therapy',
        'Prosthetics',
        'Respite',
        'Seizures',
        'Strokes',
        'Toileting',
        'Transferring',
        'Wheelchair',
        'Bedridden',
        'AIDS',
        'Hospice',
        'Deaf',
        'Blind',
        'Catheter',
        'Special need',
        'pediatric',
        'Colostomy',
        'Hoyer lift',
        'Cooking (Food preparation, meals, feeding, baking)',
        'Companionship (Drop-In, visiting, connect with family)',
        'Housekeeping (Vacuuming, dusting, laundry, tidying, clean out closets, move heavy items)',
        'Medication reminders (medication reminders, appointment scheduling)',
        'Staying active (Exercise, walks, board games, mental stimulation, community engagement, exploring passions)',
        'Transportation (Appointments, errands, events)',
        'Wellness check (reminders, check-ins)',
        'Kosher house'
    ];

    var daysArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    $scope.getDays = function (days) {
        return days.map(function (d) {
            return daysArr[d];
        });
    }

    getPatient();

    const getVBPInstances = () => {
        if (!$scope.nursingQuestions) {
            return;
        }
        $scope.vbpQuestions = $scope.nursingQuestions.filter(item => item.isVBP);
        $scope.vbpTodayWeek();
    }

    const updateDaysDatesMap = () => {
        const mondayCopy = $scope.vbpViewStart.clone();
        const format = 'YYYY-MM-DD';
        $scope.daysDatesMap = {
            Monday: mondayCopy.format(format),
            Tuesday: mondayCopy.add(1, 'days').format(format),
            Wednesday: mondayCopy.add(1, 'days').format(format),
            Thursday: mondayCopy.add(1, 'days').format(format),
            Friday: mondayCopy.add(1, 'days').format(format),
            Saturday: mondayCopy.add(1, 'days').format(format),
            Sunday: mondayCopy.add(1, 'days').format(format)
        }
    }

    $scope.vbpTodayWeek = () => {
        $scope.vbpViewStart = moment().startOf('week');
        $scope.vbpViewEnd = moment().endOf('week');
        if (moment.localeData().weekdays()[0] === 'Sunday') {
            $scope.vbpViewStart.add(1, 'days');
            $scope.vbpViewEnd.add(1, 'days');
        }
        updateDaysDatesMap();
        getVBPReports();
    }

    $scope.vbpNextWeek = () => {
        $scope.vbpViewStart.add(1, 'weeks');
        $scope.vbpViewEnd.add(1, 'weeks');
        updateDaysDatesMap();
        getVBPReports();
    }

    $scope.vbpPreviousWeek = () => {
        $scope.vbpViewStart.subtract(1, 'weeks');
        $scope.vbpViewEnd.subtract(1, 'weeks');
        updateDaysDatesMap();
        getVBPReports();
    }

    const getVBPReports = () => {
        $scope.isVbpTableLoading = true;
        const from = LocalDate.from(nativeJs($scope.vbpViewStart));
        const to = LocalDate.from(nativeJs($scope.vbpViewEnd));
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${$scope.patientId}/vbp_items?from=${from}&to=${to}`;
        DatabaseApi.get(url).then(res => {
            $scope.vbpReports = res.data.reports;
            initVBPReportsTable($scope.vbpReports);
            $scope.isVbpTableLoading = false;
        }, (err) => {
            toaster.pop('error', 'Failed loading VBP reports');
            $scope.isVbpTableLoading = false;
        });
    }

    const getVbpRiskClassByLevel = (riskLevel) => {
        if (riskLevel === 2) {
            return 'badge badge-warning';
        } else if (riskLevel === 3) {
            return 'badge badge-danger';
        }
        return 'badge';
    }

    const initVBPReportsTable = (reports) => {
        if ($scope.vbpReports.length !== 7) {
            toaster.pop('error', 'Error initializing VBP reports data');
            return;
        }

        $scope.vbpReportsQuestionsAndAnswers = $scope.vbpQuestions.map(question => {
            const answers = {};
            reports.forEach(report => {
                const answer = report.answers.find(answer => {
                    return answer.nursingQuestionId === question.id;
                });
                if (answer !== undefined) {
                    answers[report.date] = {
                        answer: answer.answer,
                        notes: answer.notes,
                        riskLevel: answer.riskLevel,
                        riskClass: getVbpRiskClassByLevel(answer.riskLevel)
                    };
                }
            })
            return {
                nursingQuestionId: question.id,
                questionText: question.questionText,
                possibleAnswers: question.possibleAnswers.map(x => x.text),
                answers: answers
            };
        });

        $scope.vbpReportsTable = new NgTableParams({}, {
            dataset: $scope.vbpReportsQuestionsAndAnswers
        });
    }

    $scope.openAddVBPReportModal = () => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-vbp-new-report-modal.html',
            size: 'md',
            controller: 'patientVbpNewReportModalCtrl',
            resolve: {
                patient: () => $scope.patient,
                questions: () => $scope.vbpQuestions
            }
        });
        modalInstance.result.then(res => {
            if (res === 'success') {
                getVBPReports();
            }
        });
    }

    $scope.cantSendDocToaster = function (row) {
        if (row.submittedAt && row.pdfUrl) return;
        row.selected = false;
        let strErr = "document wasn't submitted";
        if (row.submittedAt) {
            strErr += " - marked as submitted manually";
        }
        toaster.pop('error', "Can't send:", strErr);
    }

    $scope.openUploadedDocumentNewTab = function (url) {
        window.open(url).focus();
    }

    //////////////////////////
    ///// calendar
    //////////////////////////

    $scope.vacationEdit = function (event, delta, revertFunc) {
        uiCalendarConfig.calendars[$scope.calendarName].fullCalendar('render');
    }

    $scope.onCaregiverClick = function (eventId, modal) {
        $rootScope.openCaregiverModal($scope.eventsData[eventId].caregiverId);
    }

    $scope.newVisitInstance = function (startDays) {
        $rootScope.openCalendarNewVisitModal({
            patientName: $scope.patient.firstName + " " + $scope.patient.lastName,
            patientId: $scope.patient.id,
            startDays: startDays,
            address: $scope.patient.address,
            coordinator: $rootScope.user.coordinator,
            contracts: $scope.patient.contracts
        }).then(function (result) {

            if (result === 'ok') {
                $scope.$broadcast('refresh_visits');
            }

        }).catch(function (err) {
            if (err !== undefined && err !== "backdrop click") {
                toaster.pop('error', "Error creating visit");
            }
        });
    }

    $scope.handleClickPatientCalendarNote = ({ date }) => {
        $scope.handleOpenCalendarNote(date);
    }

    $scope.handleOpenCalendarNote = (calendarDay) => {
        $scope.handleSideBarStateChange('CALENDAR', calendarDay);
    }

    $scope.openVacationManagementModal = function () {
        $uibModal.open({
            templateUrl: 'admin/views/patient-vacations-modal.html',
            size: 'm',
            controller: 'patientVacationsModalCtrl',
            resolve: {}
        });
    }

    function showBillingSections() {
        $scope.navigationItems.list.forEach(function (navItem) {
            if (navItem.title === 'Calendar' || navItem.title === 'Master Weeks') {
                navItem.hidePending = false;
            }
        });
    }

    function showBillingFeature() {
        showBillingSections();
        getAuthorizations();
    }

    $scope.$on('showBillingFeature_updated', function (event) {
        showBillingFeature();
    });


    $scope.addAuthorization = function (data) {
        if (!$rootScope.isPermittedByKey('edit_patient_page_authorizations')) return;

        var newScope = $scope.$new();
        newScope.patientContracts = $scope.patient.contracts;
        newScope.getAuthorizations = getAuthorizations;
        newScope.periodTypesOptions = $scope.periodTypesOptions;
        newScope.firstDayOfWeekOptions = $scope.firstDayOfWeekOptions;
        newScope.lastAuthorization = $scope.authorizationsTable?.data?.length ? $scope.authorizationsTable.data[0] : undefined;

        $uibModal.open({
            templateUrl: 'admin/views/authorization-setup-modal.html',
            controller: 'authorizationSetupModalCtrl',
            scope: newScope,
            size: 'lg',
            resolve: {
                fields: function () { return DatabaseApi.formFields('Authorization'); },
                data: function () { if (data) return data; return undefined; },
                serviceCodes: function () { return DatabaseApi.activeServiceCodes() },
                patientId: function () { return $scope.patient.id }
            }
        });

    }

    $scope.autoAllocate = function (authorization) {
        SweetAlert.swal({
            title: "Auto Allocate Authorization?",
            text: "This will clear all the visit allocations (that haven't been billed) and recalculate them optimally (using all available Authorizations)",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, Auto Allocate",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                var patientContractId = authorization.patientContractId;
                var serviceCodeId = authorization.serviceCode;

                var url = "agencies/" + $rootScope.agencyId + "/auto_recalculate_visit_authorizations";
                DatabaseApi.post(url, { patientContractId: patientContractId, serviceCodeId: serviceCodeId }).then(function (res) {
                    toaster.pop('success', "Success", 'Auto Recalculated Authorizations');

                    $rootScope.$emit("refresh_visits");
                }, function (err) {
                    var errText = '';
                    if (err.data) errText = err.data.error ? err.data.error : '';

                    toaster.pop('error', "Something went wrong", errText);
                });
            }
        });
    }

    $scope.showAuthorizationUtilization = function (authorization) {
        $uibModal.open({
            templateUrl: 'admin/views/authorization-utilization-modal.html',
            controller: 'authorizationUtilizationModalCtrl',
            scope: $scope.$new(),
            size: 'lg',
            resolve: {
                authorization: authorization
            }
        });
    }

    function getAuthorizations() {
        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/authorizations/' + patientId).then(function (res) {
            $scope.patient.authorizations = res.data.authorizations;
            initAuthorizationsTable($scope.patient.authorizations);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not get authorizations");
        })
    }

    function initAuthorizationsTable(data) {
        if (!data) return;
        var options = {
            count: 3,
            sorting: { startDate: "desc" }
        };

        var contractsIdsMap = $scope.patient.contracts.reduce(function (map, obj) {
            map[obj.id] = {
                contractTypeId: obj.contractTypeId,
                contractTypeName: obj.contractTypeName,
            };
            return map;
        }, {});

        const agencyMemberNameByIdObj = DatabaseApi.getAllAgencyMembersNames();

        for (var authorization in data) {
            data[authorization].contractTypeId = contractsIdsMap[data[authorization].patientContractId].contractTypeId;
            data[authorization].contractName = contractsIdsMap[data[authorization].patientContractId].contractTypeName;
            data[authorization].remainingHours = data[authorization].remainingMinutes;

            data[authorization].updatedByName = agencyMemberNameByIdObj[data[authorization].updatedBy];
            data[authorization].createdByName = agencyMemberNameByIdObj[data[authorization].createdBy];

            if (data[authorization].note) {
                const predefinedAnswerId = data[authorization].note.notePredefinedAnswerId;
                const noteRichText = data[authorization].note.noteRichText;

                if (predefinedAnswerId) {
                    const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType(noteConsts.NoteTypes.PATIENT_AUTHORIZATION);
                    const answer = noteSettings.predefinedAnswers.find(a => a.id === predefinedAnswerId);
                    data[authorization].note.predefinedAnswerText = answer.text;
                }

                data[authorization].note.tableRowNoteRichText = predefinedAnswerId && noteRichText ?
                    entityNoteService.setRichNoteText(noteRichText) : noteRichText;
            }
        }

        $scope.authorizationsTable = new NgTableParams(options, {
            counts: [3, 5, 10, 25, 50, 100],
            dataset: data
        });
    }
    $scope.patient.availableContracts = [];

    function initPatientStatusesTable(data) {
        if (!data || !data.length) {
            $scope.patient.availableContracts = $scope.patient.contracts;
            return;
        }

        const options = {
            count: 10,
            sorting: { id: "desc" }
        };

        data.forEach(function (statusChangeItem) {
            const patientContract = $filter('filter')($scope.patient.contracts, { id: statusChangeItem['patientContractId'] });
            if (patientContract[0] !== undefined) {
                statusChangeItem.patientContractName = patientContract[0].contractTypeName;
            }

            const statusChangeTo = $filter('filter')($scope.statusChangeTos, { id: statusChangeItem['to'] });
            if (statusChangeTo[0] !== undefined) {
                statusChangeItem.statusChangeToText = statusChangeTo[0].text;
            }

            const statusChangeReason = $filter('filter')($scope.statusChangeReasons, { id: statusChangeItem['reason'] });
            if (statusChangeReason[0] !== undefined) {
                statusChangeItem.statusChangeReasonText = statusChangeReason[0].text;
            }

            statusChangeItem.notes = statusChangeItem.notes ? stripHtml(statusChangeItem.notes) : '';
        })

        $scope.patientStatusesTable = new NgTableParams(options, {
            counts: [],
            dataset: data
        });

        $scope.patient.availableContracts = [];
        const notReinstatedContract = $scope.patientStatusesTable.data.reduce((acc, item) => {
            if (item.reinstateDate === null && acc.filter(x => x === item.patientContractId).length === 0) {
                acc.push(item.patientContractId);
            }
            return acc;
        }, $scope.patient.availableContracts)

        $scope.patient.availableContracts = $scope.patient.contracts.filter(contract => {
            let notFound = true;
            for (let filtred of notReinstatedContract) {
                if (filtred === contract.id) {
                    notFound = false;
                }

            }
            return notFound;
        })
    }

    function mapPatientVacations() {
        const todayString = new Date().toISOString().slice(0, 10)
        for (const vaction of $scope.patient.vacations) {
            if (vaction.date === todayString) {
                return $scope.patient.isOnVacationToday = true;
            }
        }
    }

    function getPatientStatusChanges() {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_statuses/patient_status_change"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", patientId);
        DatabaseApi.get(url).then(function (res) {
            $scope.patientStatusesChanges = res.data.list;
            initPatientStatusesTable($scope.patientStatusesChanges);
        }, function (err) {
            toaster.pop('error', "Something went wrong", "Could not get patient status changes list");
        })
    }

    $scope.openChangeStatusModal = function () {
        const newScope = $scope.$new();
        newScope.patientId = patientId;
        newScope.getPatientStatusChanges = getPatientStatusChanges;

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-status-change-modal.html',
            controller: 'patientStatusChangeModalCtrl',
            scope: newScope,
            size: 'lg'
        });

        modalInstance.result.then(() => {
            getPatientStatusChanges();
            getPatient();
        })
    }

    $scope.goToPatient = function (id) {
        $rootScope.openPatientModal(id);
    };

    $scope.removeLinkedPatient = function () {

        SweetAlert.swal({
            title: "Remove?",
            text: "Are you sure you want to remove this link?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                DatabaseApi.delete('agencies/' + $rootScope.agencyId + '/patients/' + patientId + '/linked_patient/' + $scope.patient.linkedPatient.linkedWith).then(function (res) {
                    $scope.patient.linkedPatient = {};
                    toaster.pop('success', "Success", "Linked patient removed successfully");
                }, function (err) {
                    toaster.pop('error', "Something went wrong", "Failed to remove linked patient");
                })
            }
        });
    }

    $scope.openAddLinkedPatientModal = function () {
        var newScope = $scope.$new();
        newScope.patient = $scope.patient;

        $uibModal.open({
            templateUrl: 'admin/views/linked-patient-setup-modal.html',
            controller: 'linkedPatientSetupModalCtrl',
            scope: newScope,
            size: 'md'
        });
    }
    $scope.patientContractNameById = function (id) {
        if ($scope.patient.contracts) {
            if (id === "default") {
                if ($scope.patient.contracts[0] !== undefined) {
                    return $scope.patient.contracts[0].contractTypeName;
                }
            }
            const contractsFiltred = $scope.patient.contracts.filter(x => x.id === id);
            if (contractsFiltred.length > 0) {
                return contractsFiltred[0].contractTypeName;
            }
        }
    }

    function initPatientVitalsTable(items) {
        items.forEach(function (item) {
            let d;
            if (item.vitalMeasuredAt !== null) {
                d = LocalDateTime.parse(item.vitalMeasuredAt);
                item.measuredAt = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
            }
            if (item.answerType === "bloodPressure") {
                item.answer = item.answer.join(" / ");
            }

            item.vitalUnit = $scope.vitalUnitNames[item.vitalUnit];
            item.vitalMethod = $scope.vitalMethodNames[item.vitalMethod];
        });

        const options = {
            count: 10
        };
        $scope.patientVitalsTable = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function initPatientGeneralMedicalInfoTable(items) {

        items = items.map(item => {
            const sortedAnswers = item.answers.sort((a, b) => {
                const first = a.updatedAt ? a.updatedAt : a.createdAt;
                const second = b.updatedAt ? b.updatedAt : b.createdAt;
                return first > second;
            });
            item.mostRecentAnswer = sortedAnswers.length > 0 ? sortedAnswers[0] : "";
            item.mostRecentAnswerText = item.mostRecentAnswer.answerText;
            if (typeof (item.mostRecentAnswerText) === 'object' && item.mostRecentAnswerText.length) {
                item.mostRecentAnswerText = item.mostRecentAnswerText.join(', ');
            }
            return item;
        })

        const options = {
            count: 10
        };
        $scope.patientGeneralMedicalInfoTable = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    function getPatientVitals() {
        $http.get(Consts.api + 'agencies/' + $rootScope.agencyId + '/agency_members/' + $rootScope.agencyMemberId + '/patient/' + patientId + '/vital_questions').then(function (res) {
            initPatientVitalsTable(res.data.patientVitals);
        });
    }

    $scope.addNewMeasurement = function () {
        const newScope = $scope.$new();
        newScope.vitalQuestions = $scope.nursingVitalsQuestions;
        newScope.patientId = $scope.patient.id;
        newScope.getPatientVitals = getPatientVitals;

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/patient-measurement-modal.html',
            controller: 'patientMeasurementModalCtrl',
            scope: newScope
        });
        modalInstance.result.then(function (res) {
            // getPatientVitals();
        }, function () {
        });
    }

    function setVitalNursingQuestions(nursingQuestions) {
        $scope.nursingVitalsQuestions = angular.copy(nursingQuestions.filter(q => q.isVital === true));
    }

    $scope.onVitalQuestionsExcelRangeChange = (startDate, endDate) => {
        $scope.vitalQuestionsExcelStartDate = new Date(startDate);
        $scope.vitalQuestionsExcelEndDate = new Date(endDate);
    }

    $scope.onExportVitalQuestionClick = () => {
        const startDate = $scope.vitalQuestionsExcelStartDate.toJSON().slice(0, 10);
        const endDate = $scope.vitalQuestionsExcelEndDate.toJSON().slice(0, 10);
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${patientId}/vital_questions_excel?from=${startDate}&to=${endDate}`;
        DatabaseApi.get(url).then((res) => {
            const vitals = res.data.patientVitals;
            if (vitals.length === 0) {
                toaster.pop('warning', 'No data found for selected date range');
            } else {
                $scope.exportVitalsToExcel(vitals);
            }
        }).catch((err) => {
            toaster.pop('error', 'Failed loading data to export');
        });
    }

    function setNotesData(notesType, calendarDay = undefined) {
        $scope.notesData = {
            profileId: $scope.patient.id,
            notesType: notesType,
            calendarDay: calendarDay
        };

        entityNotesModalService.setNotesData($scope.notesData);
    }

    function setNewVisitData() {
        const newVisitData = {
            patientId: $scope.patient.id,
            patientContracts: $scope.patient.contracts,
            patientMainLanguage: $scope.patient.mainLanguage,
            patientSecondaryLanguage: $scope.patient.secondaryLanguage,
            patientPhoneNumbers: $scope.patient.phoneNumbers,
            patientVacations: $scope.patient.vacations.filter(v => !v.removedAt),
            patientAuthorizations: $scope.patient.authorizations,
            calendarDate: undefined
        };

        entityNewVisitModalService.setNewVisitData(newVisitData);
    }

    function setDeleteVisitsData() {
        const deleteVisitData = {
            patientId: $scope.patient.id,
            selectedVisits: $scope.selectedVisits,
            editShiftsParams: $scope.editShiftsParams
        };

        entityNewVisitModalService.setDeleteVisitData(deleteVisitData);
    }

    function setMissedVisitsData() {
        const missedVisitData = {
            patientId: $scope.patient.id,
            selectedVisits: $scope.selectedVisits,
            editShiftsParams: $scope.editShiftsParams
        };

        entityNewVisitModalService.setMissedVisitData(missedVisitData);
    }

    function scrollToSubsection({ sectionLabel, subsectionLabel }) {
        const sectionIdx = $scope.sections.list.findIndex(section => section.label === sectionLabel);
        const section = $scope.sections.list[sectionIdx];
        const subsectionIdx = section.list.findIndex(subsection => subsection.label === subsectionLabel);
        $scope.sections.activeIdx = sectionIdx;
        $scope.sections.list[sectionIdx].activeIdx = subsectionIdx;
    }

    $scope.exportVitalsToExcel = (vitals) => {
        const rows = [];
        const titles = [
            'Vital Sign',
            'Date Taken',
            'Time Taken',
            'Taken By',
            'Value',
            'Units',
            'Method',
            'Comments'
        ];

        rows.push(titles);

        vitals.forEach((vital) => {
            vital.answers.forEach((answer) => {
                const row = [];
                titles.forEach((title) => {
                    if (title === 'Vital Sign') row.push($filter('csvValueWithComma')(vital.questionText) || '');
                    else if (title === 'Date Taken') row.push($filter("mfShortDate")(answer.vitalMeasuredAt) || '');
                    else if (title === 'Time Taken') row.push($filter("mfShortTime")(answer.vitalMeasuredAt) || '');
                    else if (title === 'Taken By') row.push($filter('csvValueWithComma')(answer.reporterName) || '');
                    else if (title === 'Value') row.push($filter('csvValueWithComma')(answer.answerText) || '');
                    else if (title === 'Units') row.push(answer.vitalUnit || '');
                    else if (title === 'Method') row.push(answer.vitalMethod || '');
                    else if (title === 'Comments') row.push($filter('csvValueWithComma')(answer.vitalComments) || '');
                });
                rows.push(row);
            })
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const startDate = $scope.vitalQuestionsExcelStartDate.toJSON().slice(0, 10);
        const endDate = $scope.vitalQuestionsExcelEndDate.toJSON().slice(0, 10);
        const patientName = ($scope.patient.firstName + " " + $scope.patient.lastName).replace(' ', '-');
        link.setAttribute("download", "medflyt-patient-vitals-" + patientName + "-" + startDate + "-to-" + endDate + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    $scope.openNewPatientTaskModal = ({
        task,
        date,
        certificationPeriod
    }) => {
        const newScope = $scope.$new();

        newScope.task = null;
        newScope.type = null;
        newScope.patient = $scope.patient;
        newScope.date = date;
        newScope.certificationPeriod = certificationPeriod;

        if (task) {
            newScope.task = task;
            newScope.task.documents = newScope.task.documents.filter(doc => doc !== undefined);
            newScope.type = task.type;
        }

        const docs = $scope.availableForms
            .filter(doc => doc.title !== "")
            .map(doc => ({ id: doc.id, label: doc.title }));

        newScope.documentsMultiSelectOptions = docs.filter(doc => {
            const document = newScope.availableForms.find(d => d.id === doc.id);
            return document && document.versions && document.versions.find(v => v.isPublished) !== undefined;
        });

        const patientOfficePOC = $scope.planOfCareTypes.find(poc => poc.officeId === $scope.patient.currentOfficeId);

        // add plan of care to array with unique id '0'
        if (patientOfficePOC) {
            newScope.patientOfficePOC = patientOfficePOC;
            newScope.documentsMultiSelectOptions.push({
                id: 0,
                label: $scope.planOfCare.name,
                isPlanOfCare: true
            });
        }

        return $uibModal.open({
            templateUrl: 'admin/views/new-patient-task-modal.html',
            size: 'lg',
            controller: 'newPatientTaskModalCtrl',
            scope: newScope,
            backdrop: 'static',
            windowClass: "modal-slide-in-right uib-side-modal uib-side-modal-wide"
        }).result;
    }

    $scope.handleSideBarStateChange = (newState, calendarDay = undefined) => {
        const secondClickDoesntCloseModals = ["NEW_VISIT", "EDIT_NEW_VISIT"];
        if (
            (
                $scope.sideBarState === newState &&
                !secondClickDoesntCloseModals.includes(newState)
            ) ||
            $rootScope.isNewVisitSideModalOpen === false
        ) {
            $scope.sideBarState = undefined;
            $rootScope.isNewVisitSideModalOpen = undefined;
        } else {
            let preventSidebarChange = false;
            switch (newState) {
                case "CHATS":
                    break;
                case "NOTES":
                    setNotesData('Patient');
                    break;
                case "CALENDAR":
                    setNotesData('PatientCalendar', calendarDay);
                    break;
                case "NEW_VISIT":
                    if (!calendarDay) {
                        setNewVisitData();
                    }
                    scrollToSubsection({ sectionLabel: "Calendar", subsectionLabel: "Calendar" });
                    $rootScope.$broadcast("calendar_new_visit");
                    break;
                case "EDIT_NEW_VISIT":
                    if ($rootScope.isNewVisitSideModalOpen === true) {
                        preventSidebarChange = true;
                        $rootScope.$emit("reset_edit_visits");
                    } else {
                        $rootScope.$broadcast('calendar_edit_visit');
                    }
                    break;
                case "DELETE_VISIT":
                    setDeleteVisitsData();
                    break;
                case "MISS_VISIT":
                    setMissedVisitsData();
                    break;
                default:
                    break;
            }

            if (!preventSidebarChange) {
                $scope.sideBarState = newState;
            }
        }
    }

    const summaryColumn = {
        heading: "Summary",
        className: "extra",
        html: (items) => {
            const aggregations = {
                scheduledTime: 0,
                recordedTime: 0,
                paidTime: 0,
                billedTime: 0,
            };

            items.forEach(dayContainer => {
                aggregations.scheduledTime += dayContainer.items
                    .filter(filterSummaryVisits)
                    .reduce((acc, visit) => acc + (new Date(visit.payload.endTime) - new Date(visit.payload.startTime)) / 1000, 0);

                aggregations.recordedTime += dayContainer.items
                    .filter(filterSummaryVisits)
                    .filter(visit => visit.payload.clockinTime !== null && visit.payload.clockoutTime !== null)
                    .reduce((acc, visit) => acc + (new Date(visit.payload.clockoutTime) - new Date(visit.payload.clockinTime)) / 1000, 0);

                aggregations.paidTime += dayContainer.items
                    .filter(filterSummaryVisits)
                    .reduce((acc, visit) => acc + visit.payload.paidSeconds, 0);

                aggregations.billedTime += dayContainer.items
                    .filter(filterSummaryVisits)
                    .reduce((acc, visit) => acc + visit.payload.billedSeconds, 0);
            });

            return `
              <div class="calendar-summary">
                  <p><span>Scheduled:</span><strong>${formatSeconds(aggregations.scheduledTime)}</strong></p>
                  <p><span>Recorded:</span><strong>${formatSeconds(aggregations.recordedTime)}</strong></p>
                  <p><span>Paid:</span><strong>${formatSeconds(aggregations.paidTime)}</strong></p>
                  <p><span>Billed:</span><strong>${formatSeconds(aggregations.billedTime)}</strong></p>
              </div>
          `
        },
    };

    function getSummaryColumn() {
        const column = angular.copy(summaryColumn);

        column.hide = !$scope.showSummaryColumn;

        return column;
    }

    function setExtraColumns() {
        $scope.extraColumns = [
            getSummaryColumn(),
        ];
    }

    setExtraColumns();

    function formatSeconds(seconds) {
        const h = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds % 3600 / 60);

        const m = minutes > 9 ? `${minutes}` : `0${minutes}`;

        return `${h}:${m}h`;
    }

    const filterSummaryVisits = (visit) => ["ASSIGNED_VISIT", "UNSTAFFED_VISIT"].includes(visit.type);

    function getSelectedVisits() {
        $scope.selectedVisits = entityNewVisitModalService.selectedItems.visits;
    }

    function getSelectedVacations() {
        $scope.selectedVacations = entityNewVisitModalService.selectedItems.vacations;
    }

    function setSelectedItems({ newVisits, newVacations }) {
        entityNewVisitModalService.setSelectedItems({ visits: newVisits, vacations: newVacations });
    }

    entityNewVisitModalService.registerObserverCallback(
        "visits",
        "patientCtrl",
        getSelectedVisits
    );

    entityNewVisitModalService.registerObserverCallback(
        "vacations",
        "patientCtrl",
        getSelectedVacations
    );

    setSelectedItems({ newVisits: [], newVacations: [] });
    $rootScope.isNewVisitSideModalOpen = undefined;

    function getPatientChats(patientId) {
        const caregivers = DatabaseApi.caregivers();
        let caregiversWithNoChatWithWithUser = [];
        for (var c in caregivers) {
            caregiversWithNoChatWithWithUser.push(caregivers[c.toString()].id);
        }

        const emptyDate = JSON.stringify(new Date('1/1/1980')).slice(1, -1);

        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/chat_rooms/' + $rootScope.agencyMemberId).then(function ({ data }) {
            if (!data) {
                return;
            }
            const chats = data.chatRooms;
            if (!chats || chats.length === 0) {
                return;
            }

            const temp = [];
            const existsIds = [];
            let lastMessage = JSON.stringify(new Date('1/1/1980')).slice(1, -1);
            let lastMessageIdx;

            for (const chat of chats) {
                if (chat.chatRoomType.type !== "CaregiverPatientChat") { // Filter only patient chats
                    continue;
                }
                if (chat.chatRoomType.patientId !== patientId) { // Filter only this patients chat
                    continue;
                }
                if (!caregivers[chat.chatRoomType.caregiverId]) { // caregiver actually exists on this agency
                    continue;
                }
                const caregiverId = chat.chatRoomType.caregiverId;
                const chatLine = angular.copy(caregivers[caregiverId]);
                chatLine.chat = chat;

                if (caregiversWithNoChatWithWithUser.indexOf(caregiverId) > -1) {
                    caregiversWithNoChatWithWithUser.splice(caregiversWithNoChatWithWithUser.indexOf(caregiverId), 1);
                }

                let lastViewed;
                if (chat.members) {
                    chat.viewed = null;
                    for (const member of chat.members) {
                        if (member.user.type === 'AgencyMember') {
                            if (member.lastViewed > lastViewed) {
                                lastViewed = member.lastViewed;
                            } else if (!lastViewed) {
                                lastViewed = member.lastViewed;
                            }
                        } else {
                            chat.viewed = member.lastViewed;
                        }
                    }
                }

                let notViewedCounter = 0;
                if (chat.messages && chat.messages.length) {
                    chatLine.lastMessage = chat.messages[chat.messages.length - 1].createdAt;
                    if (chat.messages[chat.messages.length - 1].createdAt > lastMessage) {
                        lastMessage = chat.messages[chat.messages.length - 1].createdAt;
                        lastMessageIdx = temp.length;
                    }

                    if (lastViewed) {
                        var messagesLength = chat.messages.length - 1;
                        for (var i = messagesLength; i >= 0; i--) {
                            if (!chat.messages[i].broadcast) {
                                if (chat.messages[i].createdAt > lastViewed) notViewedCounter++;
                                else break;
                            }
                        }
                    } else {
                        if (chat.messages[0].user.type === 'Caregiver') {
                            notViewedCounter++;
                        }
                    }
                    chat.notViewedCounter = notViewedCounter || 0;
                    chat.notViewedCounterBoolean = notViewedCounter > 0;
                    chatLine.notViewedCounterBoolean = notViewedCounter > 0;
                    if (notViewedCounter) {
                        $rootScope.commPageChatCounterCache[chat.id] = true;
                        // chatLine.lastMessage = lastMessage;
                    } else delete $rootScope.commPageChatCounterCache[chat.id];
                } else {
                    chatLine.lastMessage = emptyDate;
                }
                temp.push(chatLine);
                existsIds.push(chat.chatRoomType.caregiverId.toString());
            }

            caregiversWithNoChatWithWithUser.forEach(function (id) {
                var c = angular.copy(caregivers[id.toString()]);
                if (!c) return;
                c.lastMessage = emptyDate;
                c.chat = undefined;
                temp.push(c);
            });

            $scope.patientChats = temp;
        }, function (err) {
            toaster.pop('error', "Something Went Wrong", 'Failed to load patient chats');
        });
    }

    $scope.patientStatusEvents = {
        onSelectionChanged: () => {
            if (!$scope.patient.selectedStatus.id) return;

            const findSelectedStatus = $scope.statuses.find(
                (stat) => stat.id === $scope.patient.selectedStatus.id
            );
            if (!findSelectedStatus) return;
            $scope.patient.status = findSelectedStatus.value;
            $scope.patchPatient('status');
        },
    };

    $scope.handleChatClicked = (chatClicked) => {
        $rootScope.openChat(chatClicked);
    }

    $scope.exportPatientProfile = () => {
        $scope.isExportingProfile = true;

        exportProfileService
            .exportPatientProfilePDF(
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                $scope.patientId
            )
            .finally(() => $scope.isExportingProfile = false);
    };

    $scope.openExportPatientProfileModal = () => {
        $uibModal.open({
            templateUrl: "admin/views/export-profile-modal.html",
            size: "md",
            controller: "exportPatientProfileModal",
            resolve: {
                agencyId: () => $rootScope.agencyId,
                agencyMemberId: () => $rootScope.agencyMemberId,
                patientId: () => $scope.patientId
            }
        });
    };

    $scope.clearSelectedChat = () => {
        $scope.currentPatientChat = undefined;
    }

    $scope.uploadContractFile = ($event, contract) => {
        $event.stopPropagation();
        contract.title = contract.contractTypeName;
        contract.documentTypeId = contract.id;
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_contract/upload_file`;
        $rootScope.openUploadDocumentPdfModal({
            url: url,
            document: contract, successCallback: (result) => {
                if (result !== null) {
                    if (contract.files === undefined) {
                        contract.files = [];
                    }
                    contract.files.push(result.data.url);
                }
            }
        });
    }

    $scope.uploadAuthorizationFile = ($event, authorization) => {
        $event.stopPropagation();
        authorization.title = authorization.contractName + " authorization";
        authorization.documentTypeId = authorization.id;
        const url = `agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patient_authorization/upload_file`;
        $rootScope.openUploadDocumentPdfModal({
            url: url,
            document: authorization, successCallback: (result) => {
                if (result !== null) {
                    if (authorization.files === undefined) {
                        authorization.files = [];
                    }
                    authorization.files.push(result.data);
                }
            }
        });
    }

    $scope.openAuthorizationFile = (row, patientAuthorizationFileId) => {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/patient_authorization_file/:patientAuthorizationFileId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            patientAuthorizationFileId
        );

        row.isLoadingPatientAuthorizationFile = true;
        row.loadingPatientAuthorizationFileId = patientAuthorizationFileId;
        DatabaseApi.get(url).then((res) => {
            $window.open(res.data.url);
        }, () => {
            toaster.pop("error", "Something went wrong", "Can't get authorization file");
        }).finally(() => row.isLoadingPatientAuthorizationFile = false);
    }

    $rootScope.$on("chat_room_updated", function (event, data) {
        if (data.chatRoomType.patientId !== $scope.patient.id) {
            return;
        }
        DatabaseApi.getMessagesByIds(data.id).then(
            function (res) {
                if (!res.chatRoomType) {
                    return;
                }
                if (res.chatRoomType.patientId !== $scope.patient.id) {
                    return;
                }
                if (res.chatRoomType.type !== "CaregiverPatientChat") {
                    return;
                }

                $scope.patientChats = $scope.patientChats
                    .filter(caregiver => caregiver !== undefined)
                    .map((caregiver) => {
                        if (caregiver.chat && caregiver.chat.id === res.id) {
                            caregiver = {
                                ...caregiver,
                                chat: res,
                            }
                        } else if (!caregiver.chat && caregiver.id === data.chatRoomType.caregiverId) {
                            caregiver = {
                                ...caregiver,
                                chat: res,
                            }
                        }
                        return caregiver;
                    });

                if (
                    $scope.currentPatientChat !== undefined &&
                    $scope.currentPatientChat.chat.id === res.id
                ) {
                    $scope.currentPatientChat.chat = res;
                }
            },
            function (err) {
                console.error(err);
            }
        );
    });

    function getCaregiverAssociated() {
        DatabaseApi.get(
            "agencies/" +
            $rootScope.agencyId +
            "/agency_members/" +
            $rootScope.agencyMemberId +
            "/patient/" +
            patientId +
            "/caergivers_associated"
        )
            .then(function (res) {
                initCaregiversTable(res.data.caregivers);
            })
            .catch(function (err) {
                toaster.pop(
                    "error",
                    "Something went wrong",
                    "Could not get caregivers associated"
                );
            });
    }

    function setPlanOfCareType() {
        if (!$scope.planOfCareTypes || $scope.planOfCareTypes.length === 0) {
            return;
        }

        $scope.planOfCare = $scope.planOfCareTypes[0];
    }

    $scope.showBranches = function () {
        if (!$scope.patient) return;
        const selected = $scope.patient.branches.map((branch) => {
            return $scope.agencyBranches.find((obj) => {
                return obj.id === branch.id;
            }).label;
        });
        return selected.length ? selected.join(", ") : "Not set";
    };

    $scope.patientBranchesEvents = {
        onSelectionChanged() {
            updatePatientBranches();
        },
    };

    function updatePatientBranches() {
        const patientId = $scope.patient.id;
        const body = {
            agencyBranchIds: $scope.patient.branches.map((branch) => branch.id)
        };
        agencyBranchService
            .updatePatientBranches(
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                patientId,
                body
            )
            .then((res) => {
                toaster.pop(
                    "success",
                    "Success",
                    "Patient branches updated successfully"
                );
            })
            .catch((err) => {
                toaster.pop("error", "Failed to update patient branches");
            });
    }

    function initCaregiversTable(data) {
        if (!data || !data.length) return;
        const caregivers = DatabaseApi.caregivers();

        const options = {
            count: 10,
            sorting: { id: "desc" },
        };

        const parsedData = data.map((caregiverData) => {
            const caregiver = caregivers[caregiverData.caregiverId];
            return {
                ...caregiverData,
                caregiverName: caregiver ? caregiver.displayName + (caregiver.displayId ? ' (' + caregiver.displayId + ')' : '') : '',
            };
        });

        $scope.caregiversTable = new NgTableParams(options, {
            counts: [],
            dataset: parsedData,
        });
    }

    function deletePatientPhoneNumber(phoneNumber) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/phonenumbers/:phoneNumberId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.patient.id)
            .replace(":phoneNumberId", phoneNumber.id);

        return DatabaseApi.delete(url);
    }

    function deletePatientAddress(address) {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/addresses/:addressId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.patient.id)
            .replace(":addressId", address.id);

        return DatabaseApi.delete(url);
    }

    $scope.getPhoneNumber = (phoneNumberId) => {
        return $scope.patient.phoneNumbers.find(x => x.id === phoneNumberId);
    }

    $scope.patchPhonenumber = (phoneNumber) => {
        const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/phonenumbers/:phoneNumberId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.patientId)
            .replace(":phoneNumberId", phoneNumber.id)

        const body = { phonenumber: phoneNumber.phonenumber };

        return DatabaseApi.put(url, body)
            .then(({ data }) => {
                $scope.patient.phoneNumbers.map(x => x.id === data.phoneNumber.id ? data.phoneNumber : x);
                mapPatientPhonenumbers();
                toaster.pop("succes", "Phone number updated");
            })
            .catch(() => toaster.pop("error", "Something went wrong"));
    }

    $scope.openNewPhoneNumberModal = () => {
        const instance = $uibModal.open({
            templateUrl: 'admin/views/new-phonenumber-modal.html',
            size: 'lg',
            resolve: {
                patient: () => $scope.patient,
                onSuccess: () => handleSuccess,
                onFail: () => handleFail
            },
            controller: 'newPhonenumberModalCtrl'
        });

        function handleSuccess(phoneNumber) {
            $scope.patient.phoneNumbers.push(phoneNumber);
            mapPatientPhonenumbers();
            toaster.pop("success", "Phone number has been added");
            instance.close();
        }

        function handleFail() {
            toaster.pop("error", "Failed to create new phone number");
        }
    }

    $scope.openEditAddressPhoneNumberModal = (address) => {
        const instance = $uibModal.open({
            templateUrl: 'admin/views/edit-address-phonenumber-modal.html',
            size: 'sm',
            windowClass: 'center-center',
            resolve: {
                patient: () => $scope.patient,
                address: () => address,
                onSuccess: () => handleSuccess,
                onFail: () => handleFail
            },
            controller: 'editAddressPhonenumberModalCtrl'
        });

        function handleSuccess(address) {
            toaster.pop("success", "Modified successfully");
            $scope.patient.addresses = $scope.patient.addresses.map(x => x.id === address.id ? address : x);
            instance.close();
        }

        function handleFail() {
            toaster.pop("error", "Failed to modify address phone numbers");
        }
    }

    $scope.handleChangeAddressField = (address, field) => {
        const url = `agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/addresses/:patientAddressId`
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":patientId", $scope.patient.id)
            .replace(":patientAddressId", address.id);

        let value;

        switch (field) {
            case "address":
                value = address.address.formatted_address;
                break;
            case "address2":
            case "evvLocation":
            case "description":
                value = address[field];
                break;
            default:
                throw new Error(`Could not get the value of field "${field}"`);
        }

        const data = {
            [field]: value,
        };

        return DatabaseApi.put(url, data)
            .then(() => toaster.pop("success", "Modified successfully"))
            .catch((e) => toaster.pop("success", "Something went wrong"));
    }

    $scope.handleClickNewAddress = () => {
        const instance = $uibModal.open({
            templateUrl: 'admin/views/new-patient-address-modal.html',
            size: 'md',
            windowClass: 'center-center',
            resolve: {
                patient: () => $scope.patient,
                onSuccess: () => handleSuccess,
                onFail: () => handleFail
            },
            controller: 'newPatientAddressModalCtrl'
        });

        function handleSuccess(address) {
            toaster.pop("success", "Address created successfully");
            $scope.patient.addresses.push(address);
            instance.close();
        }

        function handleFail() {
            toaster.pop("error", "Failed to create patient address");
        }
    }

    $scope.handleClickRemovePhoneNumber = (phoneNumber) => {
        SweetAlert.swal({
            title: "Remove Phone number?",
            text: `Are you sure you want to remove this phone number?`,
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete",
        }, function (hasAccepted) {
            if (hasAccepted) {
                deletePatientPhoneNumber(phoneNumber)
                    .then(() => {
                        SweetAlert.swal("The phone number has been successfully deleted");
                        $scope.patient.phoneNumbers = $scope.patient.phoneNumbers.filter(x => x.id !== phoneNumber.id);
                    })
                    .catch(() => {
                        SweetAlert.swalswal("Failed to delete the phone numbers");
                    })
            }
        });
    }

    $scope.handleClickRemoveAddress = (address) => {
        SweetAlert.swal({
            title: "Remove address?",
            text: `Are you sure you want to remove this address?`,
            type: "warning",
            showCancelButton: true,
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
        }, function (hasAccepted) {
            if (hasAccepted) {
                deletePatientAddress(address)
                    .then(() => {
                        SweetAlert.swal("The address has been successfully deleted");
                        $scope.patient.addresses = $scope.patient.addresses.filter(x => x.id !== address.id);
                    })
                    .catch(() => {
                        SweetAlert.swal("Failed to delete the addresses");
                    })
            }
        });
    }

    $scope.handleEditVisitsFromListView = () => {
        entityNewVisitModalService.setEditVisitData({
            patientId: $scope.patient.id,
            patientContracts: $scope.patient.contracts,
            patientMainLanguage: $scope.patient.mainLanguage,
            patientSecondaryLanguage: $scope.patient.secondaryLanguage,
            patientPhoneNumbers: $scope.patient.phoneNumbers,
            patientVacations: $scope.patient.vacations.filter(v => !v.removedAt),
            patientAuthorizations: $scope.patient.authorizations,
            targetElementId: 'scroll-list-view',
            editShiftsParams: 'CURRENT_SHIFTS',
            editShiftsParamsUntilDate: undefined,
        });

        $scope.handleClickEditItems();
    };

    $scope.handleClickNewVisit = () => {
        const hasCalendarDate = entityNewVisitModalService.newVisitData.calendarDate !== undefined;
        $scope.handleSideBarStateChange('NEW_VISIT', hasCalendarDate);
    };

    $scope.handleClickNewTask = (date) => {
        $scope.openNewPatientTaskModal({ date });
    }

    $scope.handleClickEditTask = (task) => {
        $scope.openNewPatientTaskModal({ task });
    }

    $scope.handleClickCancelTask = (task) => {
        $scope.cancelTask(task);
    }

    $scope.handleClickStopTaskBroadcast = (task) => {
        $scope.stopTaskBroadcast(task);
    }

    $scope.handleClickScheduleTask = (task) => {
        openScheduleTaskModal(task);
    }

    $scope.handleClickEditItems = () => {
        $scope.handleSideBarStateChange('EDIT_NEW_VISIT');
    };

    $scope.handleDeleteItems = (scrollToSection) => {
        if ($scope.selectedVacations && $scope.selectedVacations.length > 0) {
            handleDeleteVacations(scrollToSection);
        }

        if ($scope.selectedVisits && $scope.selectedVisits.length > 0) {
            handleDeleteVisits(scrollToSection);
        }
    };

    $scope.handleMissItems = (scrollToSection) => {
        if ($scope.selectedVisits && $scope.selectedVisits.length > 0) {
            handleMissVisits(scrollToSection);
        }
    };

    /*const handleDeleteVisits = (scrollToSection) => {
        const newScope = $scope.$new(true);
        newScope.patientId = $scope.patient.id;
        newScope.selectedVisits = $scope.selectedVisits;
        newScope.editShiftsParams = $scope.editShiftsParams;

        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/visit-instances-delete-modal.html",
            size: "md",
            controller: "visitInstancesDeleteModalCtrl",
            scope: newScope
        });

        modalInstance.result.then(function (res) {
            if (res && res.success) {
                $rootScope.$emit("refresh_visits");
                generalUtils.scrollToElement(scrollToSection);
            }
        });
    };*/

    const handleDeleteVisits = (scrollToSection) => {
        $scope.handleSideBarStateChange('DELETE_VISIT');
    };

    const handleMissVisits = (scrollToSection) => {
        $scope.handleSideBarStateChange('MISS_VISIT');
    };

    const handleDeleteVacations = (scrollToSection) => {
        const vacationsPreview = $scope.selectedVacations.map((vacation, index) => {
            const date = $filter("mfShortDate")(vacation.date);
            return `${index + 1}. ${date}`;
        }).join("\n");

        const options = [
            {
                id: 1,
                type: "DONT_REGENERATE_VISITS",
                label: "Delete selected vacations",
                regenerateVisits: false
            },
            {
                id: 2,
                type: "REGENERATE_VISITS",
                label: "Delete selected vacations and restore weekly template visits (if exist)",
                regenerateVisits: true
            }
        ];

        const modal = mfModal.create({
            subject: "Delete vacations",
            options: options,
            message: `Are you sure you want to delete selected vacations?\n\n${vacationsPreview}`,
            layoutOrder: ["message", "options"],
            cancelLabel: "Cancel",
            confirmLabel: "Delete",
            variant: "danger",
            onConfirm: ({ selectedOption }) => {
                modal.setLoading(true);
                if (!selectedOption) {
                    toaster.pop("error", "Please select an option!");
                    modal.setLoading(false);
                    return;
                }

                const body = {
                    ids: $scope.selectedVacations.map(vacation => vacation.id),
                    regenerateVisits: options.find(option =>
                        option.id === selectedOption
                    ).regenerateVisits
                };

                const deleteVacationsUrl = wildcard(
                    "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/patient_vacations/delete",
                    $rootScope.agencyId,
                    $rootScope.agencyMemberId,
                    $scope.patient.id
                );

                $scope.isSubmitting = true;
                DatabaseApi.post(deleteVacationsUrl, body).then((res) => {
                    setSelectedItems({ newVacations: [] });
                    toaster.pop("success", "Selected vacations succesfully deleted");
                    modal.close();
                    $scope.handleChangePatientVacations(
                        $scope.patient.vacations.filter(vacation => !body.ids.includes(vacation.id))
                    );
                    $rootScope.$emit("refresh_visits");
                    generalUtils.scrollToElement(scrollToSection);
                }, () => {
                    toaster.pop("error", "Something went wrong", "Can't delete selected vacations");
                }).finally(() => {
                    $scope.isSubmitting = false;
                    modal.setLoading(false);
                });
            },
        });
    };

    $scope.handleStopBroadcastVisits = (scrollToSection) => {
        const selectedFiltered = $scope.selectedVisits.filter(v => v.visitBroadcast && v.visitBroadcast.visitBroadcastId);

        const visitBroadcastIds = [
            ...new Set(selectedFiltered.map(v => v.visitBroadcast.visitBroadcastId))
        ].filter(Boolean);
        const visitInstanceIds = selectedFiltered.map(v => v.visitInstanceId);
        let modal;

        const stopBroadcastByBroadcastOrVisitIds = (type = 'broadcasts') => {
            modal.setLoading(true);

            const url = wildcard(
                "agencies/:agencyId/agency_member/:agencyMemberId/patients/:patientId/broadcast_visits/stop",
                $rootScope.agencyId,
                $rootScope.agencyMemberId,
                $scope.patient.id
            );

            const body = {
                type,
                ...(type === 'broadcasts' ? { visitBroadcastIds } : { visitInstanceIds }),
            };

            DatabaseApi.put(url, body).then((_) => {
                toaster.pop("success", "Successfully stopped visit broadcast");
                $rootScope.$emit("refresh_visits");
                modal.close();
                generalUtils.scrollToElement(scrollToSection);
            }, (err) => {
                toaster.pop("error", "Oops...", "Can't stop selected broadcasts");
                modal.update({
                    isLoading: false,
                    message: "Something went wrong, please try again.",
                });
            });
        }

        const showBroadcastModal = (visitInstanceIds, visitBroadcastIds) => {
            const modalOptions = {
                subject: "Stop broadcast",
                message: "Are you sure want to stop broadcasting?",
                cancelLabel: "Cancel",
                confirmLabel: `Stop selected (${visitInstanceIds.length}) visits`,
                variant: "warning",
                onConfirm: () => stopBroadcastByBroadcastOrVisitIds('visits')
            };

            if (visitInstanceIds.length > 1) {
                modalOptions.extraActionLabel = `Stop entire (${visitBroadcastIds.length}) series`;
                modalOptions.extraAction = true;
                modalOptions.onExtraAction = () => stopBroadcastByBroadcastOrVisitIds('broadcasts');
            }

            modal = mfModal.create(modalOptions);
        }

        showBroadcastModal(visitInstanceIds, visitBroadcastIds);
    };

    $scope.handleChangePatientVacations = (newPatientVacations) => {
        $scope.patient.vacations = newPatientVacations;
    };

    $scope.handleCreatePatientVacations = (selectedDate) => {
        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/new-patient-vacation-modal.html',
            size: 'md',
            controller: 'newPatientVacationModalCtrl',
            windowClass: "center-center",
            resolve: {
                selectedDate: function () { return selectedDate },
                patientId: function () { return $scope.patient.id },
            },
        });
        return modalInstance.result;
    };

    $scope.cancelTask = (task) => {
        const newScope = $scope.$new();
        newScope.task = task;

        $uibModal.open({
            templateUrl: "admin/views/patient-upcoming-task-cancel-modal.html",
            size: "md",
            controller: "patientUpcomigTaskCancelModalCtrl",
            scope: newScope,
        });
    };

    $scope.stopTaskBroadcast = (task) => {
        const modal = mfModal.create({
            subject: "Stop broadcast",
            message: `
                Are you sure you want to stop selected task broadcasts?
            `,
            cancelLabel: "Cancel",
            confirmLabel: "Stop selected broadcasts",
            variant: "danger",
            onConfirm: () => {
                modal.setLoading(true);

                const url = wildcard(
                    "agencies/:agencyId/agency_members/:agencyMemberId/patient_task_instances_broadcast/:taskInstanceId",
                    $rootScope.agencyId,
                    $rootScope.agencyMemberId,
                    task.taskInstanceId
                );

                DatabaseApi.delete(url).then((_) => {
                    toaster.pop("success", "Successfully stopped task broadcast");
                    $rootScope.$emit("patient_task_saved");
                    modal.close();
                }, (_) => {
                    toaster.pop("error", "Oops...", "Can't stop selected broadcasts");
                    modal.update({
                        isLoading: false,
                        message: "Something went wrong, please try again.",
                    });
                });
            }
        });
    };

    $scope.handleClickToggleDeletedVisits = () => {
        $scope.showDeletedVisitsOnCalendar = !$scope.showDeletedVisitsOnCalendar;
        localStorage.setItem("patientCalendarShowDeletedVisits", $scope.showDeletedVisitsOnCalendar);
    };

    $scope.handleClickToggleAuthorizationDetails = () => {
        $scope.showAuthOnCalendar = !$scope.showAuthOnCalendar;
        localStorage.setItem("patientCalendarShowAuthorizationDetails", $scope.showAuthOnCalendar);
    };

    $scope.handleClickToggleSummaryColumn = () => {
        $scope.showSummaryColumn = !$scope.showSummaryColumn;
        localStorage.setItem("patientCalendarShowSummaryColumn", $scope.showSummaryColumn);
        setExtraColumns();
    };

    const handleConfirmDeletePatientAuthorization = (authorizationId) => {
        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/authorization/:authorizationId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            authorizationId
        );

        DatabaseApi.delete(url).then((_) => {
            getAuthorizations();
            toaster.pop("success", "Successfully deleted authorization");
        }, (err) => {
            if (!err.data.totalVisits) return;

            const totalVisits = `Authorization has ${err.data.totalVisits} ` +
                `${err.data.totalVisits === 1 ? 'visit' : 'visits'} `;

            let authorizationVisitsRange;
            if (err.data.totalVisits === 1) {
                authorizationVisitsRange = `at: ${moment(err.data.firstVisitDate).format("MM/DD/YYYY")}`;
            } else {
                authorizationVisitsRange = `from ${moment(err.data.firstVisitDate).format("MM/DD/YYYY")}` +
                    ` - ${moment(err.data.lastVisitDate).format("MM/DD/YYYY")}`;
            }
            const billedOrPaid = `that ${err.data.totalVisits === 1 ? 'is' : 'are'} billed or paid.`;
            const errorMessage = `${totalVisits} ${authorizationVisitsRange} ${billedOrPaid}`;

            const modal = mfModal.create({
                subject: "Can't delete authorization",
                message: errorMessage,
                variant: "danger",
                confirmLabel: "OK",
                hideCancelButton: true,
                onConfirm: () => modal.close()
            });
        });
    }

    $scope.handleClickDeletePatientAuthorization = (authorizationId) => {
        const modal = mfModal.create({
            subject: "Delete Authorization",
            message: "Deleting this authorization will remove all allocated hours from visits.",
            variant: "danger",
            confirmLabel: "Confirm",
            cancelLabel: "Cancel",
            onConfirm: () => modal.close() && handleConfirmDeletePatientAuthorization(authorizationId)
        });
    };

    $scope.handleUpdatePatientSOCFromMedicalSummary = (newVal) => {
        $scope.patient.startOfCare = newVal;
    };

    $scope.toggleShouldOpenCertificationPeriodModal = () => {
        $scope.shouldOpenCertificationPeriodModal = !$scope.shouldOpenCertificationPeriodModal;
    };

    const openCertififcationPeriodModal = ({ resolve }) => {
        $uibModal.open({
            templateUrl: "admin/views/certification-period-modal.html",
            size: "md",
            controller: "certificationPeriodModal",
            windowClass: "modal center-center",
            resolve: resolve,
        });
    };

    const openScheduleTaskModal = (task) => {
        $uibModal.open({
            templateUrl: 'admin/views/patient-schedule-task-modal.html',
            controller: 'patientScheduleTaskModalCtrl',
            size: 'md',
            resolve: {
                task: () => task,
            },
        });
    };

    $scope.patchPatientStartOfCare = () => {
        $scope.patchPatient('startOfCare');

        if ($scope.shouldOpenCertificationPeriodModal) {
            openCertififcationPeriodModal({
                resolve: {
                    patientId: () => parseInt($scope.patientId, 10),
                    certificationPeriod: {
                        type: "START_OF_CARE",
                        startDate: convert(LocalDate.parse($scope.patient.startOfCare)).toDate(),
                    },
                    patientStartOfCareDate: () => $scope.patient.startOfCare,
                    onSubmitted: () => (res) => {
                        if (moment(res.certificationPeriod.startDate).isAfter(moment($scope.patient.startOfCare))) {
                            $scope.patient.startOfCare = res.certificationPeriod.startDate;
                        }
                    }
                },
            });
        }
    }

    $rootScope.$on('got_patients_statuses', function () {
        initStatusesOptions();
    });

    $rootScope.$on("got_patient_task_templates", function (event) {
        $scope.patientTaskTemplates = DatabaseApi.patientTaskTemplates();
    });

    $rootScope.$on("got_plan_of_care_type", function (event) {
        $scope.planOfCareTypes = DatabaseApi.plansOfCare();
        setPlanOfCareType();
    });

    $rootScope.$on("refresh_patient_authorizations", function () {
        getAuthorizations();
    });

    $rootScope.$on("got_agency_teams", function () {
        $scope.agencyTeams = DatabaseApi.agencyTeams().filter(team => team.active);
        if ($scope.patient) {
            initPatientAgencyTeam($scope.patient.agencyTeamId);
        }
    });

    const stripHtml = (html) => {
        const tempElement = document.createElement("DIV");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    setPlanOfCareType();
};
