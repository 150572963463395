import { LocalDate, nativeJs } from "js-joda";
import moment from "moment";
import { getBase64 } from "../utils/getBase64";

//! @ngInject
export function caregiverComplianceGroupModalCtrl(
    $scope,
    $rootScope,
    $http,
    $uibModalInstance,
    combinePdf,
    $q,
    toaster,
    Consts,
    complianceService
) {
    $scope.extraSettings = complianceService.complianceGroupExtraSettings;
    $scope.groupItemsMap = {};
    $scope.selectedTopItemDocId = null;

    const init = () => {
        $scope.complianceItem = $scope.$resolve.parentItem;
        $scope.caregiverId = $scope.$resolve.caregiverId;
        mapItemsLastParentIndication($scope.complianceItem);
        //we will set inital disable, only if tree starts 'or' op and have more parents
        if (!$scope.complianceItem.isLastParent && $scope.complianceItem.groupType !== 'and') {
            toggleAllItemAndChildren($scope.complianceItem, true);
        }
        setTopItemId();
    };

    const setTopItemId = () => {
        const topItem = $scope.complianceItem;
        // CASE FOR LAST PARENT OF AND 'OP'
        if (topItem.isLastParent && topItem.groupType === 'and') {
            $scope.selectedTopItemDocId = topItem.caregiverDocumentTypeId;
            const groupItems = topItem.children.map(child => {
                const selectedDocument = child;
                selectedDocument.complianceInstanceModel = complianceService.setSelectedDocumentModelInitialState(child);
                return {
                    groupItem: topItem,
                    selectedDocument: selectedDocument
                }
            });

            $scope.groupItemsMap[$scope.selectedTopItemDocId] = {
                topItem: topItem,
                groupItems: groupItems
            }
        }
    }

    const mapItemsLastParentIndication = (item) => {
        item.isLastParent = true;
        if (!item.children || item.children.length === 0) return;

        item.children.forEach((child, idx) => {
            child.id = idx;
            if (child.children && child.children.length > 0) {
                // SET dropdown model
                child.selectedDocument = {};
                if (item.isLastParent !== false) {
                    item.isLastParent = false;
                }
                mapItemsLastParentIndication(child);
            }
        });
    };

    const toggleAllItemAndChildren = (root, disabledMode) => {
        root.isDisabled = disabledMode;
        if (!root.children || root.children.length === 0) return;

        root.children.forEach((child) => {
            if (child.children && child.children.length > 0) {
                toggleAllItemAndChildren(child, disabledMode);
            }
        });
    };

    const setSelectedItemActiveState = (root, currentItem) => {
        if (!root.children || root.children.length === 0) return;
        if (root !== currentItem) {
            root.isDisabled = true;
        } else {
            return toggleAllItemAndChildren(currentItem);
        }

        root.children.forEach((child) => {
            if (child.children && child.children.length > 0) {
                setSelectedItemActiveState(child, currentItem);
            }
        });
    };

    const uploadFiles = async (caregiverDocumentTypeId, files) => {
        const deferred = $q.defer();

        try {
            const fetchPromisesWithTypes = [];
            for (const file of files) {
                const fetchCurrentFilePromise = await getBase64(file);
                if (file.type === 'application/pdf') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'pdf' });
                } else if (file.type === 'image/png') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'png' });
                } else if (file.type === 'image/jpg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpg' });
                } else if (file.type === 'image/jpeg') {
                    fetchPromisesWithTypes.push({ fetchPromise: fetchCurrentFilePromise, type: 'jpeg' });
                }
            }
            const bytesPdf = await combinePdf(fetchPromisesWithTypes);
            const blob = new Blob([bytesPdf], { type: "application/pdf" });
            const combinedPdfFile = new File([blob], 'Combined.pdf');
            const url = `${Consts.api}hr/agencies/${$rootScope.agencyId}/caregivers/${$scope.caregiverId}/compliance_items/${caregiverDocumentTypeId}/upload_file`;

            const formData = new FormData();
            formData.append('file', combinedPdfFile, combinedPdfFile.name);

            $http({
                url: url,
                method: 'POST',
                data: formData,
                headers: { 'Content-Type': undefined }
            }).then((res) => {
                deferred.resolve(res.data.id);
            }).catch((err) => {
                $scope.newFiles = null;
                console.error(err);
                toaster.pop('error', "Something went wrong", "Could not create new compliance instance");
                deferred.resolve(null);
            })
        } catch (e) {
            $scope.newFiles = null;
            $scope.uploading = false;
            toaster.pop('error', "Something went wrong", "Could not create new compliance instance");
            deferred.resolve(null);
        }

        return deferred.promise;
    };

    const handleFilesUpload = async (caregiverDocumentTypeId, files) => {
        $scope.uploading = true;
        if (files && files.length > 0) {
            return uploadFiles(caregiverDocumentTypeId, files);
        }

        return undefined;
    };

    const createComplianceInstances = (items) => {
        const body = {
            items: items
        };

        complianceService.createComplianceGroupInstances($scope.caregiverId, body)
            .then((res) => {
                toaster.pop('success', "Items created successfully");
                $scope.uploading = false;
                $scope.closeModal(res);
                $rootScope.$emit("caregiver_compliance_item_created", res);
            }).catch((err) => {
                const message = err.status === 403
                    ? "You don't have permissions to edit caregiver compliance"
                    : "Could not create new compliance instance";
                toaster.pop('error', "Something went wrong", message);
                $scope.uploading = false;
            });
    }

    $scope.itemSelectEvents = {
        onItemSelect: function (item) {
            const currentGroup = $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems
                .find(group => group.groupItem.caregiverDocumentTypeId === $scope.currentSelecteCaregiverDocumentTypeId);
            if (!currentGroup) {
                return toaster.pop("error", "Something went wrong", "Can't set selected document type");
            }

            const child = currentGroup.groupItem.children.find(child => child.id === item.id);
            if (!child) {
                return toaster.pop("error", "Something went wrong", "Can't set selected document type");
            }
            currentGroup.selectedDocument = child;
            currentGroup.selectedDocument.complianceInstanceModel = complianceService.setSelectedDocumentModelInitialState(child);
            $scope.toggleGroupCollapse(currentGroup, true);
        },
        onDeselectAll() {
            const currentGroupIndex = $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems
                .findIndex(group => group.groupItem.caregiverDocumentTypeId === $scope.currentSelecteCaregiverDocumentTypeId);
            if (currentGroupIndex === -1) {
                return toaster.pop("error", "Something went wrong", "Can't deselect document");
            }
            $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems.splice(currentGroupIndex, 1);
        },
    };

    $scope.handleChildMultiSelectClick = (item) => {
        $scope.currentSelecteCaregiverDocumentTypeId = item.caregiverDocumentTypeId;

        // could happen if were not selected radio button to toggle current, and 
        // top item is with 'and' op
        if (!$scope.selectedTopItemDocId) {
            $scope.selectedTopItemDocId = item.parent.caregiverDocumentTypeId;
            $scope.groupItemsMap[$scope.selectedTopItemDocId] = {
                topItem: item.parent,
                groupItems: [{
                    groupItem: item,
                    selectedDocument: null
                }]
            }
        } else {
            const group = $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems.find(group => group.groupItem === item);
            if (!group) {
                $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems.push({
                    groupItem: item,
                    selectedDocument: null
                });
            }
        }
    };

    $scope.handleComplianceChildRadioClickOfSingleParent = (item) => {
        $scope.selectedTopItemDocId = item.caregiverDocumentTypeId;

        const selectedDocument = item;
        selectedDocument.complianceInstanceModel = complianceService.setSelectedDocumentModelInitialState(selectedDocument);

        if (!$scope.groupItemsMap[$scope.selectedTopItemDocId]) {
            $scope.groupItemsMap[$scope.selectedTopItemDocId] = {
                topItem: item,
                groupItems: [{
                    groupItem: item,
                    selectedDocument: selectedDocument,
                    displySectionItem: true
                }]
            }
        } else {
            $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems[0].selectedDocument = selectedDocument;
        }
    };

    $scope.handleComplianceChildRadioClick = (item) => {
        $scope.selectedTopItemDocId = item.caregiverDocumentTypeId;
        if (!$scope.groupItemsMap[$scope.selectedTopItemDocId]) {
            $scope.groupItemsMap[$scope.selectedTopItemDocId] = {
                topItem: item,
                groupItems: []
            }
        }
        setSelectedItemActiveState($scope.complianceItem, item);
    };

    $scope.toggleGroupCollapse = (toggledGroup, enforceDisplay) => {
        $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems.forEach(group => {
            if (group.groupItem.caregiverDocumentTypeId !== toggledGroup.groupItem.caregiverDocumentTypeId && group.displySectionItem) {
                group.displySectionItem = false;
            }
        });
        if (enforceDisplay) {
            toggledGroup.displySectionItem = true;
        } else {
            toggledGroup.displySectionItem = !toggledGroup.displySectionItem;
        }
    };

    $scope.submitForm = async function () {
        if (Object.keys($scope.groupItemsMap).length === 0) {
            return toaster.pop('warning', "No items selected");
        }

        const newDocuments = [];

        const groupItems = $scope.groupItemsMap[$scope.selectedTopItemDocId].groupItems;
        for(const group of groupItems) {
            const newDocument = group.selectedDocument.complianceInstanceModel;
            const fields = newDocument.fields ? newDocument.fields.map(complianceService.transformFieldBeforeRequest) : [];

            const params = {
                caregiverDocumentTypeId: newDocument.caregiverDocumentTypeId,
                isCompliant: newDocument.isCompliant,
                effectiveDate: LocalDate.from(nativeJs(moment(newDocument.effectiveDate))),
                expiryDate: newDocument.expiryDate ?
                    LocalDate.from(nativeJs(moment(newDocument.expiryDate))): undefined,
                fields: fields,
                caregiverDocumentUploadId: await handleFilesUpload(newDocument.caregiverDocumentTypeId, newDocument.files)
            }

            if (params.caregiverDocumentUploadId === null) {
                $scope.uploading = false;
                return toaster.pop('warning', "Failed to upload files for a document");
            }
            newDocuments.push(params);
        }

        createComplianceInstances(newDocuments);
    }

    $scope.closeModal = (res) => {
        if (res) {
            $uibModalInstance.close(res);
        } else {
            $uibModalInstance.dismiss();
        }
    };

    init();
};