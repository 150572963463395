import { PatientId, VisitInstanceId } from "../messages/ids";
import { AgencyNoteEditParams } from "../messages/note";
import { VisitInstancesSaveResponseWithAssignIncreaseOvertime } from "../messages/visit";
import { BulkEditVisitInstanceRequest, VisitInstanceEditParams } from "../messages/visit_instance";
import { MissedVisitService } from "../services/missedVisitService";

interface NoteValidations {
    isPredefinedValid: boolean | undefined,
    isMessageValid: boolean | undefined
}

interface MissedVisitsSideBarBindings {    
    changeState: (newState: string | undefined) => void;
}

interface MissedVisitsSideBarOptions extends angular.IComponentOptions {
    bindings: Record<keyof MissedVisitsSideBarBindings, string>;
}

interface ModalState {
    selectedOption: {id?: number};
    missedVisitNote: AgencyNoteEditParams;
}

//! @ngInject
class MissedVisitSideBarCtrl implements ng.IComponentController, MissedVisitsSideBarBindings {
    changeState!: (newState: string | undefined) => void;
    private selectedVisits :any[];
    private patientId :PatientId;
    private editShiftsParams :any[];
    private isLoading: boolean;
    private isNoteRequired: boolean;
    private modalState: ModalState;
    private visitsPreview: string[] = [];
    private noteValidations: NoteValidations;
    private message;
    private isMissedVisitSideModalOpen :boolean;

    constructor(
        private missedVisitService: MissedVisitService,
        private toaster: toaster.IToasterService,        
        private $rootScope: ng.IRootScopeService,        
        private $filter: MFFilterService,
        private noteConsts: any,
        private entityNoteService: any,
        private entityNewVisitModalService: any,
        private VisitInstanceModalService: any,
        private generalUtils: any
    ) { 
        this.isNoteRequired = this.entityNoteService.isEntityNoteRequired(this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
        this.noteValidations = {
            isPredefinedValid: undefined,
            isMessageValid: undefined
        };
        this.modalState = {
            selectedOption: { id: undefined },
            missedVisitNote: {
                status: "",
                noteType: "missed_visit_instance",
            },
        }
        this.isLoading = false;
        this.message = "";
        this.isMissedVisitSideModalOpen = true;
        this.selectedVisits = this.entityNewVisitModalService.missedVisitsData.selectedVisits;
        this.patientId = this.entityNewVisitModalService.missedVisitsData.patientId;
        this.editShiftsParams = this.entityNewVisitModalService.missedVisitsData.editShiftsParams;

        this.entityNewVisitModalService.registerObserverCallback(
            "visits",
            "missedVisitSideBar",
            this.getSelectedVisits
        );

        this.updateVisitsPreview();
    }

    loadItems = () => {
        this.isLoading = true;
        const body = this.getMissedVisitsBody(this.selectedVisits);
        this.missedVisitService.missVisits(body).then((res :VisitInstancesSaveResponseWithAssignIncreaseOvertime) => {
            const skippedVisitsData = res.skippedVisitsAndMissingPermissions;
            if (!skippedVisitsData) {
                this.toaster.pop("success", "Selected visits succesfully missed");
            } else {
                this.VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: skippedVisitsData,
                    updatedVisitsAmount: res.visitInstances.length,
                    action: "update"
                });
            }
            this.$rootScope.$emit("refresh_visits");
            this.closeParentModal();
            this.generalUtils.scrollToElement('scroll-calendar');
        }, () => {
            this.toaster.pop("error", "Something went wrong", "Cannot set selected visits as missed");
        }).finally(() => {
            this.isLoading = false;
        });
    }

    getSelectedVisits = () => {
        this.selectedVisits = this.entityNewVisitModalService.selectedItems.visits;
        if (this.isMissedVisitSideModalOpen &&
            this.selectedVisits.length === 0) {
            this.closeParentModal();
        } else {
            this.updateVisitsPreview();
        }
    }

    updateVisitsPreview = () => {
        this.visitsPreview = this.selectedVisits.map((visit, index) => {
            const startDateAndTime = this.$filter("mfShortTime")(visit.startTime, ['withDate']);
            const endTime = this.$filter("mfShortTime")(visit.endTime);
            return `${index + 1}. ${startDateAndTime} - ${endTime}`;
        });

        // If more than one visit selected adjust the message accordingly
        this.message = this.selectedVisits.length === 1 ? `Are you sure you want to miss the selected visit?` :
            `Are you sure you want to miss the following ${this.selectedVisits.length} selected visits?`;
    }

    getMissedVisitsBody = (visits :any) => {
        const ids :VisitInstanceId[] = visits.map((visit :any) => visit.visitInstanceId);
        const changes :VisitInstanceEditParams = {
            "missedVisit": true,            
            "missedVisitNote": this.entityNoteService.buildEntityNoteRequest(
                this.modalState.missedVisitNote,
                this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE,
            )
            ,
            "manualHoldInvoicingNotes": null,
            "travelTimeSeconds": 0
        }
        const body :BulkEditVisitInstanceRequest = {
            ids,
            changes
        };

        return body;
    }

    closeParentModal = () => {
        this.changeState(undefined);
        this.isMissedVisitSideModalOpen = false;
        this.$rootScope.$emit("close_missed_visit_modal");
    };

    onClickMiss = () => {
        const { isNoteValid, isPredefinedValid, isMessageValid } = this.entityNoteService.validateEntityNote(
            this.modalState.missedVisitNote,
            this.noteConsts.NoteTypes.MISSED_VISIT_INSTANCE
        );
        if (!isNoteValid) {
            this.noteValidations = { isPredefinedValid, isMessageValid };
            return;
        }

        this.loadItems();
    }

    handleNoteChanges = (updatedNote :any) => {
        this.modalState.missedVisitNote = updatedNote;
    };

    $onDestroy = () => {
        this.entityNewVisitModalService.unregisterObserverCallback("visits", "MissedVisitSideBarCtrl");
    }
}

export const MissedVisitSideBar: MissedVisitsSideBarOptions = {
    controller: MissedVisitSideBarCtrl,
    controllerAs: "ctrl",
    templateUrl: "admin/views/missed-visit-side-bar.html",
    bindings: {        
        changeState: "&"
    },
};

