import { Payer, Payers } from "../messages/payer";
import { Api } from "./Api";
import { Endpoint } from "./endpoint.service";

//! @ngInject
 export class PayerService {
    payers: Payer[] | undefined = undefined;

    constructor(
      private api: Api,
      private endpoint: Endpoint,
      private $rootScope: ng.IRootScopeService
    ) {}

    /**
     * Get a list of agency payers
     */
    async getPayers(params: {onlyActives: boolean, refetch: boolean} = {
      onlyActives: false,
      refetch: false
    }) {
      const url = this.endpoint({
        path: "agencies/:agencyId/payers",
        params: {
          agencyId: this.$rootScope.agencyId
        },
      });

      if (!params.refetch && this.payers !== undefined){
        return params.onlyActives ? this.payers.filter(row => row.isActive) : this.payers;
      }

      return this.api.get<Payers>(url).then((res) => {
        this.payers = res.data.payers;
        return params.onlyActives ? this.payers.filter(row => row.isActive) : this.payers;
      });
    }

  }
