export const patientPlanOfCare = {
  templateUrl: "admin/views/patient-plan-of-care.html",
  bindings: {
    patient: "<",
    planOfCare: "<",
    showViewHistoryButton: "<",
    canEditPlanOfCare: "<",
    canCreateNewPlanOfCare: "<",
    onClickSendEmail: "&",
},
  //! @ngInject
  controller: function ($scope, $rootScope, $uibModal, DatabaseApi, toaster, NgTableParams) {
    let patient;
    let planOfCare;

    this.$onChanges = () => {
      planOfCare = mapPlanOfCare($scope.$ctrl.planOfCare);
      patient = $scope.$ctrl.patient; 

      $scope.onClickNewPlanOfCare = handleClickNewPlanOfCare;
      $scope.onClickOpenHistoryModal = handleClickOpenHistoryModal;
      $scope.onClickViewPdf = handleClickViewPdf;
      $scope.canEditPlanOfCare = $scope.$ctrl.canEditPlanOfCare;
      $scope.canCreateNewPlanOfCare = $scope.$ctrl.canCreateNewPlanOfCare;
      $scope.canViewHistoryButton = $scope.$ctrl.showViewHistoryButton;
      $scope.onClickSendEmail = $scope.$ctrl.onClickSendEmail;
      $scope.planOfCare = planOfCare;
      $scope.checked = true;

      initTable($scope.planOfCare.pocItemWithAnswerList);
    };

    function handleClickNewPlanOfCare() {
      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/new-plan-of-care-modal.html",
        size: "lg",
        controller: "newPlanOfCareModalCtrl",
        resolve: {
          planOfCare: () => planOfCare,
          patientId: () => patient.id,
          patientCurrentOfficeId: () => patient.currentOfficeId
        },
      });

      modalInstance.result.then((res) => {
        if (res === "success") {
          $rootScope.$emit("patient_poc_updated");
        }
      });
    }

    function handleClickOpenHistoryModal() {
      return $rootScope.openPatientPocHistoryModal($scope.$ctrl.patient);
    }

    function handleClickViewPdf() {
      const url =
                "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plan_of_care/:planOfCareId/generate_url"
                    .replace(":agencyId", $rootScope.agencyId)
                    .replace(":agencyMemberId", $rootScope.agencyMemberId)
                    .replace(":patientId", patient.id)
                    .replace(":planOfCareId", planOfCare.poc.id);

            $scope.isLoadingPlanOfCarePDF = true;
            DatabaseApi.get(url).then((res) => {
                const fileUrl = res.data.fileUrl;
                if (fileUrl !== null) {
                    return window.open(fileUrl, "_blank");
                } else {
                    return toaster.pop("warning", "Could'nt preview plan of care pdf");
                }
            }).catch(error => {
                toaster.pop("error", "Something went wrong", "Failed to fetch plan of care pdf");
            }).finally(() => $scope.isLoadingPlanOfCarePDF = false);
    }

    function initTable(items) {
      const oldTotal = $scope.tableParams?.total?.() || 0;
      const options = {
        count: 15,
      };

      let page = false,
        filters = undefined,
        sorting = undefined;

      if ($scope.tableParams) {
        options.count = $scope.tableParams.count();
        page = $scope.tableParams.page();
        filters = $scope.tableParams.filter();
        sorting = $scope.tableParams.sorting();
      }

      if (filters) {
        options.filter = filters;
      }

      $scope.tableParams = new NgTableParams(options, {
        dataset: items,
        sorting: sorting,
        counts: [15, 25, 50, 100],
      });

      if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
    }

    function mapPlanOfCare(planOfCare) {
      if (!planOfCare) {
        return;
      }

      let currentTitle = "";
      let pocItemWithAnswerList = [];

      const docItems = planOfCare.docItems.map((item) => {
        if (item.itemType === "bigHeader") {
          currentTitle = item.label;
          return item;
        }

        item.section = currentTitle;

        if (item.itemType === "check") {
          item.type = "Task";
          pocItemWithAnswerList.push(item);
        }

        return item;
      });

      pocItemWithAnswerList = pocItemWithAnswerList.map((item) => {
        item.everyVisit =
          !item.days &&
          !(item.frequencyPerWeek && item.visitPerWeek) &&
          item.isRequired;
        item.asNeeded =
          !item.isRequired &&
          !(item.frequencyPerWeek && item.visitPerWeek) &&
          !item.days;

        if (
          item.isRequired &&
          !item.days &&
          item.frequencyPerWeek &&
          item.visitPerWeek
        ) {
          item.frequency = `${item.frequencyPerWeek}/${item.visitPerWeek} days`;
        } else if (item.everyVisit) {
          item.frequency = "Every visit";
        }
        const pocItemWithCode = planOfCare.planOfCareItems.find(
          (x) => x.id === item.id
        );
        item.code =
          pocItemWithCode && pocItemWithCode.code ? pocItemWithCode.code : "";

        return item;
      });

      return {
        ...planOfCare,
        docItems,
        pocItemWithAnswerList,
      };
    }
  },
};
