/**
 * ORIGINAL FILE = officesService.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */

import { AgencyId, AgencyMemberId, OfficeId } from "../messages/ids";
import {
  AssocAgencyMembersParams,
  AssocCaregiversParams,
  OfficeCreateParams,
  OfficeEditParams,
  OfficeExtended,
} from "../messages/office";
import { Api as Api } from "./Api";
import { Endpoint } from "./endpoint.service";

/*! @ngInject */
export class OfficesService {
  offices: OfficeExtended[] | undefined = undefined;

  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) {}

  /**
   * Get a list of agency offices
   */
  async getOffices(params: {onlyActives: boolean, refetch: boolean} = {
    onlyActives: false,
    refetch: false
  }) {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/offices",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    if (!params.refetch && this.offices !== undefined){
      return params.onlyActives ? this.offices.filter(row => row.active) : this.offices;
    }

    return this.api.get<{ offices: OfficeExtended[] }>(url).then((res) => {
      this.offices = res.data.offices;
      return params.onlyActives ? this.offices.filter(row => row.active) : this.offices;
    });
  }

  /**
   * Update agency members offices list
   */
  updateAgencyMembersOffices = (
    agencyId: AgencyId,
    agencyMemberId: AgencyMemberId,
    data: AssocAgencyMembersParams
  ) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/offices/agency_member/:agencyMemberId/assoc_agency_members",
      params: {
        agencyId: agencyId,
        agencyMemberId: agencyMemberId,
      },
    });

    return this.api.put(url, data);
  };

  /**
   * Update caregivers offices list
   */
  updateCaregiversOffices = (
    agencyId: AgencyId,
    agencyMemberId: AgencyMemberId,
    data: AssocCaregiversParams
  ) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/offices/agency_member/:agencyMemberId/assoc_caregivers",
      params: {
        agencyId: agencyId,
        agencyMemberId: agencyMemberId,
      },
    });

    return this.api.put(url, data);
  };

  /**
   * Insert office
   */
  insertOffice = (agencyId: AgencyId, agencyMemberId: AgencyMemberId, data: OfficeCreateParams) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/office",
      params: {
        agencyId: agencyId,
        agencyMemberId: agencyMemberId,
      },
    });

    return this.api.post<OfficeExtended>(url, data).then(({ data }) =>
      this.offices?.push(data)
    );
  };

  /**
   * Update office
   */
  updateOffice = (
    agencyId: AgencyId,
    agencyMemberId: AgencyMemberId,
    officeId: OfficeId,
    data: OfficeEditParams
  ) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/office/:officeId",
      params: {
        agencyId: agencyId,
        agencyMemberId: agencyMemberId,
        officeId: officeId,
      },
    });

    return this.api.put<OfficeExtended>(url, data).then(({ data }) => {
      this.offices = this.offices?.map((office) => (office.id === officeId ? data : office));
    });
  };
}
