/*! @ngInject */
export class Api {
  constructor(private $http: ng.IHttpService, private Consts: any) {}

  get = <Data>(route: string) => {
    return this.$http.get<Data>(this.Consts.api + route);
  };

  post = <Body>(route: string, body?: unknown) => {
    return this.$http.post<Body>(this.Consts.api + route, body);
  };

  put = <Body>(route: string, body?: unknown) => {
    return this.$http.put<Body>(this.Consts.api + route, body);
  };

  patch = <Body>(route: string, body?: unknown) => {
    return this.$http.patch<Body>(this.Consts.api + route, body);
  };

  delete = <Body>(route: string) => {
    return this.$http.delete<Body>(this.Consts.api + route);
  };
}
