import { LocalDate, LocalDateTime } from "js-joda";
import { AgencyPatientDocument, PatientDocumentService } from "../services/patientDocumentService";

interface PatientDocumentDropdownButtonBindings {
  data: AgencyPatientDocument;
  onRefresh: () => void;
}

interface PatientDocumentDropdownComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof PatientDocumentDropdownButtonBindings, string>;
}

//! @ngInject
class PatientDocumentDropdownButtonCtrl
  implements ng.IComponentController, PatientDocumentDropdownButtonBindings
{
  constructor(
    private $window: Window,
    private patientDocumentService: PatientDocumentService,
    private toaster: toaster.IToasterService,
    private $uibModal: ng.ui.bootstrap.IModalService,
    private $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService,
    private mfModal: any
  ) {}

  data!: AgencyPatientDocument;
  onRefresh!: () => void;

  canViewPdf = () => {
    return this.data.document.hasFile;
  };

  checkIfCustomDocumentScanned = () => {
    return (
      this.data.document.title !== null &&
      this.data.document.type === null &&
      this.data.document.isScanned
    );
  };

  viewPDF = () => {
    if (this.canViewPdf() && this.data.document.id !== null) {
      this.patientDocumentService
        .getDocumentPdfUrl(this.data.document.id)
        .then((url) => {
          if (url !== null) {
            this.$window.open(url);
          } else {
            this.toaster.pop("warning", "Missing file", "No PDF file");
          }
        })
        .catch(() => {
          this.toaster.pop("error", "Something went wrong", "Could not get document file URL");
        });
    }
  };

  sendDocumentPDFEmail = () => {
    this.$uibModal.open({
      templateUrl: "admin/views/email-modal.html",
      size: "lg",
      resolve: {
        documents: () => [this.getTransformedDocument()],
        patientId: () => this.data.patient.id,
        patientFullName: () => this.data.patient.name,
        docsType: () =>
          this.data.document.type?.title === "planOfCare" ? "planOfCare" : "patient",
        onSuccess: () => () => {
          this.data.document.status = "SENT";
          this.data.document.wasSentViaEmail = true;
          this.data.document.latestSentOutDate = LocalDate.now();
        },
      },
      controller: "emailModalCtrl",
    });
  };

  sendDocumentPDFEfax = () => {
    this.$uibModal.open({
      templateUrl: "admin/views/fax-modal.html",
      size: "lg",
      resolve: {
        documents: () => [this.getTransformedDocument()],
        patientId: () => this.data.patient.id,
        onSuccess: () => () => {
          this.data.document.status = "SENT";
          this.data.document.wasSentViaFax = true;
          this.data.document.latestSentOutDate = LocalDate.now();
        },
      },
      controller: "faxModalCtrl",
    });
  };

  reviewForm = () => {
    this.patientDocumentService
      .getEditPatientDocModalData(this.data)
      .then((data) => {
        this.$uibModal
          .open({
            templateUrl: "admin/views/edit-patient-doc-modal.html",
            size: "lg",
            controller: "editPatientDocModalCtrl",
            resolve: {
              patientId: data.patientId,
              document: data.document,
              documentReplaceConstants: () => () =>
                Promise.resolve({
                  caregiverName: this.data.assignedNurse.name,
                  patientName: this.data.patient.name,
                  agencyName: this.$rootScope.user.agency.name,
                  agencyAddress: this.$rootScope.user.agency.address,
                  agencyPhone: this.$rootScope.user.agency.officePhone,
                }),
              onSuccessSubmit: () => this.onRefresh(),
            },
          })
          .result.then(
            (data: { fileUrl?: string; date?: string }) => {
              if (data.fileUrl !== undefined && data.date !== undefined) {
                this.data.document.submittedAt = LocalDateTime.parse(data.date.split('Z')[0]);
                this.data.document.hasFile = Boolean(data.fileUrl);
              }
            },
            () => {
              this.onRefresh();
            }
          );
      })
      .catch(() => this.toaster.pop("error", "Something went wrong"));
  };

  reopenSubmission = () => {
    if (this.data.document.id === null) {
      return;
    }

    this.patientDocumentService
      .openSubmission(this.data.document.id)
      .then(() => {
        this.data.document.submittedAt = null;
        this.data.document.hasFile = false;
        this.data.document.isOpenForResubmission = true;
        this.data.document.status = "OPEN_FOR_RESUBMISSION";
      })
      .catch(() => {
        this.toaster.pop("error", "Something went wrong", "Could not reopen for submission");
      });
  };

  submitManually = () => {
    if (this.data.document.visitInstanceId === null) {
      return;
    }

    const url = this.patientDocumentService.getSubmitManuallyEndpointURL(
      this.data.document.visitInstanceId
    );

    if (url === undefined) {
      return;
    }

    this.$rootScope.openUploadDocumentPdfModal({
      url: url,
      document: this.getTransformedDocument(),
      successCallback: (result) => {
        if (result !== null) {
          this.data.document.submittedAt = LocalDateTime.now();
          this.data.document.hasFile = Boolean(result.data.url);
          this.data.document.status = "COMPLETED";
        }
      },
    });
  };

  markAsSubmitted = () => {
    if (this.data.document.visitInstanceId === null || this.data.document.type === null) {
      return;
    }

    this.patientDocumentService
      .markAsSubmitted(
        this.data.document.visitInstanceId,
        this.data.document.type.id,
        this.data.document.versionId
      )
      .then((res) => {
        this.toaster.pop("success", "Manual submission successful");
        this.data.document.submittedAt = LocalDateTime.now();
        this.data.document.hasFile = false;
        this.data.document.id = res.data.id;
      })
      .catch(() => void null);
  };

  markAsSignedByPhysician = () => {
    this.$uibModal.open({
      templateUrl: "admin/views/sign-physician-document-modal.html",
      controller: "signPhysicianDocumentModalCtrl",
      size: "sm",
      windowClass: "center-center",
      resolve: {
        document: () => this.getTransformedDocument(),
        onSuccess: () => (data: { physicianSignDate: string }) => {
          this.data.document.signedDate = LocalDate.parse(data.physicianSignDate);
          this.data.document.status = "SIGNED";
        },
      },
    });
  };

  goToVisit = () => {
    if (this.data.document.visitInstanceId === undefined) {
      return;
    }

    this.$state.go("app.visitDetailes", { id: this.data.document.visitInstanceId });
  };

  handleConfirmRemoveVisitScheduledDocument = () => {
    if (this.data.document.id === null) {
      console.error("Can not delete a document without scheduled document id");
      return;
    }

    this.patientDocumentService
      .deletePatientVisitDocument(this.data.document.patient.id, this.data.document.id)
      .then(() => {
        this.toaster.pop("success", "Document successfully deleted");
        this.onRefresh();
      })
      .catch(() => {
        this.toaster.pop("error", "Something went wrong", "Failed to delete document");
      });
  };

  onClickRemovePatientScheduledDocument = () => {
    if (this.data.document.id === null) {
      if (this.data.document.type === null) {
        console.error("Can not find document title");
        return;
      }

      const modal = this.mfModal.create({
        subject: `Delete ${this.data.document.type.title} Document`,
        message:
          "This visit document hasn't been filled yet, in order to delete pre-filled documents, delete it by editing the visit via the patient calendar.",
        variant: "info",
        confirmLabel: "OK",
        hideCancelButton: true,
        onConfirm: () => modal.close(),
      });
      return;
    }

    const modal = this.mfModal.create({
      subject: `Delete ${this.data.document.type!.title} Document`,
      message: "Are you sure you want to delete this document from the visit?",
      variant: "danger",
      confirmLabel: "Confirm",
      cancelLabel: "Cancel",
      onConfirm: () => modal.close() && this.handleConfirmRemoveVisitScheduledDocument(),
    });
  };

  private getTransformedDocument() {
    return this.patientDocumentService.parseDocument(this.data);
  }
}

export const PatientDocumentDropdownButton: PatientDocumentDropdownComponentOptions = {
  controller: PatientDocumentDropdownButtonCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/patient-document-dropdown-button.html",
  bindings: {
    data: "<",
    onRefresh: "&",
  },
};
