import { LocalDate, DateTimeFormatter } from "js-joda";
import moment from "moment";

//! @ngInject
export function clockedVisitsCtrl(
    $scope,
    NgTableParams,
    itemSearchPageManager,
    toaster,
    $rootScope,
    $filter,
    DatabaseApi,
    generalUtils
  ) {
    $scope.caregivers = DatabaseApi.caregivers();
    $scope.patients = DatabaseApi.patients();
    const agencyBranches = DatabaseApi.agencyBranches() || [];
    $scope.agencyBranches = agencyBranches.map((branch) => ({
      id: branch.id,
      label: branch.name,
    }));
    $scope.contractTypes = DatabaseApi.contractTypes();
    $scope.coordinators = [];

    $scope.tableParams = null;
    $scope.clockMethods = [
      { id: "ALL", label: "All" },
      { id: "MOBILE", label: "Mobile" },
      { id: "TEL", label: "Tel" },
      { id: "IVR", label: "IVR" },
      { id: "MANUAL", label: "Manual" },
      { id: "TIME_SHEET", label: "Time Sheet" },
    ];
    $scope.filters = {
      clockMethod: "ALL",
      incompleteVisits: false,
      evvErrorSubmission: false,
      missedVisits: false,
      agencyBranches: [],
      contracts: [],
      offices: [],
      coordinators: [],
      teams: [],
    };
    $scope.globalFilter = { val: "" };

    $scope.filteredVisits = [];

    $scope.tableColumns = {
      VisitDate: true,
      VisitTime: true,
      Patient: true,
      Caregiver: true,
      ClockIn: true,
      ClockOut: true,
      ClockInType: true,
      ClockOutType: true,
      ClockInLocation: true,
      ClockOutLocation: true,
      TimelyFiling: true
    };

    $scope.multiSelectComponentOptions = {
      styleActive: true,
      scrollable: true,
      scrollableHeight: "250px",
      enableSearch: true,
    };

    const evvClassesMap = {
      SUBMITTED: 'fa fa-check text-blue',
      FAILED: 'fa fa-times text-danger',
      PENDING: 'fa fa-clock-o',
    };

    const initialize = () => {
      initPageManager();
      $scope.loadItems();
      setTimeout(() => {
        initCoordinatorDropdown();
      }, 5000);
    };

    const initCoordinatorDropdown = () => {
      const currentAgencyMemberOfficeIds = $rootScope.user.agencyMember.officeIds;
      const agencyMembersByRoles = DatabaseApi.getAgencyMembersByRole() || {};
      $scope.coordinators = [
        ...(agencyMembersByRoles.coordinatorsSeniors || []),
        ...(agencyMembersByRoles.coordinators || []),
        ...(agencyMembersByRoles.admins || []),
      ]
        .sort(generalUtils.sortByDisplayName)
        .filter((coordinator) =>
          coordinator.officeIds.findIndex((coordinatorOfficeId) => {
            return currentAgencyMemberOfficeIds.includes(coordinatorOfficeId);
          }) !== -1
        );
    };

    const initPageManager = () => {
      $scope.pageManager = itemSearchPageManager.createSearchPageManager("/clocked_visits");

      $scope.pageManager.initFromToDateParams();
      $scope.pageManager.updateSearchParamValue("from", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
      $scope.pageManager.updateSearchParamValue("to", new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))));
      $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();
      $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
      $scope.coordinatorDataManager = $scope.pageManager.getCoordinatorDataManager({onlyActive: true});
      $scope.teamDataManager = $scope.pageManager.getTeamDataManager();
    };

    const populateTable = () => {
      const oldTotal = $scope.tableParams?.total?.() || 0;
      let sorting = { id: "desc" };
      let page = false;
      let count = 25;
      if ($scope.tableParams) {
        sorting = $scope.tableParams.sorting();
        page = $scope.tableParams.page();
        count = $scope.tableParams.count();
      }
      const options = {
        count: count,
        sorting: sorting,
      };

      const parseTellusEvvDetails = (item) => {
        const evvSubmissionDetails = item.evvSubmissionDetails;
        if (evvSubmissionDetails.errorType !== null) {
          item.evvStatus = "FAILED";
          item.evvClasses = evvClassesMap[item.evvStatus];
          if (evvSubmissionDetails.errors !== null) {
            item.evvText = "Sent On: " +
              $filter("date")(evvSubmissionDetails.sentAt, 'd MMM, yyyy') + " " +
              $filter("mfShortTime")(evvSubmissionDetails.sentAt) + " " +
              evvSubmissionDetails.errorType + ": " + evvSubmissionDetails.errors.join(',&#013;'); 
          }
        } else {
          item.evvStatus = "SUBMITTED";
          item.evvClasses = evvClassesMap[item.evvStatus];
          item.evvText = "Sent On: " +
          $filter("date")(evvSubmissionDetails.sentAt, 'd MMM, yyyy') + " " +
          $filter("mfShortTime")(evvSubmissionDetails.sentAt)
        }

        return item;
      };

      const parseEmednyEvvDetails = (item) => {
        const evvSubmissionDetails = item.evvSubmissionDetails;
        if (evvSubmissionDetails.submitted === true) {
          item.evvStatus = 'SUBMITTED';
          item.evvClasses = evvClassesMap[item.evvStatus];
          item.evvText = "Sent On: " +
            $filter("date")(evvSubmissionDetails.processedAt, 'd MMM, yyyy') + " " +
            $filter("mfShortTime")(evvSubmissionDetails.processedAt) + " " +
            " Transaction Id: " + evvSubmissionDetails.transactionId;
        } else {
          // evvSubmissionDetails.submitted === false
          item.evvStatus = 'FAILED';
          item.evvClasses = evvClassesMap[item.evvStatus];
          if (evvSubmissionDetails.processedAt && evvSubmissionDetails.errorType) {
            item.evvText = "Sent On: " +
              $filter("date")(evvSubmissionDetails.processedAt, 'd MMM, yyyy') + " " +
              $filter("mfShortTime")(evvSubmissionDetails.processedAt) + " " +
              evvSubmissionDetails.errorType + ": " + evvSubmissionDetails.errors.join(',&#013;');
          }
        }

        return item;
      };

      $scope.filteredVisits.forEach((item) => {
        // set clock time bounds
        if (item.clockinTime) {
          item.isClockInTimeOverdue = isClockTimeOutOfBound({
            scheduledTime: item.startTime,
            clockTime: item.clockinTime,
            patientContractType: item.patientContractType,
            clockType: "IN"
          });
        }
        if (item.clockoutTime) {
          item.isClockOutTimeOverdue = isClockTimeOutOfBound({
            scheduledTime: item.endTime,
            clockTime: item.clockoutTime,
            patientContractType: item.patientContractType,
            clockType: "OUT"
          });
        }

        // emedny text
        switch (item.evvSubmissionDetails?.type) {
            case "EmedNy": {
              item = parseEmednyEvvDetails(item);
              break;
            }
            case "Tellus": {
              item = parseTellusEvvDetails(item)
              break;
            }
            default: {
              item.evvStatus = 'PENDING';
              item.evvClasses = evvClassesMap[item.evvStatus];
              item.evvText = "Pending submission";
            }
        }
      });

      $scope.tableParams = new NgTableParams(options, {
        dataset: $scope.searchText
          ? $filter("filter")($scope.filteredVisits, $scope.searchText)
          : $scope.filteredVisits,
      });
      if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
      if ($scope.globalFilter) {
        $scope.applyGlobalSearch($scope.globalFilter.val);
      }
    };

    $scope.loadItems = () => {
      $scope.pageManager.executeSearch().then((res) => {
          $scope.clockedVisits = setIncomingClockedVisits(res.data.visitInstances);
          $scope.onFiltersChange();
        },
        (err) => {
          toaster.pop("error", "Failed to load clocked visits");
        }
      );
    };

    $scope.onFiltersChange = () => {
      $scope.filteredVisits = $scope.clockedVisits;

      // set incomplete visits
      if ($scope.filters.incompleteVisits) {
        $scope.filteredVisits = $scope.clockedVisits.filter((visit) =>
          visit.clockinTime === null || visit.clockoutTime === null
        );
      }

      // Evv submission
      if ($scope.filters.evvErrorSubmission) {
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.evvSubmissionDetails !== null &&
            (visit.evvSubmissionDetails?.type === "EmedNy" && !visit.evvSubmissionDetails?.submitted) ||
            (visit.evvSubmissionDetails?.type === "Tellus" && visit.evvSubmissionDetails?.errorType !== null)
        );
      }

      // Missed visits
      if ($scope.filters.missedVisits) {
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.clockinTime === null && new Date(visit.startTime).valueOf() < new Date().valueOf()
        );
      }

      // Contracts
      if ($scope.filters.contracts.length) {
        const selectedContractIds = $scope.filters.contracts.map(contract => contract.id);
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.patientContractType !== null &&
          selectedContractIds.indexOf(visit.patientContractType) !== -1
        );
      }

      // Offices
      if ($scope.filters.offices.length) {
        const selectedOfficeIds = $scope.filters.offices.map(office => office.id);
        $scope.filteredVisits = $scope.filteredVisits.filter(
          (visit) =>
            visit.officeId !== null &&
            selectedOfficeIds.indexOf(visit.officeId) !== -1
        );
      }

      // Branches
      if ($scope.filters.agencyBranches.length) {
        const selectedBranchIds = $scope.filters.agencyBranches.map(branch => branch.id);
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.caregiverBranches !== null &&
          selectedBranchIds.find((branchId) => visit.caregiverBranches.indexOf(branchId) !== -1) !== undefined
        );
      }

      // Coordinators
      if ($scope.filters.coordinators.length) {
        const selectedCoordinatorIds = $scope.filters.coordinators.map(c => c.id);
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.assignedCoordinator !== null &&
          selectedCoordinatorIds.indexOf(visit.assignedCoordinator) !== -1
        );
      }

      // Teams
      if ($scope.filters.teams.length) {
        const selectedTeamIds = $scope.filters.teams.map((team) => team.id);
        $scope.filteredVisits = $scope.filteredVisits.filter((visit) =>
          visit.patientAgencyTeam !== null &&
          selectedTeamIds.indexOf(visit.patientAgencyTeam) !== -1
        );
      }

      // clock method
      switch ($scope.filters.clockMethod) {
        case "ALL": {
          populateTable();
          break;
        }
        default: {
          $scope.filteredVisits = $scope.filteredVisits.filter((visit) => {
            return (
              (visit.clockinType === $scope.filters.clockMethod &&
                visit.clockoutType === $scope.filters.clockMethod) ||
              (visit.clockinType === $scope.filters.clockMethod &&
                visit.clockoutType === null) ||
              (visit.clockoutType === $scope.filters.clockMethod &&
                visit.clockinType === null)
            );
          });
          populateTable();
        }
      }
    };

    $scope.exportTable = () => {
      // if (!$scope.table || !$scope.table.data || !$scope.table.data.length) return;
      var tableData = $scope.filteredVisits;

      if (tableData.length <= 0) {
        toaster.pop("warning", "Nothing to export", "The table is empty");
        return;
      }

      var rows = [];
      var titles = [
        "Visit date",
        "Visit time",
        "Patient",
        "Caregiver",
        "Clock In",
        "Clock In notes",
        "Clock Out",
        "Clock Out notes",
        "Clock In type",
        "Clock Out type",
      ];

      rows.push(titles);

      tableData.forEach((visit) => {
        var row = [];
        titles.forEach((title) => {
          if (title === "Visit date") row.push($filter("mfShortDate")(visit.startTime) || "");
          else if (title === "Visit time") row.push($filter("mfShortTime")(visit.startTime) + "-" + $filter("mfShortTime")(visit.endTime));
          else if (title === "Patient") row.push(visit.patientFullName || "");
          else if (title === "Caregiver") row.push(visit.caregiverFullName || "");
          else if (title === "Clock In") row.push($filter("mfShortTime")(visit.clockinTime) || "");
          else if (title === "Clock In notes") row.push(visit.isClockInTimeOverdue ? "Clock in time is too far from schedule" : "")
          else if (title === "Clock Out") row.push($filter("mfShortTime")(visit.clockoutTime) || "");
          else if (title === "Clock Out notes") row.push(visit.isClockInTimeOverdue ? "Clock out time is too far from schedule" : "")
          else if (title === "Clock In type") row.push(visit.clockinType || "");
          else if (title === "Clock Out type") row.push(visit.clockoutType || "");
        });
        rows.push(row);
      });

      var csvContent = "data:text/csv;charset=utf-8,";
      rows.forEach((rowArray) => {
        var row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", getExportedFileName());
      document.body.appendChild(link); // Required for FF

      link.click();
    };

    const getExportedFileName = () => {
      const fromDate = $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd");
      const toDate = $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd");
      return `medflyt-clocked-visits-export-${fromDate}-to-${toDate}.csv`;
    };

    const isClockTimeOutOfBound = ({scheduledTime, clockTime, patientContractType, clockType}) => {
      let schedule = moment(scheduledTime);
      let checked = moment(clockTime);

      const contractType = $scope.contractTypes.find(c => c.id === patientContractType);
      const clockTimeDiff = clockType === "IN" ?
        (contractType?.clockInToleranceMinutes ?? 15) :
        (contractType?.clockOutToleranceMinutes ?? 15);

      const minutesDiff = Math.abs(checked.diff(schedule, "minutes"));
      return minutesDiff > clockTimeDiff;
    };

    const setIncomingClockedVisits = (items) => {
      items.forEach((visit) => {
        if (visit.clockinType !== null && visit.clockinTime === null) {
          visit.clockinType = null;
        }
        if (visit.clockoutType !== null && visit.clockoutTime === null) {
          visit.clockoutType = null;
        }
      });

      return items;
    };

    $scope.applyGlobalSearch = () => {
      const filter = { $: $scope.globalFilter.val };
      if ($scope.tableParams) {
        angular.extend($scope.tableParams.filter(), filter);
      }
    };

    $scope.multiSelectEvents = {
      onSelectionChanged() {
        $scope.onFiltersChange();
      },
    };

    $scope.$on("refresh_visits", $scope.loadItems);

    $rootScope.$on("got_data", () => {
      const agencyBranches = DatabaseApi.agencyBranches() || [];
      $scope.agencyBranches = agencyBranches.map((branch) => ({
        id: branch.id,
        label: branch.name,
      }));
    });

    $rootScope.$on("got_caregivers_data", (event) => {
      $scope.caregivers = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", (event) => {
      $scope.patients = DatabaseApi.patients();
    });

    $rootScope.$on("got_contract_types", () => {
      $scope.contractTypes = DatabaseApi.contractTypes();
    });

    initialize();
  };
