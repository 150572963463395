import { LocalDate, convert, nativeJs } from "js-joda";
import moment from "moment";

//! @ngInject
export function newPlanOfCareModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster, apiErrors) {
    function initialize() {

        const resolvedStartDate = ($scope.$resolve.planOfCare && $scope.$resolve.planOfCare.poc && $scope.$resolve.planOfCare.poc.startDate) || $scope.$resolve.startDate;
        const resolvedEndDate = ($scope.$resolve.planOfCare && $scope.$resolve.planOfCare.poc && $scope.$resolve.planOfCare.poc.endDate) || $scope.$resolve.endDate;

        $scope.form = {
            startDate: {
                value: resolvedStartDate ? convert(LocalDate.parse(resolvedStartDate)).toDate() : (resolvedEndDate ? convert(LocalDate.parse(resolvedEndDate ?? resolvedStartDate).plusDays(1)).toDate() : undefined),
                isOpen: false,
            },
            endDate: {
                value: resolvedEndDate ? convert(LocalDate.parse(resolvedEndDate)).toDate() : undefined,
                isOpen: false,
            }
        };

        $scope.altInputFormats = ['MM/dd/yyyy'];
        $scope.isNew = $scope.$resolve.planOfCare === undefined;
        $scope.days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
        $scope.fullDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
        $scope.defaultSelectedDays = {};
        $scope.days.forEach(day => $scope.defaultSelectedDays[day] = false);
        $scope.frequencyOptions = [];
        for (let i = 1; i <=7; i++) {
            $scope.frequencyOptions.push({ id: i, label: i });
        }
        $scope.singleSelectionExtraSettings = {
            styleActive: true,
            singleSelection: true,
            selectionLimit: 1,
            smartButtonMaxItems: 1,
            closeOnSelect: true,
            showCheckAll: false,
            showUncheckAll: false
        };

        $scope.plansOfCare = DatabaseApi.plansOfCare();
        if ($scope.plansOfCare && $scope.plansOfCare.length > 0) {
            $scope.mapPlansOfCare();
        }
    }

    $scope.mapPlansOfCare = () => {
        $scope.officePlanOfCare = $scope.plansOfCare.find(poc => poc.officeId === $scope.$resolve.patientCurrentOfficeId);
        if ($scope.officePlanOfCare === undefined) {
            return toaster.pop('error', 'Oops! Something went wrong', 'Patient office does not have a plan of care');
        }
        $scope.items = $scope.officePlanOfCare.columns.filter(item => item.type === "Task").map(item => {
            let isShown = false;
            if ($scope.$resolve.itemsFromHHAXIntegration !== undefined && $scope.$resolve.itemsFromHHAXIntegration.find(pocItemId => pocItemId === item.id) !== undefined) {
                isShown = true;
            }
            
            let itemWithAnswer = undefined;
            
            if (!$scope.isNew) {
                itemWithAnswer = $scope.$resolve.planOfCare.pocItemWithAnswerList.find(x => x.id === item.id);
            }
            if (itemWithAnswer !== undefined) {
                const whenToPerform = getWhenToPerform(itemWithAnswer);
                let daysArray = [];
                if (whenToPerform === "everyVisit") {
                    daysArray = [];
                } else if (itemWithAnswer && itemWithAnswer.days) {
                    daysArray = itemWithAnswer.days.map(day => day[0].toUpperCase() + day[1].toLowerCase());
                }
                const selectedDays = {};
                $scope.days.forEach(day => {
                    selectedDays[day] = daysArray.includes(day);
                });
                return {
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    section: item.section,
                    isShown: true,
                    whenToPerform: whenToPerform,
                    frequencyPerWeek: itemWithAnswer.frequencyPerWeek ? { id: itemWithAnswer.frequencyPerWeek } : {},
                    visitPerWeek: itemWithAnswer.visitPerWeek ? { id: itemWithAnswer.visitPerWeek } : {},
                    selectedDays: selectedDays,
                    notes: itemWithAnswer.notes ? itemWithAnswer.notes : ""
                };
            } else {
                return {
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    section: item.section,
                    isShown: isShown,
                    whenToPerform: undefined,
                    frequencyPerWeek: {},
                    visitPerWeek: {},
                    selectedDays: angular.copy($scope.defaultSelectedDays),
                    notes: ""
                };
            }
        });
    }

    $rootScope.$on("got_plan_of_care_type", function (event) {
        if ($scope.gotPlanOfCareSettings) return;
        $scope.gotPlanOfCareSettings = true;
        $scope.plansOfCare = DatabaseApi.plansOfCare();
        if ($scope.plansOfCare && $scope.plansOfCare.length > 0) {
            $scope.mapPlansOfCare();
        }
    });

    const getWhenToPerform = (itemWithAnswer) => {
        if (itemWithAnswer.type === "Task") {
            if (itemWithAnswer.everyVisit) {
                return "everyVisit";
            } else if (itemWithAnswer.asNeeded) {
                return "asNeeded";
            } else if (itemWithAnswer.frequencyPerWeek && itemWithAnswer.visitPerWeek) {
                return "frequencyVisitedInAWeek"
            }
            return "onDaysOfWeek";
        }
        return undefined;
    };

    $scope.chooseDayByItemId = (itemId, day) => {
        $scope.items = $scope.items.map(item => {
            if (item.id === itemId) {
                item.whenToPerform = "onDaysOfWeek";
                item.selectedDays[day] = !item.selectedDays[day];
            }
            return item;
        });
    };

    $scope.resetSelectedDaysOfItemById = (itemId) => {
        $scope.items = $scope.items.map(item => {
            if (item.id === itemId) {
                item.selectedDays = angular.copy($scope.defaultSelectedDays);
            }
            return item;
        });
    };

    $scope.validatePoc = () => {
        let errorMessage = "";
        const items = angular.copy($scope.items.filter(item => item.isShown));
        let i = 0;
        while (i < items.length && errorMessage === "") {
            const currentItem = items[i];
            if (currentItem.whenToPerform === "frequencyVisitedInAWeek") {
                if (!currentItem.frequencyPerWeek.id || !currentItem.visitPerWeek.id) {
                    errorMessage = "Missing frequency data for: " + currentItem.label;
                }
            }
            const selectedDaysAmount = Object.values(currentItem.selectedDays).filter(x => x === true).length;
            if (currentItem.type === "Task" && currentItem.whenToPerform === "onDaysOfWeek" && selectedDaysAmount === 0) {
                errorMessage = "Please choose when to perform the task: " + currentItem.label;
            }
            i++;
        }
        return errorMessage;
    };

    $scope.mapItemsToBody = () => {
        const sections = {};
        let sectionCounter = 9999;

        $scope.items.filter(item => item.isShown).forEach(item => {
            let section;
            if (sections[item.section] === undefined) {
                section = [];
                sections[item.section] = section;
            } else {
                section = sections[item.section];
            }

            if (item.type === "Task") {
                const task = {
                    id: item.id,
                    isRequired: item.whenToPerform !== "asNeeded",
                    itemType: "check",
                    label: item.label,
                    mainClass: "multiple",
                    parentId: null,
                    showIfParentEquals: null,
                    possibleAnswers: ["Done", "Add Notes"],
                    showOtherOption: false,
                    notes: item.notes ? item.notes : null,
                    title: "Multiple Select (check)",
                    columns: [
                        [
                            {
                                id: 1000 + item.id,
                                label: "Notes",
                                title: "Short Text",
                                columns: null,
                                itemType: "textShort",
                                parentId: item.id,
                                mainClass: "single",
                                isRequired: false,
                                parentType: "check",
                                ifParentEquals: "Add Notes",
                                showIfParentEquals: true
                            }
                        ]
                    ]
                };

                if (item.whenToPerform === "onDaysOfWeek") {
                    task.days = [];
                    $scope.fullDays.forEach(fullDay => {
                        if (item.selectedDays[fullDay[0].toUpperCase() + fullDay[1].toLowerCase()] === true) {
                            task.days.push(fullDay);
                        }
                    })
                }
                if (item.whenToPerform === "frequencyVisitedInAWeek") {
                    task.frequencyPerWeek = item.frequencyPerWeek.id;
                    task.visitPerWeek = item.visitPerWeek.id;
                }

                section.push(task);
            }
            // else if (item.type === "Attribute") {
            //     section.push({
            //         id: item.id,
            //         itemType: "smallHeader",
            //         label: item.label,
            //         notes: item.notes ? item.notes : null,
            //         mainClass: "noQuestion",
            //         parentId: null,
            //         showIfParentEquals: null,
            //         title: "Sub Header"
            //     });
            // }
        });

        const result = [];
        Object.keys(sections).forEach((key) => {
            const section = sections[key];
            result.push({
                id: sectionCounter++,
                itemType: "bigHeader",
                label: key,
                mainClass: "noQuestion",
                title: "Main Header",
                showIfParentEquals: null,
                parentId: null
            },
                ...section);
        });

        return result;
    };

    const dateToLocalDate = (date) => {
        if (date) {
            return LocalDate.from(nativeJs(moment(date)));
        }
        return undefined;
    }

    $scope.savePoc = () => {
        $scope.isSending = true;
        const errorMessage = $scope.validatePoc();
        if (errorMessage !== "") {
            $scope.isSending = false;
            toaster.pop('warning', errorMessage);
            return;
        }

        const body = {
            isPublished: true,
            startDate: dateToLocalDate($scope.form.startDate.value),
            endDate: dateToLocalDate($scope.form.endDate.value),
            content: $scope.mapItemsToBody()
        };
        // if ($scope.isNew) {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plans_of_care"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":patientId", $scope.$resolve.patientId);

            body.planOfCareTypeId = $scope.officePlanOfCare.planOfCareTypeId;
            DatabaseApi.post(url, body).then((res) => {
                $scope.isSending = false;
                $scope.closeModal('success');
            }, (e) => {
                $scope.isSending = false;
                toaster.pop('error', 'Something went wrong', apiErrors.format(e, 'Failed saving plan of care'));
            });
            
        // I'm commenting these lines because this is a temporary solution
        // } else {
        //     const url = "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/plans_of_care/:patientDocId"
        //         .replace(":agencyId", $rootScope.agencyId)
        //         .replace(":agencyMemberId", $rootScope.agencyMemberId)
        //         .replace(":patientId", $scope.$resolve.patientId)
        //         .replace(":patientDocId", $scope.$resolve.planOfCare.patientDocId);

        //     DatabaseApi.put(url, body).then((res) => {
        //         $scope.isSending = false;
        //         $scope.closeModal('success');
        //     }, (err) => {
        //         $scope.isSending = false;
        //         toaster.pop('error', 'Oops! Something went wrong', 'Failed saving plan of care');
        //     });
        // }
    
    };

    $scope.closeModal = (res) => {
        if (res === undefined) {
            res = 'cancel';
        }
        $uibModalInstance.close(res);
    };

    initialize();
};