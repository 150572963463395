import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export const caregiversTrainingCenterOverviewTotalStatistics = {
    templateUrl:
      "admin/views/caregivers-training-center-overview-total-statistics.html",
    bindings: {
      bundleStatistics: "<",
      activeOverallBundle: "<",
      onClickOverallStat: "&",
    },
    //! @ngInject
    controller: function ($scope, $timeout) {
      this.hasInitializedCharts = false;

      $scope.$ctrl.$onChanges = function ({ bundleStatistics }) {
        if (bundleStatistics && bundleStatistics.currentValue !== undefined) {
          this.bundleStatistics = bundleStatistics.currentValue;
          setTotalProgressionStats(this.bundleStatistics);
        }
      };

      this.$doCheck = () => {
        if (
          this.bundleStatistics &&
          !this.hasInitializedCharts &&
          document.querySelector('canvas[data-totals-chart="Progression"]') !==
            null
        ) {
          $timeout(() => {
            setTotalProgressionStats(this.bundleStatistics);
            this.hasInitializedCharts = true;
          });
        }
      };

      const GREEN_COLOR = "#3077db";
      const RED_COLOR = "#FF5684";
      const GRAY_COLOR = "#E5E6E8";
      const BLUE_COLOR = "#46A8FF";

      // Chart.js object references, so they can be cleared when new ones are drawn
      $scope.charts = {
        progression: undefined,
      };

      /**
       * Total statistics
       */
      $scope.totalStats = {
        Progression: undefined,
      };

      /**
       * Total stats tolltip information
       */
      $scope.totalStatsTooltipText = {}

      this.handleClickOverallStat = (overallStat) => {
        this.onClickOverallStat({ overallStat });
      };

      function setTotalProgressionStats(progression) {
        const { completed, started, notStarted, exempted } = progression;
        $scope.totalOverallAmount = completed + started + notStarted;

        $scope.totalStats.Progression = {
          "Completed": completed,
          "Has started": started,
          "Not started": notStarted,
        };
        $scope.totalStatsTooltipText = {
          "Completed": "Counts unique caregivers who have completed all assigned training",
          "Has started": "Counts unique caregivers who have started at least one bundle",
          "Not started": "Counts unique caregivers who are either exempt from training, or have not started their training",
        }

        if (
          completed === 0 &&
          started === 0 &&
          notStarted === 0
        ) {
          $scope.totalStats.Progression.noData = true;
        }

        newTotalChart(
          "progression",
          'canvas[data-totals-chart="Progression"]',
          {
            datasets: [
              {
                data: Object.values($scope.totalStats.Progression),
                backgroundColor: [
                  GREEN_COLOR,
                  BLUE_COLOR,
                  RED_COLOR,
                ],
              },
            ],
            labels: Object.keys($scope.totalStats.Progression),
          }
        );
      }

      function newTotalChart(topic, selector, data) {
        const canvas = document.querySelector(selector);
        if (!canvas) {
          return;
        }
        const ctx = canvas.getContext("2d");

        if ($scope.charts[topic] !== undefined) $scope.charts[topic].destroy();

        $scope.charts[topic] = new Chart(ctx, {
          type: "horizontalBar",
          data: data,
          plugins: [ChartDataLabels],
          options: {
            layout: {
              padding: {
                left: 0,
                right: 25,
                top: 10,
                bottom: 0,
              },
            },
            plugins: {
              datalabels: {
                anchor: "end",
                padding: 5,
                align: "end",
                clamp: true,
                offset: 10,
              },
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      }
    },
  };
