import { Id } from "../messages/ids";

type PathParamKey<T> = T extends `${infer A}/${infer B}`
  ? PathParamKey<A> | PathParamKey<B>
  : T extends string
  ? T extends `:${infer U}`
    ? U
    : never
  : never;

export type Endpoint = <Path extends string>(params: {
  path: Path;
  params: Record<PathParamKey<Path>, Id | string>;
}) => string;

export function endpoint(): Endpoint {
  return ({ path, params }) => {
    return Object.entries(params).reduce((acc, [key, value]) => {
      return acc.replace(`:${key}`, `${value}`);
    }, path as string);
  };
}
